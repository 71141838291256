import React from "react";
import { useSelector, useDispatch } from "react-redux";

function LeaveRequestHook() {
  const attendenceReducer = useSelector((state) => state.attendenceReducer);
  const dispatch = useDispatch();
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     METHODS                                    ||
  // ! ||--------------------------------------------------------------------------------||
  const setLeaveRequest = (data) => {
    dispatch({
      type: "UpdateLeaveRequestData",
      payload: data,
    });
  };
  const reset = (data) => {
    dispatch({
      type: "Reset",
      payload: data,
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     RETURN                                     ||
  // ! ||--------------------------------------------------------------------------------||
  return {
    setLeaveRequest: (e) => setLeaveRequest(e),
    leaveRequest: attendenceReducer.leaveRequest,
    reset: () => reset(),
  };
}

export default LeaveRequestHook;
