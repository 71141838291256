import React, { useState, useRef } from "react";
import { gsap } from "gsap";

const ResizableTable = () => {
  const initialData = [
    { id: 1, col1: "Data 1", col2: "Data 2", col3: "Data 3", col4: "Data 4" },
    // ... other records
  ];

  const [data, setData] = useState(initialData);
  const [resizing, setResizing] = useState(null);
  const [resizingIndex, setResizingIndex] = useState(null);
  const [resizingStartSize, setResizingStartSize] = useState(0);

  const tableRef = useRef(null);

  const handleMouseDown = (event, index, colKey) => {
    setResizing(colKey);
    setResizingIndex(index);
    setResizingStartSize(event.clientX);
  };

  const handleMouseMove = (event) => {
    if (resizing !== null) {
      const offset = event.clientX - resizingStartSize;

      gsap.to(tableRef.current.rows[resizingIndex].cells[resizing], {
        width: "+=" + offset,
      });

      setResizingStartSize(event.clientX);
    }
  };

  const handleMouseUp = () => {
    setResizing(null);
    setResizingIndex(null);
  };

  return (
    <table ref={tableRef}>
      <thead>
        <tr>
          {Object.keys(data[0]).map((colKey) => (
            <th
              key={colKey}
              onMouseDown={(e) => handleMouseDown(e, 0, colKey)}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              {colKey}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item.id}>
            {Object.keys(item).map((colKey) => (
              <td
                key={colKey}
                onMouseDown={(e) => handleMouseDown(e, index, colKey)}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                style={{ width: "150px" }} // Set initial width
              >
                {item[colKey]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ResizableTable;
