import React, { useEffect, useState, useRef } from "react";
import Input from "./input";
import { useSelector, useDispatch } from "react-redux";

const Tags = (props) => {
  const PeopleReducer = useSelector((state) => state.changeNumber);
  const dispatch = useDispatch();
  const tagRef = useRef();
  const [value, setvalue] = useState(props?.value);
  const [show, setshow] = useState(true);
  const [tags, settags] = useState(props?.data);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      settags([...tags, { tag: event.target.value }]);
      updateValue(event.target.value, props.label, props.block, props.tabName);
      setvalue("");
      setshow(false)
    }
  }

  useEffect(() => {
    tagRef.current.focus();
  }, [tags]);

  const updateValue = (e, label, block, tabName, type = "add") => {
    console.log("Update Value: ", { e, label, block, tabName });

    let PeopleReducerDeepCopy = [...PeopleReducer.fieldsBlockVariable];

    PeopleReducerDeepCopy.filter((tabs) => {
      tabs.tab_name = tabs.tab_name.toLowerCase();

      if (tabs.tab_name == tabName) {
        tabs.block.filter((tabsBlock) => {
          let tempFilterblcokName = tabsBlock.blockName
            .toLowerCase()
            .replace(/\s+/g, "_");
          let tempParamblcokName = block.blockName
            .toLowerCase()
            .replace(/\s+/g, "_");
          if (tempFilterblcokName == tempParamblcokName) {
            tabsBlock.inputs.filter((blockInputs) => {
              let tempblocklabel = blockInputs.label
                .toLowerCase()
                .replace(/\s+/g, "_");
              let tempparamlabel = label.toLowerCase().replace(/\s+/g, "_");
              console.log("Tab:", tempblocklabel, tempparamlabel);
              if (tempblocklabel == tempparamlabel) {
                if (type == "add") {
                  blockInputs.data.push({ tag: e });
                  blockInputs.value = e;
                } else if (type == "del") {
                  blockInputs.data.splice(e, 1);
                  console.log(e);
                }
                setTimeout(() => {
                  dispatch({
                    type: "UPDATEFIELD",
                    payload: PeopleReducerDeepCopy,
                  });
                  setshow(true)
                  tagRef.current.focus();
                }, 500);
              }
            });
          }
        });
      }
    });
  };
  return (
    <div>
      <div className="floTags">
        <ul>
          {tags.map((e, i) => {
            return (
              <li key={i}>
                <div>{e.tag}</div>

                <svg
                  className="hover"
                  onClick={() => {
                    let temp = [...tags];
                    temp.splice(i, 1);
                    settags(temp);
                    updateValue(
                      i,
                      props.label,
                      props.block,
                      props.tabName,
                      "del"
                    );
                  }}
                  width="10"
                  height="10"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5"
                    stroke="#696969"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
            );
          })}
          <li className={`${show ? "d-flex" : "d-none"}`}>
            <div>
              <input
              ref={tagRef}
                className="floAddTags"
                value={value}
                onChange={(e) => setvalue(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>

            <svg
              className="hover"
              onClick={() => setshow(false)}
              width="10"
              height="10"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5"
                stroke="#696969"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </li>
          <li
            onClick={() => setshow(true)}
            className={`${show ? "d-none" : null}`}
            style={{ padding: 0, background: "unset", cursor: "pointer" }}
          >
            <div className="floAppendTags">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.26461 1.47266V13.0487M13.0526 7.2607H1.47656"
                  stroke="#919196"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Tags;
