import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo/Logo.svg";
import home from "../assets/img/icon/home.svg";
import workload from "../assets/img/icon/workload.svg";
import manage from "../assets/img/icon/manage.svg";
import bell from "../assets/img/icon/bell.svg";
import search from "../assets/img/icon/search.svg";
import profile from "../assets/img/icon/profile.svg";
import ManageProfileMenu from "./manageProfileMenu";

// ! ||--------------------------------------------------------------------------------||
// ! ||                                 MAIN CONPONENT                                 ||
// ! ||--------------------------------------------------------------------------------||
function FloHeader() {
  const [activeTab, setactiveTab] = useState(1);
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 RETURN METHORD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <div className="floheader">
      <div className="mainContainer">
        <div className="logoContainer">
          <img src={logo} />
        </div>
        <div className="toolsContainer">
          <ul>
            <li className="manageIcon toolsContainer_home">
              <img src={home} />
            </li>
            <li
              className="manageIcon toolsContainer_workload"
              onClick={() => setactiveTab(0)}
            >
              <div
                className={`${
                  activeTab == 0 ? "manageActive" : null
                } manageTab`}
              >
                <img src={workload} />
                <span>Workload</span>
              </div>
            </li>
            <li
              className="manageIcon toolsContainer_manage"
              onClick={() => setactiveTab(1)}
            >
              <div
                className={`${
                  activeTab == 1 ? "manageActive" : null
                } manageTab`}
              >
                <img src={manage}/>
                <span>Manage</span>
              </div>
            </li>
            <li className="manageIcon toolsContainer_search">
              <img src={search} />
            </li>
          </ul>
        </div>
        <div className="profileContainer toolsContainer">
          <ul>
            <li className="manageIcon profileContainer_home">
              <img src={bell} style={{width:20}}/>
            </li>
            <li
              className="manageIcon toolsContainer_workload"
              onClick={() => setactiveTab(0)}
            >
              {/* <div className={`manageTab`}>
                <img style={{ width: 40 }} src={profile} />
                <div>
                  <div className="companyNamy">Buzz Interactive</div>
                  <div className="userName">Gohar Shafique</div>
                </div>
              </div> */}
              <ManageProfileMenu/>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FloHeader;
