import logo from "../assets/img/logo/Logo.svg";
import Input from "../Components/input";
import { Link } from "react-router-dom";
import "../App.css";
import { Button } from "@mui/material";
import arrow from "../assets/img/icon/Arrow.svg";
import google from "../assets/img/icon/google.svg";
import eyeslash from "../assets/img/icon/eyeslash.svg";
import check from "../assets/img/icon/check.svg";
import invalid from "../assets/img/icon/invalid.svg";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function SetPassword(props) {
  const OnboardingReducer = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(true);
  const [character, setcharacter] = useState(false);
  const [numaric, setnumaric] = useState(false);
  const [length8, setlength8] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("weak");
  const location = useLocation();
  const navigate = useNavigate();
  const SetButton = useRef();
  const [body, setbody] = useState(location.state);

  const checkValidation = (e) => {
    // CHECK CHARACTER VALIDATION
    var characterReg = /[a-zA-Z]/g;
    var numaricReg = /\d/g;

    if (characterReg.test(e)) {
      console.log(1);
      setcharacter(true);
    } else if (!characterReg.test(e)) {
      console.log(2);
      setcharacter(false);
    }

    if (numaricReg.test(e)) {
      console.log(3);
      setnumaric(true);
    } else if (!numaricReg.test(e)) {
      console.log(4);
      setnumaric(false);
    }

    if (e.length >= 8) {
      console.log(5);
      setlength8(true);
    } else {
      console.log(6);
      setlength8(false);
    }

    setbody({ ...body, password: e });
    dispatch({ type: "SetPass", payload: { password: e } });

    // Determine password strength
    if (character && numaric && e.length >= 8) {
      setPasswordStrength("strong");
    } else {
      setPasswordStrength("weak");
    }
  };
  const setPass = () => {
    if (character && numaric && length8) {
      navigate("/company-details", { state: body });
    } else {
      console.log("Error in set pass");
    }
  };

  useEffect(() => {
    console.log("character: ", character);
    console.log("numaric: ", numaric);
    console.log("length8: ", length8);
    if (character && numaric && length8) {
      console.log("password:", body);
    }
  }, [character, numaric, length8]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        SetButton.current.click();
        console.log("Enter...");
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="signup bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading">Great! Lets get you started</h4>
      <div className="h-center">
        <div>
          <div className="board-white">
            <div className="p-relative">
              <Input
                value={body?.password}
                getValue={(e) => checkValidation(e)}
                password={showPass}
                minWidth={350}
                placeholder="*******"
                label="Password"
              />
              {showPass ? (
                <img
                  onClick={() => setShowPass(!showPass)}
                  src={eyeslash}
                  className="p-absolute eyetoggle"
                />
              ) : (
                <img
                  onClick={() => setShowPass(!showPass)}
                  src={check}
                  className="p-absolute eyetoggle"
                />
              )}
            </div>
            {/* <p className="CentraNo2 strong_pass">
              Password Strength: <span className="CentraNo2-m">Strong</span>
            </p> */}
            <p className="CentraNo2 strong_pass">
              Password Strength:{" "}
              <span className="CentraNo2-m">{passwordStrength}</span>
            </p>
            <p
              className={`CentraNo2 ${
                length8 ? "text-s" : "text-p"
              } strong_pass_check v-center`}
            >
              <img
                src={length8 ? check : invalid}
                style={{ marginRight: 10, width: 14 }}
              />{" "}
              Use 8 or more characters.
            </p>
            <p
              className={`CentraNo2 ${
                character ? "text-s" : "text-p"
              } strong_pass_check v-center`}
            >
              <img
                src={character ? check : invalid}
                style={{ marginRight: 10, width: 14 }}
              />{" "}
              Use a minimum of one letter.
            </p>
            <p
              className={`CentraNo2 ${
                numaric ? "text-s" : "text-p"
              } strong_pass_check v-center`}
              style={{ marginBottom: 10 }}
            >
              <img
                src={numaric ? check : invalid}
                style={{ marginRight: 10, width: 14 }}
              />{" "}
              Use a minimum of one number.
            </p>

            <Button
              ref={SetButton}
              onClick={() => setPass()}
              className={`btn-block btn_text`}
              variant="contained"
            >
              Set Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
