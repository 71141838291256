import { Link, useNavigate } from "react-router-dom";

import logo from "./assets/img/logo/Logo.svg";
import Input from "./Components/input";
import "./App.css";
import { Button } from "@mui/material";
import arrow from "./assets/img/icon/Arrow.svg";
import google from "./assets/img/icon/google.svg";
import outlook from "./assets/img/icon/outlook.svg";
import { useEffect, useState } from "react";
import { NextPlan, PropaneSharp } from "@mui/icons-material";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "./utilities/toast";
import { isEmailExistCall } from "./helper/index";

// ! ||--------------------------------------------------------------------------------||
// ! ||                                 MAIN COMPONENT                                 ||
// ! ||--------------------------------------------------------------------------------||
function App(props) {
  const navigate = useNavigate();
  const [body, setBody] = useState({
    name: "",
    company_name: "",
    email: "",
    password: "",
    no_emp: "01-10",
    how_did_hear_you_about_us: "social_media",
  });

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   USE EFFECTS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    console.log("Body:", body);
  }, [body]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                    NEXT STEP                                   ||
  // ! ||--------------------------------------------------------------------------------||
  const NextStep = () => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (body.name == "") {
      toastError("'Name' is require");
    } else if (body.email == "") {
      toastError("'Email' is require");
    } else if (!filter.test(body.email)) {
      toastError("Email is invalid");
    } else if (body.company_name == "") {
      toastError("'Company Name' is require");
    } else {
      isEmailExist();
    }
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 IS EMAIL EXIST                                 ||
  // ! ||--------------------------------------------------------------------------------||
  const isEmailExist = () => {
    isEmailExistCall({ email: body.email }).then((res) => {
      if (res.data.status == "error") {
        toastError(res.data.data.message);
      } else if ((res.data.status = "success")) {
        if (res.data.data.isEmailExist > 0) {
          toastError("Email already exist please use different email");
        } else {
          navigate("/set-password", { state: body });
        }
      }
    });
  };
  return (
    <div className="signup signup_bg bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading animate__animated animate__bounce">
        Great! Lets get you started
      </h4>
      <div className="h-center">
        <div>
          <div className="board-white">
            {/* <h5 className="text-center mt-0 CentraNo2-l">
              Just need a few things to get you going
            </h5> */}
            <div className="jsb">
              <div className="signup_google">
                <h5 className="CentraNo2 v-center m-0">
                  Sign up with{" "}
                  <img src={google} style={{ marginLeft: 5, width: 25 }} />
                </h5>
              </div>
              <div className="signup_outlook">
                <h5 className="CentraNo2 v-center m-0">
                  Sign up with{" "}
                  <img src={outlook} style={{ marginLeft: 5, width: 25 }} />
                </h5>
              </div>
            </div>
            <div className="jsb v-center" style={{ margin: "15px 0px" }}>
              <div className="border-bottom" style={{ width: "45%" }}></div>
              <span style={{ fontSize: 13 }}>or</span>
              <div className="border-bottom" style={{ width: "45%" }}></div>
            </div>
            <div>
              <Input
                getValue={(e) => setBody({ ...body, name: e })}
                minWidth={350}
                placeholder="Tom"
                label="Your Name"
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <Input
                getValue={(e) => setBody({ ...body, email: e })}
                minWidth={350}
                placeholder="tom@gmail.com"
                label="Email"
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <Input
                getValue={(e) => setBody({ ...body, company_name: e })}
                minWidth={350}
                placeholder="Cubix"
                label="Company Name"
              />
            </div>

            <div
              onClick={() => NextStep()}
              // state={body}
              // to="/set-password"
              className="btn-block btn_text"
              variant="contained"
            >
              Next
            </div>
            <p className="h-center CentraNo2 already_use_flo">
              Already use flo?{" "}
              <Link to="/login" style={{ paddingLeft: 5 }}>
                Sign in here.
              </Link>
            </p>
          </div>
          <p className="signup_toc text-center">
            Questions? Need a hand?
            <a href="#" style={{ marginLeft: 3 }}>
              Support can help
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
