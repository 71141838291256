import * as React from "react";
import Box from "@mui/material/Box";
import employeeImg from "../assets/img/img/employee.svg";
import collaboratorImg from "../assets/img/img/collaborator.svg";
import plusIcon from "../assets/img/icon/plusIcon.png";
import Input from "../Components/input";
import FloSelect from "../Components/FloSelect";
import DatePicker from "../Components/DatePicker";
import { leaveRequest, updateLeaveRequest } from "../helper/index";
import { useSelector, useDispatch } from "react-redux";
import useTokkenHook from "../utilities/TokkenHook";
import LeaveRequestHook from "../utilities/LeaveRequestHook";
import moment from "moment";
import { toastSuccess, toastWarning, toastError } from "../utilities/toast";
import Grid from "@mui/material/Grid";
import { getPeople, getAllTimeoff, getFeaturedTimeoff } from "../helper/index";
import GeneralHook from "../utilities/GeneralHook";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 3,
  borderRadius: 2,
  outline: "unset",
  maxHeight: 560,
  overflow: "scroll",
};

export default function RequestTimeoffForm(props) {
  const { tokken, setTokken } = useTokkenHook();
  const { leaveRequest: leaveRequesthook, setLeaveRequest } =
    LeaveRequestHook();
  const { getGeneral, setGeneral } = GeneralHook();
  const [timeOffSummery, settimeOffSummery] = React.useState([]);
  const [timeoffTypes, settimeoffTypes] = React.useState([]);
  const [data, setdata] = React.useState({
    type: leaveRequesthook.type,
    from: leaveRequesthook.from,
    to: leaveRequesthook.to,
    reason: leaveRequesthook.reason,
    user_id: getGeneral("changeNumber", "selectedPeopleId"),
    company_id: getGeneral("userReducer", "userDetails").company_id,
  });
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   USE EFFECTS                                  ||
  // ! ||--------------------------------------------------------------------------------||

  React.useEffect(() => {
    let data = getGeneral("attendenceReducer", "featuredTimeoff");
    settimeOffSummery(data);
    AllTimeoff();
  }, []);
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 GET ALL TIMEOFF                                ||
  // ! ||--------------------------------------------------------------------------------||
  const AllTimeoff = () => {
    let userData = getGeneral("userReducer", "userDetails");
    getAllTimeoff({ company_id: userData.company_id }, tokken).then((e) => {
      if (e.data.status == "success") {
        let tempdata = [];
        let data = e.data.data;
        data.map((e) => {
          tempdata.push({ leave_type: e.timeoff_type });
        });
        console.log("safsdfasdfasdfasdf,", tempdata);
        settimeoffTypes(tempdata);
      } else if (e.data.status == "error") {
        toastError(e.data.data.message);
      }
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                              GET FEATURED TIMEOFF                              ||
  // ! ||--------------------------------------------------------------------------------||
  const FeaturedTimeoff = () => {
    getFeaturedTimeoff(
      { user_id: getGeneral("changeNumber", "selectedPeopleId") },
      tokken
    ).then((e) => {
      if (e.data.status == "success") {
        setGeneral("FeaturedTimeoff", e.data.data);
      } else if (e.data.status == "error") {
        toastError(e.data.data.message);
      }
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ADD REQUEST                                  ||
  // ! ||--------------------------------------------------------------------------------||
  const RequestTimeOff = () => {
    console.log("Data:", data);
    let tempfrom = moment(data.from, "MMMM Do YYYY");
    let tempto = moment(data.to, "MMMM Do YYYY");
    const isDateFromGreater = tempto.isSameOrAfter(tempfrom);

    console.log("isDateAGreater:", isDateFromGreater);
    if (isDateFromGreater) {
      leaveRequest(
        {
          type: data.type,
          from: new Date(tempfrom),
          to: new Date(tempto),
          reason: data.reason,
          user_id: data.user_id,
          company_id: data.company_id,
        },
        tokken
      ).then((e) => {
        console.log("Response:", e);
        if (e.data.status == "success") {
          FeaturedTimeoff();
          toastSuccess("Your request has been sent successfully");
          props.handleClose();
        } else if (e.data.status == "error") {
          toastError(e.data.data.message);
        }
      });
    } else {
      toastWarning(`Date(From) must be grater then equal Date(To)`);
    }
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 UPDATE REQUEST                                 ||
  // ! ||--------------------------------------------------------------------------------||
  const UpdateTimeOff = () => {
    let tempdata = { ...data, id: leaveRequesthook._id };
    let tempfrom = moment(tempdata.from);
    let tempto = moment(tempdata.to);
    const isDateFromGreater = tempto.isSameOrAfter(tempfrom);
    console.log("isDateAGreater:", isDateFromGreater);
    if (isDateFromGreater) {
      console.log("tempdata:", tempdata);
      updateLeaveRequest(tempdata, tokken).then((e) => {
        console.log("Response:", e);
        if (e.data.status == "success") {
          FeaturedTimeoff();
          toastSuccess("Your request has been sent successfully");
          props.handleClose();
        } else if (e.data.status == "error") {
          toastError(e.data.data.message);
        }
      });
    } else {
      toastWarning(`Date(to) must be grater then Date(from)`);
    }
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     RETURN                                     ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <Box sx={style}>
      <Box className="h-center" sx={{ justifyContent: "space-between" }}>
        <h1
          className="fh1 text-start"
          style={{ marginBottom: 20, paddingLeft: 8 }}
        >
          Request Timeoff
        </h1>
      </Box>
      <Grid container>
        <Grid xs={9}>
          <Grid container>
            <Grid xs={12}>
              <Box sx={{ padding: 1 }}>
                <FloSelect
                  type="individual"
                  key={timeoffTypes}
                  tabName={""}
                  block={""}
                  keyValue={"Leave Type"}
                  data={timeoffTypes}
                  placeholder={"Leave Type"}
                  value={data.type}
                  label={"Type"}
                  multiline={false}
                  row={1}
                  disabled={leaveRequesthook.type != "" ? true : false}
                  getValue={(e) => {
                    setdata({ ...data, type: e });
                    console.log("Get Value", timeoffTypes);
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ padding: 1 }}>
                <DatePicker
                  type="individual"
                  key={data.from}
                  tabName={""}
                  block={""}
                  keyValue={"from"}
                  data={[{ from: 0 }]}
                  placeholder={""}
                  label={"Date (from)"}
                  value={data.from}
                  getValue={(e) => {
                    setdata({ ...data, from: e });
                    console.log("From:", e);
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ padding: 1 }}>
                <DatePicker
                  type="individual"
                  key={data.to}
                  tabName={""}
                  block={""}
                  keyValue={"to"}
                  data={[{ to: 0 }]}
                  placeholder={""}
                  label={"Date (to)"}
                  value={data.to}
                  getValue={(e) => {
                    setdata({ ...data, to: e });
                    console.log("TO:", e);
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box sx={{ padding: 1 }}>
                <FloSelect
                  type="individual"
                  key={data.reason}
                  tabName={""}
                  block={""}
                  keyValue={"reason"}
                  data={[]}
                  placeholder={""}
                  label={"Reason"}
                  value={data.reason}
                  multiline={true}
                  row={2}
                  getValue={(e) => {
                    console.log("Get Value", e);
                    setdata({ ...data, reason: e });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={3} style={{ padding: 8 }}>
          <Box
            className="leavesSummeryNote"
            sx={{ height: 209, overflow: "scroll" }}
          >
            {timeOffSummery.map((timeoff) => (
              <>
                <Box
                  className="leaveSummeryContainer leaveSummeryContainer2"
                  style={{ marginTop: 10 }}
                >
                  <div className="fh3">
                    {timeoff.remaining_days}/
                    {timeoff.timeoff_details.total_days} Used
                  </div>
                  <div className="fp">
                    {timeoff.timeoff_details.timeoff_type}
                  </div>
                </Box>
              </>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1 }}>
        <Box className="f-end" style={{ marginTop: 30 }}>
          <div
            onClick={() => props.handleClose()}
            className="btn-block btn-mini-inv mt-0"
            style={{ height: 38, maxWidth: 100 }}
          >
            Cancel
          </div>
          <div
            onClick={() => {
              if (leaveRequesthook.type != "") {
                UpdateTimeOff();
              } else {
                RequestTimeOff();
              }
            }}
            className="btn-block btn_text mt-0"
            style={{ height: 38, maxWidth: 100 }}
          >
            Request
          </div>
        </Box>
      </Box>
    </Box>
  );
}
