import * as React from "react";
import MuiGrid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

import Box from "@mui/material/Box";
const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));
export default function Date(props) {
  return (
    // <div className="date--Css--position">
    <Grid container className="address--topbar">
      <Grid item xs>
        <div className="date--css">
          <Box className="date--datepicker--css">
            <p className="date--text--css">Start Date</p>
          </Box>
          <Box className="date--date--css">{props.data[0][props.keyValue?.toLowerCase().replace(/\s+/g, "_")]}</Box>
        </div>
      </Grid>
      <Divider orientation="vertical" flexItem className="border--date" />
      <Grid item xs>
        <div className="date--css">
          <Box className="date--datepicker--css">
            <p className="date--text--css">End Date</p>
          </Box>
          <Box className="date--date--css">
            {props.data[1][props.keyValue]
              ? props.data[1][props.keyValue?.toLowerCase().replace(/\s+/g, "_")]
              : "Undefined"}
          </Box>
        </div>
      </Grid>
    </Grid>
    // </div>
  );
}
