import React, { useEffect, useState } from "react";
import Input from "./input";
import times from "../assets/img/icon/times.svg";
import { CalculateFormula } from "../utilities/FormulaCalc";
import { useSelector, useDispatch } from "react-redux";
import { GrantAccess } from "../utilities/General";
import RolePermissionsHelper from "../helper/RolePermission";
import { getLineManagers } from "../helper/index";
import useTokkenHook from "../utilities/TokkenHook";

const FloSelect = (props) => {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 DEFINED STATUES                                ||
  // ! ||--------------------------------------------------------------------------------||
  const dispatch = useDispatch();
  const { tokken, setTokken } = useTokkenHook();
  const { GetAllRoles } = RolePermissionsHelper();
  const PeopleReducer = useSelector((state) => state.changeNumber);
  const [open, setopen] = useState(false);
  const [grantAccess, setGrantAccess] = useState(true);
  const [value, setvalue] = useState(props.value ? props.value : "");
  const [data, setdata] = useState([]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||

  useEffect(() => {
    if (props.keyValue == "Manager") {
      getLineManagers(tokken).then((managers) => {
        let manager = managers.data.data.map((m)=>({manager:m.name}))
        setdata([...manager,{manager:""}]);
      });
    } else if (props.keyValue == "Type") {
      SetRoles();
    } else {
      setdata(props.data.length > 0 ? [...props.data] : []);
    }
  }, [PeopleReducer,props.keyValue]);

  useEffect(() => {
    var calculatedValue = CalculateFormula(value);
    if (calculatedValue) {
      setvalue(calculatedValue);
    }
    let externalField = PeopleReducer?.peoples[0]?.externalField;
    GrantPermission(externalField, "work", "just_update_my_work_info");
  }, [PeopleReducer]);

  async function SetRoles() {
    let roles = await GetAllRoles();
    if (roles.status == "success") {
      let updatedRole = roles.data.map((roles) => {
        return { id: roles._id, type: roles.role_name };
      });
      setdata(updatedRole);
    }
  }

  function GrantPermission(permissionsArray, module, permission_string) {
    console.log(
      "permissionsArray, module, permission_string:",
      permissionsArray,
      module,
      permission_string
    );
    const hasTimeOffPermission = Filter(permissionsArray, module);
    console.log("hasTimeOffPermission_:", hasTimeOffPermission);
    if (hasTimeOffPermission) {
      if (props?.tabName == module) {
        if (hasTimeOffPermission.length > 0) {
          let hasPermissions = hasPermission(
            hasTimeOffPermission,
            permission_string
          );
          console.log("hasPermissions:", hasPermissions);
          if (hasPermissions == true) {
            setGrantAccess(true);
          } else {
            setGrantAccess(false);
          }
        } else {
          setGrantAccess(false);
        }
      }
    }
  }
  function hasPermission(permissionsArray, permissionName) {
    return permissionsArray?.some(
      (permission) =>
        permission.description === permissionName &&
        permission.module == props?.tabName
    );
  }
  function Filter(permissionsArray, module) {
    return permissionsArray?.filter(
      (permission) => permission.module == module
    );
  }

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  const search = (e) => {
    if (data.length > 0) {
      let obj = data?.filter((o) => {
        console.log("o:", o[props.keyValue.toLowerCase().replace(/\s+/g, "_")]);
        return o[props.keyValue.toLowerCase().replace(/\s+/g, "_")]
          ?.toLowerCase()
          .includes(e?.toLowerCase());
      });
      if (e == "") {
        setdata(obj);
      } else {
        setdata(obj);
      }
    }
  };
  const updateValue = (e, label, block, tabName) => {
    if (props?.type == "individual") {
      props?.getValue(e);
    } else {
      console.log("Update Value: ", { e, label, block, tabName });
      let PeopleReducerDeepCopy = [...PeopleReducer.fieldsBlockVariable];
      PeopleReducerDeepCopy.filter((tabs) => {
        tabs.tab_name = tabs.tab_name.toLowerCase();

        if (tabs.tab_name == tabName) {
          tabs.block.filter((tabsBlock) => {
            let tempFilterblcokName = tabsBlock.blockName
              .toLowerCase()
              .replace(/\s+/g, "_");
            let tempParamblcokName = block.blockName
              .toLowerCase()
              .replace(/\s+/g, "_");
            if (tempFilterblcokName == tempParamblcokName) {
              tabsBlock.inputs.filter((blockInputs) => {
                let tempblocklabel = blockInputs.label
                  .toLowerCase()
                  .replace(/\s+/g, "_");
                let tempparamlabel = label.toLowerCase().replace(/\s+/g, "_");
                if (tempblocklabel == tempparamlabel) {
                  blockInputs.value = e;
                  setTimeout(() => {
                    if (grantAccess && "work" == props.tabName) {
                      dispatch({
                        type: "UPDATEFIELD",
                        payload: PeopleReducerDeepCopy,
                      });
                    }
                  }, 500);
                }
              });
            }
          });
        }
      });
    }
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <section>
      {}
      <div
        className={`floSelectMenuOverlay ${
          open && props.data.length > 0 ? "d-block" : "d-none"
        }`}
        onClick={() => setopen(false)}
      ></div>
      <div className="p-relative">
        <div
          onClick={() => {
            search("");
            if (!props.disabled) {
              setopen(true);
            }
          }}
        >
          <Input
            key={value}
            value={value}
            getValue={(e) => {
              search(e);
              setopen(true);
            }}
            onBlur={(e) => {
              setopen(true);
              updateValue(e, props.label, props.block, props.tabName);
            }}
            placeholder={props.placeholder}
            label={props.label}
            multiline={props.multiline}
            row={props.row}
            disabled={props.disabled || !grantAccess}
          />
          {/* ARROW */}
          {props.data.length > 0 ? (
            <svg
              onClick={() => {
                search("");
                setopen(true);
              }}
              className="floSelectArrowup"
              width="11"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.625 1L7 6.625L1.375 1"
                stroke="#696969"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : null}
          {/* TIMES */}
          {value && props.data.length > 0 ? (
            <svg
              onClick={() => {
                setvalue("");
                search("");
              }}
              className="floSelectTimes"
              width="8"
              height="8"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5"
                stroke="#696969"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : null}
        </div>
        {data.length > 0 ? (
          <div className={`floSelectBody ${open ? "d-block" : "d-none"}`}>
            <ul>
              {data.map((e, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => {
                      setvalue(
                        e[props.keyValue.toLowerCase().replace(/\s+/g, "_")]
                      );
                      setopen(false);
                      updateValue(
                        e[props.keyValue.toLowerCase().replace(/\s+/g, "_")],
                        props.label,
                        props.block,
                        props.tabName
                      );
                    }}
                  >
                    {e[props.keyValue.toLowerCase().replace(/\s+/g, "_")]}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default FloSelect;
