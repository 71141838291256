const initialState = {
  token: "",
  userDetails: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATETOKEN":
      console.log(action);
      return {
        ...state,
        token: action.payload,
      };
      break;
    case "UPDATEUSERDATA":
      console.log(action);
      return {
        ...state,
        userDetails: action.payload,
      };
      break;
    case "CLEANUSERREDUCER":
      console.log(action);
      return {
        ...state,
        token: "",
        userDetails: [],
      };
      break;

    default:
      return state;
  }
};

export default userReducer;
