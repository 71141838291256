export const limitdepartment = [
    {
      departmentTitle: "Human Resource",
    },
    {
      departmentTitle: "WebFlow Developers",
    },
    
    {
      departmentTitle: "Web Developer",
    },
    
  ];