import * as React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import profile from "../assets/img/icon/profilepic.svg";
import userprofile from "../assets/img/icon/userprofile.svg";
import help from "../assets/img/icon/help_.svg";
import setting from "../assets/img/icon/setting.svg";
import whatnew from "../assets/img/icon/whatnew.svg";
import logout from "../assets/img/icon/logout.svg";
import bottomarrow from "../assets/img/icon/bottomarrow.svg";
import { styled, alpha } from "@mui/material/styles";
export default function ManageProfileMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    props?.closeFloMenu();
  };

  React.useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName("manageMenuContainer");
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        console.log(element);
        element.removeAttribute("tabindex");
      }
    }, 1000);
  }, [open]);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <div>
      <div className={`manageTab`} style={{ padding: 0 }} onClick={handleClick}>
        {props.menu}
      </div>

      <Menu
        id="basic-menu"
        className={props?.className}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          className="manageMenuContainer"
          style={{
            maxWidth: props.maxWidth,
            maxHeight: props.maxHeight,
            overflow: "scroll",
          }}
        >
          {props.children}
        </div>
      </Menu>
    </div>
  );
}
