import moment from "moment";
const initialState = {
  leaveRequest: {
    type: "",
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    reason: "",
  },
  featuredTimeoff: [],
  tracking: 0,
  trackingStage: 0,
  reload: 0,
};

const attendenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TrackingStage":
      console.log(action);
      return {
        ...state,
        trackingStage: action.payload,
      };
      break;
    case "FeaturedTimeoff":
      console.log(action);
      return {
        ...state,
        featuredTimeoff: action.payload,
      };
      break;
    case "SetTracking":
      console.log(action);
      return {
        ...state,
        tracking: action.payload,
      };
      break;
    case "UpdateLeaveRequestData":
      console.log(action);
      return {
        ...state,
        leaveRequest: action.payload,
      };
      break;
    case "Reset":
      console.log(action);
      return {
        ...state,
        leaveRequest: {
          type: "",
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
          reason: "",
        },
      };
      break;
    case "CLEANATTENDENCEREDUCER":
      console.log(action);
      return {
        ...state,
        leaveRequest: {
          type: "",
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
          reason: "",
        },
        featuredTimeoff: [],
        tracking: 0,
        trackingStage: 0,
      };
      break;
    case "RELOAD_ATTENDANCE_REDUCER":
      console.log(action);
      return {
        ...state,
        reload: action.reload,
      };
      break;

    default:
      return state;
  }
};

export default attendenceReducer;
