import React from "react";
import { useSelector, useDispatch } from "react-redux";

function TokkenHook() {
  const userReducer = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     METHODS                                    ||
  // ! ||--------------------------------------------------------------------------------||
  const setToken = (tokken) => {
    dispatch({
      type: "UPDATETOKEN",
      payload: tokken,
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     RETURN                                     ||
  // ! ||--------------------------------------------------------------------------------||
  return { setTokken: (e) => setToken(e), tokken: userReducer.token };
}

export default TokkenHook;
