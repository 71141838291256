import { render } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import Input from "../../Components/input";
import FloCheckbox from "../../Components/checkbox";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import RolePermissionsHelper from "../../helper/RolePermission";

const RolePermissions = () => {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 DEFINED STATUES                                ||
  // ! ||--------------------------------------------------------------------------------||
  const params = useParams();
  const { GetAllPermissions, GetRoleDetails, UpdateRolePermissions } =
    RolePermissionsHelper();

  const [checkPermission, setCheckPermission] = React.useState();
  const [roleName, setRoleName] = React.useState("");
  const [permissions, setpermissions] = React.useState([]);
  const [checkedPermissionsIds, setCheckedPermissionsIds] = React.useState([]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    if (params.role_id) {
      InitUpdateRole();
    } else {
      InitCreateRole();
    }
  }, []);
  useEffect(() => {
    console.log("checkedPermissionsIds:", checkedPermissionsIds, roleName);
  }, [checkedPermissionsIds, roleName]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||

  const InitUpdateRole = () => {
    GetAllPermissions().then((res) => {
      if (res.status == "success") {
        setpermissions(res.data);
      }
    });
    GetRoleDetails(params.role_id).then((res) => {
      if (res.status == "success") {
        console.log(res.data.roles);
        // setpermissions(res.data);
        setCheckPermission(res.data?.roles?.permissions);
        setRoleName(res.data?.roles?.role_name);
        getPermissionsIDsArray(res.data?.roles?.permissions);
      }
    });
  };
  const InitCreateRole = () => {
    GetAllPermissions().then((res) => {
      if (res.status == "success") {
        setpermissions(res.data);
      }
    });
  };

  const validatePermission = (idToCheck) => {
    const exists =
      Array.isArray(checkPermission) &&
      checkPermission.some((obj) => obj._id === idToCheck);
    return exists;
  };
  const getPermissionsIDsArray = (permissionsArray) => {
    const idsArray = permissionsArray?.map((obj) => obj?._id);
    Array.isArray(idsArray) && setCheckedPermissionsIds(idsArray);
  };
  const handleOnChange = (obj) => {
    if (obj.checked) {
      Array.isArray(checkedPermissionsIds) &&
        setCheckedPermissionsIds([...checkedPermissionsIds, obj.id]);
    } else {
      console.log("checkedPermissionsIds:", checkedPermissionsIds);
      let updatedIDs = checkedPermissionsIds.filter((id) => id !== obj.id);
      Array.isArray(updatedIDs) && setCheckedPermissionsIds(updatedIDs);
    }
  };
  const saveChanges = () => {
    if (roleName != null && checkedPermissionsIds.length > 0) {
      UpdateRolePermissions(params.role_id, {
        role_name: roleName,
        permissions: checkedPermissionsIds,
      });
    } else {
      alert("Something went wrong....");
    }
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <section>
      <Box
        className="sqr-profile-name v-center"
        style={{ paddingLeft: 0, marginBottom: 25 }}
      >
        Roles and Permissions
      </Box>
      <Box className="d-flex">
        <Input
          getValue={(e) => setRoleName(e)}
          value={roleName}
          maxWidth={350}
          label="Role Name"
        />
        <Box>
          <Button
            onClick={saveChanges}
            className="btn text-white white-space-wrap"
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      <Box style={{ marginTop: 25 }}>
        <Grid container spacing={2}>
          {permissions.map((permission, i) => (
            <Grid item md={4} key={i}>
              <Box sx={{ height: 35, display: "flex" }} key={i}>
                <FloCheckbox
                  value={permission._id}
                  style={{ marginRight: "unset", marginLeft: 6 }}
                  index={i}
                  className="flocheckbox baseNameCheckbox"
                  checked={validatePermission(permission._id)}
                  onChange={(e) => handleOnChange(e)}
                />
                <Typography sx={{ marginLeft: 2 }}>
                  {permission?.permission_name?.replace(/_/g, " ")}
                </Typography>
                <Typography sx={{ marginLeft: 2 }}>
                  {permission?.module}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </section>
  );
};

export default RolePermissions;
