import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InGreen from "../assets/img/icon/in_green.svg";
import InGray from "../assets/img/icon/in_gray.png";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Arrow from "../assets/img/icon/rightArrow.svg";
import Edit from "../assets/img/icon/edit2.svg";
import Trash from "../assets/img/icon/trash.svg";
import CalenderIcon from "./CalenderIcon";
import RequestTimeoffModel from "./RequestTimeoffModel";
import { Box, Grid } from "@mui/material";
import { getLeaveRequest, getLeaveRequestBYID } from "../helper/index";
import useTokkenHook from "../utilities/TokkenHook";
import LeaveRequestHook from "../utilities/LeaveRequestHook";
import GeneralHook from "../utilities/GeneralHook";
import { toastSuccess, toastError } from "../utilities/toast";
import moment from "moment";
import {
  delLeaveRequest,
  getFeaturedTimeoff,
  updateLeaveRequestStatus,
} from "../helper/index";
import ConfirmationDialog from "./ConfirmationDialog";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";

// ! ||--------------------------------------------------------------------------------||
// ! ||                                  BADGE STATUS                                  ||
// ! ||--------------------------------------------------------------------------------||
const statusBadge = (s) => {
  const status = {
    Approved: "approved_badge",
    Disapproved: "disapproved_badge",
    Pending: "pending_badge",
    Tracking: "tracking_badge",
    Holiday: "grayr_badge",
  };
  return status[s];
};
const astatusBadge = (s) => {
  const status = {
    On_Time: "green_badge",
    Holiday: "gray_badge",
    Late: "yellow_badge",
    Early: "blue_badge",
    Absent: "red_badge",
    WFH: "orange_badge",
    Leave: "purple_badge",
  };
  return status[s];
};

// ! ||--------------------------------------------------------------------------------||
// ! ||                              FUNCTIONAL COMPONENT                              ||
// ! ||--------------------------------------------------------------------------------||
export default function RequestTimeOff() {
  const { tokken, setTokken } = useTokkenHook();
  const { leaveRequest, setLeaveRequest } = LeaveRequestHook();
  const { getGeneral, setGeneral } = GeneralHook();
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [delId, setdelId] = useState([]);
  const [featuredTimeoff, setfeaturedTimeoff] = useState([]);
  const [loading, setloading] = useState(false);
  const [role, setrole] = useState("");
  const ReqTimeOffModelRef = useRef();
  const ConfirmationDialogRef = useRef();
  let tab = getGeneral("changeNumber", "fieldsBlockVariable");
  const userReducer = useSelector((state) => state.userReducer);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     USE EFFECTS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    getLeavingRequest();
    FeaturedTimeoff();
  }, [tab]);
  useEffect(() => {
    let role =
      userReducer?.userDetails?.details[1]?.block[0]?.inputs[7]?.value?.toLowerCase();
    setrole(role);
  }, [userReducer?.userDetails?.details[1]?.block[0]?.inputs[7]?.value]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                Get leave request                               ||
  // ! ||--------------------------------------------------------------------------------||
  const getLeavingRequest = () => {
    setloading(true);
    const company_id = getGeneral("userReducer", "userDetails").company_id;
    const user_id = getGeneral("changeNumber", "selectedPeopleId");
    getLeaveRequest({ company_id, user_id }, tokken).then((e) => {
      console.log("Response:", e);
      if (e.data.status == "success") {
        setData(e.data.data);
      } else if (e.data.status == "error") {
        toastError(e.data.data.message);
      }
      setTimeout(() => {
        setloading(false);
      }, 1000);
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                             Get leave request by id                            ||
  // ! ||--------------------------------------------------------------------------------||
  const getLeaveRequestByIdFun = (id) => {
    getLeaveRequestBYID({ id }, tokken).then((e) => {
      console.log("Response:", e);
      if (e.data.status == "success") {
        console.log("e:", e?.data?.data[0]);
        setEditData(e?.data?.data[0]);
        setLeaveRequest(e?.data?.data[0]);
        ReqTimeOffModelRef.current.handleOpen();
      } else if (e.data.status == "error") {
        toastError(e.data.data.message);
      }
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                              DELETE LEAVE REQUEST                              ||
  // ! ||--------------------------------------------------------------------------------||
  const DelRequest = () => {
    const company_id = getGeneral("userReducer", "userDetails").company_id;
    const user_id = getGeneral("changeNumber", "selectedPeopleId");
    delLeaveRequest({ user_id, company_id }, tokken).then((e) => {
      console.log("Response:", e);
      if (e.data.status == "success") {
        toastSuccess("Request deleted successfully");
        getLeavingRequest();
        FeaturedTimeoff();
        setTimeout(() => {
          ConfirmationDialogRef.current.handleClose();
        }, 500);
      } else if (e.data.status == "error") {
        toastError(e.data.data.message);
        setTimeout(() => {
          ConfirmationDialogRef.current.handleClose();
        }, 500);
      }
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                              GET FEATURED TIMEOFF                              ||
  // ! ||--------------------------------------------------------------------------------||
  const FeaturedTimeoff = () => {
    var userData = getGeneral("userReducer", "userDetails");
    getFeaturedTimeoff(
      { user_id: getGeneral("changeNumber", "selectedPeopleId") },
      tokken
    ).then((e) => {
      if (e.data.status == "success") {
        setfeaturedTimeoff(e.data.data);
        console.log("FeaturedTimeoff:", e.data.data);
        setGeneral("FeaturedTimeoff", e.data.data);
      } else if (e.data.status == "error") {
        toastError(e.data.data.message);
      }
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                            UPDATE LEAVE REQ STATUS                             ||
  // ! ||--------------------------------------------------------------------------------||
  const handleLeaveReqStatus = (leave_request_id, status) => {
    console.log(leave_request_id);
    updateLeaveRequestStatus({ leave_request_id, status }, tokken).then((e) => {
      if (e.data.status === "success") {
        getLeavingRequest();
      }
    });
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     RETURN                                     ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <Box sx={{ padding: 3 }}>
      {/* LEAVE SUMMERY */}
      <ConfirmationDialog
        confirm={() => {
          DelRequest();
        }}
        title="Are you sure!"
        desc="Are you sure you want to delete the request? Please note that this action is irreversible and cannot be undone."
        ref={ConfirmationDialogRef}
      />
      <Grid container spacing={2}>
        {getGeneral("attendenceReducer", "featuredTimeoff")?.map(
          (timeoff, i) => {
            if (role === "admin" ? true : i < 3)
              return (
                !loading && (
                  <Grid key={i} xs={3} sx={{ padding: 1 }}>
                    <Box className="leaveSummeryContainer">
                      <div className="fh3">
                        {timeoff.remaining_days}/
                        {timeoff.timeoff_details.total_days} Used
                      </div>
                      <div className="fp">
                        {timeoff.timeoff_details.timeoff_type}
                      </div>
                    </Box>
                  </Grid>
                )
              );
          }
        )}

        {!loading && role !== "admin" && (
          <Grid xs={3} className="HEndVEnd" sx={{ padding: 1 }}>
            <RequestTimeoffModel
              ref={ReqTimeOffModelRef}
              reload={() => {
                getLeavingRequest();
              }}
            />
          </Grid>
        )}
      </Grid>
      {loading && (
        <Grid container>
          <Grid xs={3}>
            <Skeleton animation="wave" height={90} sx={{ width: "90%" }} />
          </Grid>
          <Grid xs={3}>
            <Skeleton animation="wave" height={90} sx={{ width: "90%" }} />
          </Grid>
          <Grid xs={3}>
            <Skeleton animation="wave" height={90} sx={{ width: "90%" }} />
          </Grid>
          <Grid xs={3}>
            <Skeleton animation="wave" height={90} sx={{ width: "90%" }} />
          </Grid>
        </Grid>
      )}
      <Box sx={{ paddingTop: 5 }} className="fh2">
        Request History
      </Box>
      {/* REQUEST TIMEOFF HISTORY */}
      <TableContainer style={{ marginTop: "10px" }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell className="CentraNo2-m fp">Type</TableCell>
              <TableCell className="CentraNo2-m fp">Duration</TableCell>
              <TableCell className="CentraNo2-m fp">Status</TableCell>
              <TableCell className="CentraNo2-m fp">Reason</TableCell>
              {role === "admin" && (
                <TableCell className="CentraNo2-m fp">Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              data.map((e, i) => (
                <TableRow
                  key={i}
                  className="f-12 CentraNo2-m"
                  component="th"
                  scope="row"
                >
                  <TableCell
                    className="CentraNo2-m fp v-center"
                    sx={{ color: "#545459" }}
                  >
                    {e.type}
                  </TableCell>
                  <TableCell className="CentraNo2 fp v-center">
                    <Box style={{ display: "flex" }}>
                      <CalenderIcon
                        month={moment(e.from).format("MMM")}
                        date={moment(e.from).format("DD")}
                      />
                      <img
                        src={Arrow}
                        width={25}
                        style={{ margin: "0px 10px" }}
                      />
                      <CalenderIcon
                        month={moment(e.to).format("MMM")}
                        date={moment(e.to).format("DD")}
                      />
                      <Box
                        className="CentraNo2 fp-12"
                        sx={{ marginLeft: 1, color: "#525257" }}
                      >
                        Holidays
                        <br />
                        <Box className="fp-10" sx={{ color: "#7B7B7B" }}>
                          {moment(e.to).diff(moment(e.from), "days") + 1} Day(s)
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={`CentraNo2 fp v-center`}>
                    <span className={`${statusBadge(e.status)} `}>
                      {e.status}
                    </span>
                  </TableCell>
                  <TableCell className={`CentraNo2 fp v-center`}>
                    <span>{e.reason}</span>
                  </TableCell>
                  {role === "admin" && (
                    <TableCell
                      className="CentraNo2 fp jc-r v-center"
                      sx={{ width: "17.5%" }}
                    >
                      <CloseIcon
                        className="closeico"
                        onClick={() =>
                          handleLeaveReqStatus(e._id, "Disapproved")
                        }
                      />
                      <CheckIcon
                        className="checkico"
                        onClick={() => handleLeaveReqStatus(e._id, "Approved")}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <Box>
          <Skeleton animation="wave" height={60} width={"100%"} />
          <Skeleton animation="wave" height={60} width={"80%"} />
          <Skeleton animation="wave" height={60} width={"60%"} />
        </Box>
      )}
    </Box>
  );
}
