// import React, { useState, useEffect } from "react";
// import { Box } from "@mui/material";
// import PlayIcon from "./assets/img/icon/playg.svg";
// import StopIcon from "./assets/img/icon/stop.svg";
// import play from "./assets/img/icon/play.png";
// import GeneralHook from "./utilities/GeneralHook";
// import pauseIcon from "./assets/img/icon/pauseIcon.svg";
// import ReloadReduxHook from "./utilities/ReloadReduxHook";
// import { checkInOutAttendence } from "./helper/index";
// import { useDispatch } from "react-redux";
// import moment from "moment";
// import { toastSuccess, toastError } from "./utilities/toast";
// import playIcon from "./assets/img/icon/playIcon.svg";
// const TrackingAttendenceNew = () => {
//   // ! ||--------------------------------------------------------------------------------||
//   // ! ||                                     STATES                                     ||
//   // ! ||--------------------------------------------------------------------------------||
//   const [tracking, settracking] = useState(0);
//   const [seconds, setSeconds] = useState(0);
//   const [openOption, setopenOption] = useState(0);
//   const { getGeneral, setGeneral } = GeneralHook();
//   const { AttendanceReload } = ReloadReduxHook();
//   const dispatch = useDispatch();
//   // ! ||--------------------------------------------------------------------------------||
//   // ! ||                                   USE EFFECT                                   ||
//   // ! ||--------------------------------------------------------------------------------||
//   useEffect(() => {
//     setSeconds(getGeneral("attendenceReducer", "tracking"));
//     settracking(getGeneral("attendenceReducer", "trackingStage"));
//   }, []);

//   useEffect(() => {
//     setGeneral("TrackingStage", tracking);
//   }, [tracking]);

//   useEffect(() => {
//     let interval = null;
//     if (tracking == 1) {
//       interval = setInterval(() => {
//         setSeconds((prevSeconds) => {
//           let t = prevSeconds + 1;
//           setGeneral("SetTracking", t);
//           return t;
//         });
//       }, 1000);
//     } else if (tracking == 0) {
//       setSeconds(0);
//     }
//     return () => clearInterval(interval);
//   }, [tracking]);

//   // ! ||--------------------------------------------------------------------------------||
//   // ! ||                                     METHOD                                     ||
//   // ! ||--------------------------------------------------------------------------------||
//   //   GET TRACKING ICON
//   const getIcon = (stage) => {
//     return stage === 0 ? playIcon : pauseIcon;
//   };

//   const getColor = (stage) => {
//     return stage === 0 ? "#919196" : "#057979";
//   };
//   //   SECONDS FORMATING
//   const formatTime = (totalSeconds) => {
//     const hours = Math.floor(totalSeconds / 3600);
//     const minutes = Math.floor((totalSeconds % 3600) / 60);
//     const seconds = totalSeconds % 60;

//     const formattedHours = hours.toString().padStart(2, "0");
//     const formattedMinutes = minutes.toString().padStart(2, "0");
//     const formattedSeconds = seconds.toString().padStart(2, "0");

//     return ${formattedHours}:${formattedMinutes}:${formattedSeconds};
//   };
//   //   CHANGE TRACKING STATES
//   const startTracking = () => {
//     if (tracking == 0) {
//       settracking(1);
//       CheckInTracking();
//     } else if (tracking == 1) {
//       settracking(0);
//       CheckOutTracking();
//     }
//   };

//   const CheckInTracking = () => {
//     let token = getGeneral("userReducer", "token");
//     let data = {
//       company_id: getGeneral("userReducer", "userDetails").company_id,
//       punch: moment().format("MMMM DD YYYY, h:mm:ss a"),
//     };
//     checkInOutAttendence(data, token).then((res) => {
//       AttendanceReload();
//       if (res.data.status === "success") {
//         toastSuccess(res.data.data.msg);
//       } else {
//         toastError("Something went wrong :(");
//       }
//     });
//   };

//   const CheckOutTracking = () => {
//     let token = getGeneral("userReducer", "token");
//     let data = {
//       company_id: getGeneral("userReducer", "userDetails").company_id,
//       punch: moment().format("MMMM DD YYYY, h:mm:ss a"),
//     };
//     checkInOutAttendence(data, token).then((res) => {
//       AttendanceReload();
//       if (res.data.status === "success") {
//         toastSuccess(res.data.data.msg);
//       } else {
//         toastError("Something went wrong :(");
//       }
//     });
//   };
//   // Render Function
//   return (
//     <>
//       <Box
//         sx={{ width: "100%" }}
//         className={`${
//           openOption == 0 ? (tracking == 2 ? "bounce-text" : null) : null
//         }`}
//       >
//         <Box className="h-center" style={{ paddingBottom: "5px" }}>
//           <div
//             className={`${
//               tracking == 0 ? "trackingbtnDefault" : "trackingbtnOn"
//             }`}
//           >
//             <img
//               onClick={() => startTracking()}
//               className="attendenceTrackingIcono"
//               src={getIcon(tracking)}
//               alt="Tracking icon"
//             />
//           </div>
//         </Box>
//         <Box
//           className="h-center attendenceTrackingText"
//           sx={{ color: getColor(tracking) }}
//         >
//           {formatTime(seconds)}
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default TrackingAttendenceNew;
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import GeneralHook from "./utilities/GeneralHook";
import ReloadReduxHook from "./utilities/ReloadReduxHook";
import { checkInOutAttendence } from "./helper/index";
import { useDispatch } from "react-redux";
import moment from "moment";
import { toastSuccess, toastError } from "./utilities/toast";
import playIcon from "./assets/img/icon/playIcon.svg";
import pauseIcon from "./assets/img/icon/pauseIcon.svg";

const TrackingAttendenceNew = () => {
  const { getGeneral, setGeneral } = GeneralHook();
  const { AttendanceReload } = ReloadReduxHook();
  const dispatch = useDispatch();

  const [tracking, settracking] = useState(() => {
    const storedTracking = localStorage.getItem("tracking");
    return storedTracking ? parseInt(storedTracking) : 0;
  });
  
  const [seconds, setSeconds] = useState(() => {
    const storedSeconds = localStorage.getItem("seconds");
    return storedSeconds ? parseInt(storedSeconds) : 0;
  });

  useEffect(() => {
    localStorage.setItem("tracking", tracking);
  }, [tracking]);

  useEffect(() => {
    localStorage.setItem("seconds", seconds);
  }, [seconds]);

  useEffect(() => {
    let interval = null;
    if (tracking === 1) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          let t = prevSeconds + 1;
          setGeneral("SetTracking", t);
          return t;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [tracking]);

  const startTracking = () => {
        if (tracking === 0) {
          settracking(1);
          CheckInTracking();
        } else if (tracking === 1) {
          settracking(0);
          CheckOutTracking();
        }
      };
  const CheckInTracking = () => {
    let token = getGeneral("userReducer", "token");
    let data = {
      company_id: getGeneral("userReducer", "userDetails").company_id,
      punch: moment().format("MMMM DD YYYY, h:mm:ss a"),
    };
    checkInOutAttendence(data, token).then((res) => {
      AttendanceReload();
      if (res.data.status === "success") {
        toastSuccess(res.data.data.msg);
      } else {
        toastError("Something went wrong :(");
      }
    });
  };

  const CheckOutTracking = () => {
    let token = getGeneral("userReducer", "token");
    let data = {
      company_id: getGeneral("userReducer", "userDetails").company_id,
      punch: moment().format("MMMM DD YYYY, h:mm:ss a"),
    };
    checkInOutAttendence(data, token).then((res) => {
      AttendanceReload();
      if (res.data.status === "success") {
        toastSuccess(res.data.data.msg);
      } else {
        toastError("Something went wrong :(");
      }
    });
    settracking(0); // Reset tracking state to zero
    setSeconds(0); // Reset seconds to zero
    setGeneral("SetTracking", 0); // Reset tracking in local storage
    // You can also reset tracking in redux state if needed
  };

  const getIcon = (stage) => {
    return stage === 0 ? playIcon : pauseIcon;
  };

  const getColor = (stage) => {
    return stage === 0 ? "#919196" : "#057979";
  };

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  return (
    <>
    
      <Box sx={{ width: "100%" }}>
        <Box className="h-center" style={{ paddingBottom: "5px" }}>
          <div className={`${tracking === 0 ? "trackingbtnDefault" : "trackingbtnOn"}`}>
            <img
              onClick={startTracking}
              className="attendenceTrackingIcono"
              src={getIcon(tracking)}
              alt="Tracking icon"
            />
          </div>
        </Box>
        <Box
          className="h-center attendenceTrackingText"
          sx={{ color: getColor(tracking) }}
        >
          {formatTime(seconds)}
        </Box>
      </Box>
    </>
  );
};

export default TrackingAttendenceNew;
// const [tracking, setTracking] = useState(0);