import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const NewInputSearch = (props) => {
  const [query, setQuery] = useState("");
  const data = [
    { name: "Ali Rizvi" },
    { name: "Jawad Tahir" },
    { name: "Farukh" },
  ];

  return (
    <div className="flo-input">
      <Autocomplete
        freeSolo
        options={data.map((item) => item.name)}
        inputValue={query}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder=""
            disabled={false}
            variant="filled"
            rows={1}
            type="text"
            style={{
              minWidth: "",
              maxWidth: "",
              width: "100%",
              background: "white",
              overflow: "hidden",
              border: "1px solid #d1d7d7",
              borderRadius: "5px",
            }}
            // label={props.label}
            label="Search Departments"
          />
        )}
        clearIcon={null}
        classes={{
          root: "flo-input",
          inputRoot: "flo-input",
          input: "flo-input",
        }}
      />
    </div>
  );
};

export default NewInputSearch;
