import React from "react";
import Input from "../input";
import NewInputSearch from "./NewInputSearch";
const DropdownModel = (props) => {
  return (
    <section >
      <div className="p-relative">
        <div>
          {/* <Input label={props.label} /> */}
          <NewInputSearch label={props.label} />
          <svg
            className="floSelectArrowup"
            width="11"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.625 1L7 6.625L1.375 1"
              stroke="#696969"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            className="floSelectTimes"
            width="8"
            height="8"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5"
              stroke="#696969"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default DropdownModel;
