import * as React from "react";
import Box from "@mui/material/Box";
import employeeImg from "../assets/img/img/employee.svg";
import collaboratorImg from "../assets/img/img/collaborator.svg";
import clientImg from "../assets/img/img/client.svg";
import Input from "../Components/input";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";

export default function AppendPeopleinvite(props) {
  const [body, setbody] = React.useState(false);
  const userReducer = useSelector((state) => state.userReducer);

  React.useEffect(() => {
    // console.log("Body:",body);
    props.getData(body);
  }, [body]);

  return (
    <Grid container className="AddPeopleModelInviteContainer">
      <Grid item xs={6}>
        <div style={{ padding: 10 }}>
          <Input
            getValue={(e) =>
              setbody({
                ...body,
                name: e,
                type: props.type,
                company_id: userReducer.userDetails.company_id,
                company: userReducer.userDetails.company_name,
              })
            }
            placeholder="Tom"
            label="Name"
            value={body.name}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{ padding: 10 }}>
          <Input
            getValue={(e) => setbody({ ...body, email: e, type: props.type })}
            placeholder="tom@gmail.com"
            label="Email"
            value={body.email}
          />
        </div>
      </Grid>
    </Grid>
  );
}
