import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const FloRadio = (props) => {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 DEFINED STATUES                                ||
  // ! ||--------------------------------------------------------------------------------||
  const PeopleReducer = useSelector((state) => state.changeNumber);
  const dispatch = useDispatch();
  const [select, setselect] = useState(props.value ? props.value : "");
  const [disableComp, setDisableComp] = useState(true);
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    let externalField = PeopleReducer?.peoples[0]?.externalField;
    if (props.tabName == "personal") {
      console.log("props?.tabName:", props?.tabName);
      GrantPermission(
        externalField,
        "personal",
        "just_update_my_personal_info"
      );
    }
    if(props.tabName=="work"){
      GrantPermission(externalField, "work", "just_update_my_work_info");
    }
    // GrantPermission(externalField, "people", "just_update_my_personal_info");
  }, [props.tabName]);
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  const updateValue = (e, label, block, tabName) => {
    console.log("Update Value: ", { e, label, block, tabName });

    let PeopleReducerDeepCopy = [...PeopleReducer.fieldsBlockVariable];

    PeopleReducerDeepCopy.filter((tabs) => {
      tabs.tab_name = tabs.tab_name.toLowerCase();

      if (tabs.tab_name == tabName) {
        tabs.block.filter((tabsBlock) => {
          let tempFilterblcokName = tabsBlock.blockName
            .toLowerCase()
            .replace(/\s+/g, "_");
          let tempParamblcokName = block.blockName
            .toLowerCase()
            .replace(/\s+/g, "_");
          if (tempFilterblcokName == tempParamblcokName) {
            tabsBlock.inputs.filter((blockInputs) => {
              let tempblocklabel = blockInputs.label
                .toLowerCase()
                .replace(/\s+/g, "_");
              let tempparamlabel = label.toLowerCase().replace(/\s+/g, "_");
              if (tempblocklabel == tempparamlabel) {
                console.log("Tab:", blockInputs);
                blockInputs.value = e;
                setTimeout(() => {
                  dispatch({
                    type: "UPDATEFIELD",
                    payload: PeopleReducerDeepCopy,
                  });
                }, 500);
              }
            });
          }
        });
      }
    });
  };

  function GrantPermission(permissionsArray, module, permission_string) {
    const hasTimeOffPermission = Filter(permissionsArray, module);
    console.log("hasTimeOffPermission_______:", hasTimeOffPermission);
    console.log("props?.tabName________:", module);
    if (hasTimeOffPermission) {
      if (props?.tabName == module) {
        if (hasTimeOffPermission?.length > 0) {
          let hasPermissions = hasPermission(
            hasTimeOffPermission,
            permission_string
          );
          console.log("hasPermissions________:", hasPermissions);
          if (hasPermissions == true) {
            setDisableComp(false);
          } else {
            setDisableComp(true);
          }
        } else {
          setDisableComp(true);
        }
      }
    }
  }
  function hasPermission(permissionsArray, permissionName) {
    return permissionsArray?.some(
      (permission) =>
        permission.description === permissionName &&
        permission.module == props?.tabName
    );
  }
  function Filter(permissionsArray, module) {
    return permissionsArray?.filter(
      (permission) => permission.module == module
    );
  }
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <div className="floRadio">
      <ul>
        {props.data.map((e, i) => {
          return (
            <li
              key={i}
              onClick={() => {
                if (!disableComp) {
                  setselect(
                    e[props.keyValue?.toLowerCase().replace(/\s+/g, "_")]
                  );
                  updateValue(
                    e[props.keyValue?.toLowerCase().replace(/\s+/g, "_")],
                    props.label,
                    props.block,
                    props.tabName
                  );
                }
              }}
            >
              <div
                className={`${i == 0 ? "floRadioActiveStateLeft" : null}  ${
                  i == props.data.length - 1 ? "floRadioActiveStateRight" : null
                }  ${
                  select ==
                  e[props.keyValue?.toLowerCase().replace(/\s+/g, "_")]
                    ? disableComp
                      ? "floRadioDeactiveState"
                      : "floRadioActiveState"
                    : null
                }`}
              ></div>
              <div>
                {e[props.keyValue?.toLowerCase().replace(/\s+/g, "_")]}{" "}
                
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FloRadio;
