import React, { useState, useEffect } from "react";
import { Box, Paper, Grid, Divider, IconButton } from "@mui/material";
import _ from "lodash";
import editAddressIcon from "../assets/img/icon/Edit_address.svg";
import deleteAddressIcon from "../assets/img/icon/deleteAddress.svg";
import FloSelect from "../Components/FloSelect";
import DatePicker from "../Components/DatePicker";
import FloRadio from "../Components/FloRadio";
import FloTags from "../Components/Tags";
import SubFormFactory from "../Components/SubFormFactory";
import StartEndDate from "../Components/startenddate";
import AddCustomFields from "../Components/AddCustomFields";
import AttendencePeopleTable from "../Components/AttendencePeopleTable";
import { UpdatePeople } from "../helper/index";
import { useSelector, useDispatch } from "react-redux";
import RequestTimeOff from "./RequestTimeOff";
import GeneralHook from "../utilities/GeneralHook";
import LoaderComp from "../Components/Loader";
import RequestApproveTimeOff from "../Screens/ApproveTimeOff";

import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
export default function FormFactory(props) {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 DEFINED STATUES                                ||
  // ! ||--------------------------------------------------------------------------------||
  const { getGeneral, setGeneral } = GeneralHook();
  const PeopleReducer = useSelector((state) => state.changeNumber);
  const userReducer = useSelector((state) => state.userReducer);
  const [editBlockForm, seteditBlockForm] = useState(false);
  const [loader, setLoader] = useState(false);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  const formFactory = (input, block) => {
    console.log("input:", props?.tabName);
    let permissions = PeopleReducer.peoples.filter((people) => {
      return people._id == PeopleReducer.selectedPeopleId;
    });
    let peoplePermission = permissions[0]?.externalField.filter(
      (permission) => {
        return permission.module == "people";
      }
    );
    let formFactory = {
      tags: (
        <FloTags
          tabName={props.tabName}
          block={block}
          key={input.value}
          value={""}
          data={input.data}
          placeholder={"Write a tag..."}
          label={input.label}
        />
      ),
      datepicker: (
        <DatePicker
          key={input.value}
          tabName={props.tabName}
          block={block}
          keyValue={input.label}
          data={input.data}
          placeholder={input.placeholder}
          label={input.label}
          value={input.value}
        />
      ),
      radio: (
        <FloRadio
          key={input.value}
          tabName={props.tabName}
          block={block}
          keyValue={input.label}
          label={input.label}
          data={input.data}
          value={input.value}
        />
      ),
      select: (
        <FloSelect
          key={input.value}
          tabName={props.tabName}
          block={block}
          keyValue={input.label}
          data={input.data}
          placeholder={input.placeholder}
          value={input.value}
          label={input.label}
          multiline={input.type == "longtext" ? true : false}
          row={input.type == "longtext" ? 5 : 1}
        />
      ),
      input: (
        <>
          <FloSelect
            key={input.value}
            tabName={props.tabName}
            block={block}
            keyValue={input.label}
            data={input.data}
            placeholder={input.placeholder}
            disabled={input.disable}
            label={input.label}
            value={input.value}
            multiline={input.type == "longtext" ? true : false}
            row={input.type == "longtext" ? 5 : 1}
          />
        </>
      ),
      longtext: (
        <FloSelect
          key={input.value}
          tabName={props.tabName}
          block={block}
          keyValue={input.label}
          data={input.data}
          placeholder={input.placeholder}
          label={input.label}
          value={input.value}
          multiline={input.type == "longtext" ? true : false}
          row={input.type == "longtext" ? 5 : 1}
        />
      ),
      startenddate: (
        <StartEndDate
          key={input.value}
          keyValue={input.label}
          data={input.data}
        />
      ),
      attendenceTable: (
        <AttendencePeopleTable
          key={input.value}
          keyValue={input.label}
          data={input.data}
        />
      ),
      timeoffSection: (
        <>
          {userReducer?.userDetails?.details[1]?.block[0]?.inputs[7]?.value?.toLowerCase() ==
            "admin" ||
          (peoplePermission&& peoplePermission[0]?.description ==
            "can_view_my_team_personal_info" &&
            userReducer?.userDetails?._id !== PeopleReducer?.selectedPeopleId) ? (
              <RequestApproveTimeOff
              key={input.value}
              keyValue={input.label}
              data={input.data}
              />
            ) : (
            <RequestTimeOff
              key={input.value}
              keyValue={input.label}
              data={input.data}
            />
          )}
        </>
      ),
    };
    return formFactory[input.type];
  };
  const updatePeople = () => {
    setLoader(true);
    let data = {
      name:
        PeopleReducer.fieldsBlockVariable[0].block[0].inputs[0].value +
        " " +
        PeopleReducer.fieldsBlockVariable[0].block[0].inputs[1].value,
      email: PeopleReducer.fieldsBlockVariable[0].block[3].inputs[0].value,
      details: PeopleReducer.fieldsBlockVariable,
      company_id: getGeneral("userReducer", "userDetails").company_id,
      company: PeopleReducer.fieldsBlockVariable[0].block[0].inputs[3].value,
    };
    console.log("data", data);
    UpdatePeople(data)
      .then((e) => {
        if (e.data.status == "success") {
          toastSuccess("User updated successfully");
        } else {
          toastError("Something went wrong");
        }
        setLoader(false); // Stop the loader
      })
      .catch((error) => {
        toastError("Something went wrong");
        setLoader(false); // Stop the loader in case of error
      });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <div
      className={`${props.tabName} ${
        props.activeTab == props.tabName ? "d-block" : "d-none"
      }`}
    >
      {props.tab?.block?.map((block, i) => {
        return (
          <div key={i} className="FloBlockContainer">
            <div
              className={`FloBlockName v-center ${
                block.blockName == "" ? "d-none" : null
              }`}
            >
              {block.blockName}
            </div>
            <SubFormFactory
              key={i}
              tabName={props.tabName}
              block={block}
              formFactory={formFactory}
            />
          </div>
        );
      })}
      {/* <h1>{JSON.stringify(props.tab.customField)}</h1> */}
      <div className={`${props.tab.customField == false ? "d-none" : null}`}>
        <AddCustomFields tabName={props.tabName} />
        {/* <div
          onClick={() => updatePeople()}
          className="btn-block btn_text hover"
          style={{ float: "right", maxWidth: 150 }}
        >
          Update
        </div> */}
        <div
          onClick={() => !loader && updatePeople()}
          className="btn-block btn_text hover"
          style={{ float: "right", maxWidth: 150 }}
        >
          <LoaderComp loader={loader}>Update</LoaderComp>
        </div>
      </div>
    </div>
  );
}
