import React from "react";
import { useSelector, useDispatch } from "react-redux";

function GeneralHook() {
  const onboardingReducer = useSelector((state) => state.userReducer);
  const changeNumber = useSelector((state) => state.changeNumber);
  const userReducer = useSelector((state) => state.userReducer);
  const attendenceReducer = useSelector((state) => state.attendenceReducer);

  const dispatch = useDispatch();
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     METHODS                                    ||
  // ! ||--------------------------------------------------------------------------------||
  const getreducer = (e) => {
    let data = {
      onboardingReducer,
      changeNumber,
      userReducer,
      attendenceReducer,
    };
    return data[e];
  };
  const setGeneral = (type, data) => {
    dispatch({
      type: type,
      payload: data,
    });
  };
  const getGeneral = (reducerName, state) => {
    return getreducer(reducerName)[state];
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     RETURN                                     ||
  // ! ||--------------------------------------------------------------------------------||
  return {
    setGeneral: (type, data) => setGeneral(type, data),
    getGeneral: (reducerName, state) => getGeneral(reducerName, state),
  };
}

export default GeneralHook;
