import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import logoPhone from "../../assets/img/logo/logo_circle.svg";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "../../assets/img/icon/home.svg";
import workLoadIcon from "../../assets/img/icon/workload.svg";
import manageIcon from "../../assets/img/icon/manageNew.svg";
import Typography from "@mui/material/Typography";
import logo from "../../assets/img/logo/flo.svg";
import notificationsIcon from "../../assets/img/icon/notification.svg";
import AttendenceTracking from "../../Components/AttendenceTracking";
import settingIcon from "../../assets/img/icon/setting.svg";
import profileIcon from "../../assets/img/icon/temp.svg";
import logoutIcon from "../../assets/img/icon/logout.svg";
import FloHeader from "../../Components/layouts/Layout2/floHeader";
import FloToolbar from "../../Components/toolbar";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import chart from "../../assets/img/icon/chart.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Open from "../../assets/img/icon/close.png";
import Close from "../../assets/img/icon/open.png";
import managnew from "../../assets/img/icon/managnew.png";
import manageico from "../../assets/img/icon/manageico.svg";
import Tooltip from "@mui/material/Tooltip";
import TrackingAttendenceNew from "../../TrackingAttendenceNew";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../../utilities/toast";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar(props) {
  const userReducer = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [arrowSidebarOpen, setArrowSidebarOpen] = React.useState(false);
  const [drawerOpenByHover, setdrawerOpenByHover] = React.useState(true);
  const sideItems = [
    {
      title: "Home",
      icon: HomeIcon,
      url: "/manage",
    },
    // {
    //   title: "Workload",
    //   icon: workLoadIcon,
    //   url: "/manage",
    // },
  ];
  const sidebarItems = [
    {
      title: "Notifications",
      icon: notificationsIcon,
    },
    {
      title: "Setting",
      icon: settingIcon,
    },
    {
      title: "Profile",
      icon: profileIcon,
    },
  ];
  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    console.log("userReducer:", userReducer);
    if (userReducer.token == "") {
      navigate("/");
      toastError("Sorry, you are not authorized!");
    }
  }, []);

  const logout = () => {
    dispatch({
      type: "CLEANUSERREDUCER",
    });
    dispatch({
      type: "CLEANPEOPLEREDUCER",
    });
    dispatch({
      type: "CLEANONBOARDREDUCER",
    });
    dispatch({
      type: "CLEANATTENDENCEREDUCER",
    });
    navigate("/");
  };

  const handleDrawerClick = () => {
    setdrawerOpenByHover(open);
    setOpen(!open);
  };

  const handleDrawerHover = () => {
    if (drawerOpenByHover) {
      setOpen(true);
    }
  };

  const handleDrawerLeave = () => {
    if (drawerOpenByHover) {
      setOpen(false);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={open}
        className="floDrawer"
        onMouseEnter={handleDrawerHover}
        onMouseLeave={handleDrawerLeave}
      >
        <List style={{ paddingTop: "0px" }} className="sidedrawer">
          <div>
            <div>
              {["Flo"].map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      paddingLeft: "20px",
                      paddingright: "20px",
                      justifyContent: open ? "initial" : "center",
                      px: "2.5",
                    }}
                    style={{
                      padding: "17px 0px",
                      paddingTop: 10,
                      borderRadius: "10px",
                    }}
                    className=" flo_drawerListBorder"
                    // onClick={handleDrawer}
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: "center",
                      }}
                      style={{ minWidth: "40px" }}
                    >
                      <img src={logoPhone} style={{ width: "28px" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                      <img src={logo} style={{ width: "50px" }} />
                    </ListItemText>
                    {/* {open ?  <img src={Open} style={{width:"20px"}}/> :  <img src={Close} style={{position:"absolute",left:"3em", width:"20px",zIndex:"99999"}}/>} */}
                    {open ? (
                      <ArrowBackIosNewIcon style={{ width: "18px" }} />
                    ) : (
                      <ArrowForwardIosIcon
                        style={{
                          position: "absolute",
                          left: "2em",
                          width: "18px",
                          zIndex: "99999",
                        }}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </div>

            <div className="sidedrawericonsdistance">
              <div>
                {sideItems.map((data, index) => (
                  <ListItem
                    onClick={() => {
                      navigate(data.url);
                    }}
                    key={index}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Tooltip
                      title={data.title}
                      placement="right-start"
                      followCursor
                    >
                      <Box className="activeBar"></Box>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          paddingLeft: "20px",
                          paddingright: "20px",
                          px: 2.5,
                        }}
                        style={{
                          padding: "8px 0px",
                          borderRadius: "10px",
                          height: 35,
                        }}
                        className="  flo_drawerListBorder"
                      >
                        <ListItemIcon
                          sx={{
                            justifyContent: "center",
                          }}
                          style={{ minWidth: "40px" }}
                        >
                          <img
                            src={data.icon}
                            style={{ height: "17px", width: "17px" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={data.title}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                        {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </div>
            </div>
            <div>
              {["Manage"].map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <Tooltip title={text} placement="right-start" followCursor>
                    <ListItemButton
                      sx={{
                        paddingLeft: "20px",
                        paddingright: "20px",
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      style={{
                        padding: "0px 0px",
                        borderRadius: "10px",
                        height: 35,
                      }}
                      className=" flo_drawerListBorder managebuttonborder"
                    >
                      <ListItemIcon
                        sx={{
                          justifyContent: "center",
                        }}
                        style={{ minWidth: "40px" }}
                      >
                        <img
                          src={!open ? manageIcon : manageico}
                          style={{ height: "17px", width: "17px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        style={{ color: "#ffffff" }}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </div>
          </div>
          <div>
            <div>
              <ListItem key={0} disablePadding sx={{ display: "block" }}>
                <ListItemIcon
                  sx={{
                    justifyContent: "center",
                  }}
                  style={{ minWidth: "40px" }}
                >
                  {/* <AttendenceTracking /> */}
                  <TrackingAttendenceNew/>
                </ListItemIcon>
              </ListItem>
              <ListItem className="playTracking">
                <Divider />
              </ListItem>
              <ListItem key={1} disablePadding sx={{ display: "block" }}>
                <Tooltip
                  title={"Coming Soon"}
                  placement="right-start"
                  followCursor
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      paddingLeft: "20px",
                      paddingright: "20px",
                      px: 2.5,
                    }}
                    style={{
                      padding: "8px 0px",
                      borderRadius: "10px",
                      height: 35,
                    }}
                    className="  flo_drawerListBorder"
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: "center",
                      }}
                      style={{ minWidth: "40px" }}
                    >
                      <img
                        src={notificationsIcon}
                        style={{ height: "17px", width: "17px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Notifications"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem key={2} disablePadding sx={{ display: "block" }}>
                <Tooltip
                  title={"Coming Soon"}
                  placement="right-start"
                  followCursor
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      paddingLeft: "20px",
                      paddingright: "20px",
                      px: 2.5,
                    }}
                    style={{
                      padding: "8px 0px",
                      borderRadius: "10px",
                      height: 35,
                    }}
                    className="  flo_drawerListBorder"
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: "center",
                      }}
                      style={{ minWidth: "40px" }}
                    >
                      <img
                        src={settingIcon}
                        style={{ height: "17px", width: "17px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Setting"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem key={3} disablePadding sx={{ display: "block" }}>
                <Tooltip
                  title={"Coming Soon"}
                  placement="right-start"
                  followCursor
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      paddingLeft: "20px",
                      paddingright: "20px",
                      px: 2.5,
                    }}
                    style={{
                      padding: "8px 0px",
                      borderRadius: "10px",
                      height: 35,
                    }}
                    className="  flo_drawerListBorder"
                    onClick={logout}
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: "center",
                      }}
                      style={{ minWidth: "40px" }}
                    >
                      <img
                        src={logoutIcon}
                        style={{ height: "17px", width: "17px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Logout"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem key={4} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    paddingLeft: "20px",
                    paddingright: "20px",
                    px: 2.5,
                  }}
                  style={{
                    padding: "8px 0px",
                    borderRadius: "10px",
                    height: 35,
                  }}
                  className="  flo_drawerListBorder"
                  // onClick={handleDrawer}
                >
                  <ListItemIcon
                    sx={{
                      justifyContent: "center",
                    }}
                    style={{ minWidth: "40px" }}
                  >
                    <span
                      className="profileimg"
                      style={{
                        backgroundColor: "#FFDDE1",
                        marginRight: 0,
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      W
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Profile"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
                </ListItemButton>
              </ListItem>
            </div>
          </div>
        </List>
        {/* <Divider /> */}
        {/* <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton 
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Box className="icono-button">
        <IconButton
          style={{ background: "none", }}
          onClick={handleDrawerClick}
          sx={{
            transition: "transform 0.8s ease-in-out",
          }}
        >
          {open ? (
            <KeyboardArrowLeftIcon
             
              className="keyboardArrowIcon"
            />
          ) : (
            <ChevronRight
             
              className="ChevronArrowIcon"
            />
          )}
        </IconButton>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, overflow: "hidden" }}>
        <FloHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
