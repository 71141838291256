import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo/Logo.svg";
import Input from "../Components/input";
import "../App.css";
import { Checkbox, Button } from "@mui/material";
import arrow from "../assets/img/icon/Arrow.svg";
import google from "../assets/img/icon/google.svg";
import outlook from "../assets/img/icon/outlook.svg";
import eyeslash from "../assets/img/icon/eyeslash.svg";
import eye from "../assets/img/icon/eye.svg";
import check from "../assets/img/icon/check.svg";
import alert from "../assets/img/icon/alert.svg";
import LoaderComp from "../Components/Loader";
import { useSelector, useDispatch } from "react-redux";

import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import { SignIn } from "../helper/index";

function Login() {
  const userReducer = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const buttonref = useRef();
  let navigate = useNavigate();
  const [showPass, setShowPass] = useState(true);
  const [loader, setloader] = useState(false);
  const [body, setbody] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    console.log(body);
  }, [body]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        buttonref.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   GET STARTED                                  ||
  // ! ||--------------------------------------------------------------------------------||
  const getStarted = () => {
    setloader(true);
    SignIn(body).then((res) => {
      if (res.data.status == "error") {
        toastError(res.data.data.message);
        setloader(false);
      } else if ((res.data.status = "success")) {
        let token = res.data.data.token;
        let userData = res.data.data.getUser;
        console.log("res", { token, userData });
        setloader(false);
        dispatch({
          type: "UPDATETOKEN",
          payload: token,
        });
        dispatch({
          type: "UPDATEUSERDATA",
          payload: userData,
        });
        navigate("/manage");

        toastSuccess("User Authenticated!");
      }
    });
  };

  return (
    <div className="signup bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading">Welcome back</h4>
      <div className="h-center">
        <div>
          <div className="board-white">
            {/* <h5 className="text-center mt-0 CentraNo2-l">
              Just need a few things to get you going
            </h5> */}
            <div className="jsb">
              <div className="signup_google">
                <h5 className="CentraNo2 v-center m-0">
                  Sign in with{" "}
                  <img src={google} style={{ marginLeft: 5, width: 25 }} />
                </h5>
              </div>
              <div className="signup_outlook">
                <h5 className="CentraNo2 v-center m-0">
                  Sign in with{" "}
                  <img src={outlook} style={{ marginLeft: 5, width: 25 }} />
                </h5>
              </div>
            </div>
            <div className="jsb v-center" style={{ margin: "15px 0px" }}>
              <div className="border-bottom" style={{ width: "45%" }}></div>
              <span style={{ fontSize: 13 }}>or</span>
              <div className="border-bottom" style={{ width: "45%" }}></div>
            </div>

            <div style={{ marginTop: 15 }}>
              <Input
                getValue={(e) => setbody({ ...body, email: e })}
                value={body.email}
                minWidth={350}
                placeholder="tom@gmail.com"
                label="Email"
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <div className="p-relative">
                <Input
                  getValue={(e) => setbody({ ...body, password: e })}
                  value={body.password}
                  password={showPass}
                  minWidth={350}
                  placeholder="*******"
                  label="Password"
                />
                {showPass ? (
                  <img
                    onClick={() => setShowPass(!showPass)}
                    src={eyeslash}
                    className="p-absolute eyetoggle"
                  />
                ) : (
                  <img
                    onClick={() => setShowPass(!showPass)}
                    src={eye}
                    className="p-absolute eyetoggle"
                  />
                )}
              </div>
            </div>
            {/* <p className="h-center CentraNo2 already_use_flo alert">
              <img src={alert} className="alert-icon" /> The password you are
              trying to enter is incorrect
            </p> */}
            <div className="space-between" style={{ marginTop: 10 }}>
              <p className="remember-me">
                <Checkbox
                  size="small"
                  sx={{
                    color: "#b3bdbc99",
                    "&.Mui-checked": {
                      color: "#ed604b",
                    },
                  }}
                />{" "}
                Remember me
              </p>
              <Link to="/forgot-password" className="forgot-pass text-s">
                Forgot password?
              </Link>
            </div>

            <Button
              ref={buttonref}
              sx={{ marginTop: 2 }}
              variant="contained"
              onClick={() => !loader && getStarted()}
              className="btn-block btn_text"
            >
              <LoaderComp loader={loader}>Get Started </LoaderComp>
            </Button>
            <p className="h-center CentraNo2 already_use_flo">
              Don’t have an account?{" "}
              <Link to="/signup" style={{ paddingLeft: 5 }}>
                Signup
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
