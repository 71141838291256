import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import baseIcon from "../assets/img/icon/base.svg";
import peopleIcon from "../assets/img/icon/people.svg";
import Eyegray from "../assets/img/icon/eyegray.svg";
import group from "../assets/img/icon/group.svg";
import sort from "../assets/img/icon/sort.svg";
import action from "../assets/img/icon/action.svg";
import share from "../assets/img/icon/share.svg";
import importExport from "../assets/img/icon/export.svg";
import addpersion from "../assets/img/icon/addpersion.svg";
import Arrow from "../assets/img/icon/arrow_b.svg";
import PlusP from "../assets/img/icon/plus_p.svg";
import assignbase from "../assets/img/icon/assignbase.svg";
import edit from "../assets/img/icon/edit.svg";
import archive from "../assets/img/icon/archive.svg";
import importIcon from "../assets/img/icon/Import.svg";
import print from "../assets/img/icon/print.svg";
import menuIcon from "../assets/img/icon/menuIcon.svg";
import deleteIcon from "../assets/img/icon/Delete.svg";
import listView from "../assets/img/icon/listView.svg";
import gridView from "../assets/img/icon/gridView.svg";
import FloMenu from "./floMenu";
import Input2 from "./input2";
import Switch from "./switch";
import Dropdown from "./dropdown";
import { useSelector, useDispatch } from "react-redux";

// ! ||--------------------------------------------------------------------------------||
// ! ||                                   MAIN METHOD                                  ||
// ! ||--------------------------------------------------------------------------------||
function Toolbar(props) {
  const [field, setfield] = useState(props.fields);
  const [fieldSearch, setfieldSearch] = useState(props.fields);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hideAll, sethideAll] = React.useState(0);
  const [groupsData, setgroupsData] = React.useState([{ value: "", order: 1 }]);
  const [sortData, setsortData] = React.useState([{ value: "", order: 1 }]);
  const open = Boolean(anchorEl);
  const peopleSD = useSelector((state) => state.changeNumber);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SORTFIELDS", payload: sortData });
  }, [sortData]);
  const shiftTab = (key) => {
    if (key == 2) {
      const element = document.querySelector(".flotab-active");
      element.style.transition = "left 0.15s";
      element.style.left = "93px";
    } else {
      const element = document.querySelector(".flotab-active");
      element.style.transition = "left 0.15s";
      element.style.left = "-2px";
    }
  };
  const searchHidenField = (e) => {
    // console.log("GetValue:", e);

    // const index = fieldSearch.findIndex((element) => element.value === e);
    // console.log(index);

    let obj = field.filter((o) => {
      // console.log(e);
      return o.title.toLowerCase().includes(e.toLowerCase());
    });
    console.log("obj:", props.fields);
    if (e == "") {
      setfieldSearch(props.fields);
    } else {
      setfieldSearch(obj);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RETURN METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <Box className="h-center bg-sec br-tb-8" sx={{ padding: "15px 0px" }}>
      <Box className="mainContainer">
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              height: 40,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <ul className="floManageFilter">
              {/* ACTIVE STATE */}
              <li className="active_state">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 6.9C22 3.4 20.6 2 17.1 2H12.9C9.4 2 8 3.4 8 6.9V8H11.1C14.6 8 16 9.4 16 12.9V16H17.1C20.6 16 22 14.6 22 11.1"
                    stroke="#007979"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 17.1C2 20.6 3.4 22 6.9 22H11.1C14.6 22 16 20.6 16 17.1V12.9C16 9.4 14.6 8 11.1 8H6.9C3.4 8 2 9.4 2 12.9"
                    stroke="#007979"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.07812 14.9998L8.02812 16.9498L11.9181 13.0498"
                    stroke="#007979"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span>Active</span>
              </li>
              {/* HIDE FIELDS */}
              <li
                style={{
                  backgroundColor: peopleSD.hidefields > 0 ? "" : "",
                }}
              >
                <FloMenu
                  closeFloMenu={() => {
                    setfieldSearch(props.fields);
                  }}
                  menu={
                    <>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.59984 17.759C4.59984 16.899 3.68984 15.839 2.88984 14.589C1.98984 13.179 1.98984 10.809 2.88984 9.39898C4.06984 7.54898 5.50984 6.09898 7.11984 5.12898M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998M8.42184 19.53C9.56184 20.01 10.7718 20.27 12.0018 20.27C15.5318 20.27 18.8218 18.19 21.1118 14.59C22.0118 13.18 22.0118 10.81 21.1118 9.39998C20.7818 8.87998 20.4218 8.38998 20.0518 7.92998M14.5318 9.47198L9.47184 14.532C9.1396 14.1997 8.87605 13.8053 8.69624 13.3712C8.51643 12.9371 8.42388 12.4718 8.42388 12.002C8.42388 11.5321 8.51643 11.0669 8.69624 10.6328C8.87605 10.1987 9.1396 9.80422 9.47184 9.47198C9.80409 9.13974 10.1985 8.87619 10.6326 8.69638C11.0667 8.51657 11.532 8.42402 12.0018 8.42402C12.4717 8.42402 12.937 8.51657 13.3711 8.69638C13.8052 8.87619 14.1996 9.13974 14.5318 9.47198V9.47198Z"
                          stroke={
                            peopleSD.hidefields > 0 ? "#FF6FB4" : "#919196"
                          }
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.511 12.7C15.3755 13.3976 15.0347 14.0387 14.5322 14.5412C14.0297 15.0437 13.3886 15.3845 12.691 15.52M9.47 14.531L2 22.001M22.001 2L14.531 9.47"
                          stroke={
                            peopleSD.hidefields > 0 ? "#FF6FB4" : "#919196"
                          }
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span
                        style={{
                          color: peopleSD.hidefields > 0 ? "#FF6FB4" : "",
                        }}
                      >
                        Hide Fields
                      </span>
                    </>
                  }
                >
                  <Box>
                    <div className="searchMenuContainer">
                      <Input2
                        getdata={(e) => searchHidenField(e)}
                        icon={true}
                        label="Search a field"
                      />
                    </div>
                    <div style={{ marginBottom: 60 }}>
                      {fieldSearch.map((data, index) => (
                        <Switch
                          key={index}
                          field={field}
                          data={data}
                          index={index}
                          setfields={(e) => props.setfields(e)}
                          value={data.value}
                          hide={hideAll == 0 ? data.hide : hideAll}
                          label={data.title}
                        />
                      ))}
                    </div>
                    <div className="hideMenuBtnContainer">
                      <div
                        onClick={() => {
                          let temp_fields = field;
                          field.map((data, i) => {
                            let temp = {
                              value: data.value,
                              title: data.title,
                              hide: true,
                            };

                            temp_fields[i] = temp;
                          });
                          sethideAll(true);
                          props.setfields(temp_fields);
                          // console.warn(temp_fields);
                        }}
                        className="btn-block btn_text"
                        style={{ marginTop: 0, height: 40, marginRight: 10 }}
                      >
                        Hide All
                      </div>
                      <div
                        onClick={() => {
                          let temp_fields = field;
                          field.map((data, i) => {
                            let temp = {
                              value: data.value,
                              title: data.title,
                              hide: false,
                            };

                            temp_fields[i] = temp;
                          });
                          props.setfields(temp_fields);
                          sethideAll(false);
                          // console.warn(temp_fields);
                        }}
                        className="btn-block btn_text"
                        style={{ marginTop: 0, height: 40 }}
                      >
                        Show All
                      </div>
                    </div>
                  </Box>
                </FloMenu>
                <div></div>
              </li>
              {/* GROUP FIELDS */}
              <li
                style={{
                  backgroundColor: peopleSD.groupdata > 0 ? "" : "",
                }}
              >
                <FloMenu
                  menu={
                    <>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.9213 5.53982C20.6213 6.28982 20.6213 7.52982 18.9213 8.27982L13.0213 10.8998C12.3513 11.1998 11.2513 11.1998 10.5813 10.8998L4.68125 8.27982C2.98125 7.52982 2.98125 6.28982 4.68125 5.53982L10.5813 2.91982C11.2513 2.61982 12.3513 2.61982 13.0213 2.91982L14.9412 3.76982"
                          stroke={
                            peopleSD.groupdata > 0 ? "#14AE93" : "#919196"
                          }
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3 11C3 11.84 3.63 12.81 4.4 13.15L11.19 16.17C11.71 16.4 12.3 16.4 12.81 16.17L19.6 13.15C20.37 12.81 21 11.84 21 11"
                          stroke={
                            peopleSD.groupdata > 0 ? "#14AE93" : "#919196"
                          }
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3 16C3 16.93 3.55 17.77 4.4 18.15L11.19 21.17C11.71 21.4 12.3 21.4 12.81 21.17L19.6 18.15C20.45 17.77 21 16.93 21 16"
                          stroke={
                            peopleSD.groupdata > 0 ? "#14AE93" : "#919196"
                          }
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span
                        style={{
                          color: peopleSD.groupdata > 0 ? "#14AE93" : "",
                        }}
                      >
                        Group
                      </span>
                    </>
                  }
                >
                  <Box>
                    <div className="searchMenuContainer">
                      <div className="floMenuHeading">
                        <span>Group by</span>
                      </div>
                    </div>
                    {groupsData.map((group, i) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div style={{ width: 180 }}>
                            <Dropdown
                              onChange={(e) => {
                                let data = [...groupsData];
                                data[i].value = e.target.value;
                                console.log("e:", e.target.value);

                                if (e.target.value != "") {
                                  setgroupsData(data);
                                  props.onGroupChange({ e, data });
                                } else {
                                  setgroupsData(data);
                                  props.onGroupChange({ e, data: [] });
                                }
                              }}
                              default={group.value}
                            >
                              <MenuItem value="">
                                <>None</>
                              </MenuItem>
                              {props.fields.map((data, index) => {
                                return (
                                  <MenuItem key={index} value={data.value}>
                                    {data.title}
                                  </MenuItem>
                                );
                              })}
                            </Dropdown>
                          </div>
                          {/* <div>
                            <Dropdown default={group.order}>
                              <MenuItem value={1}>
                                A <img src={Arrow} /> Z
                              </MenuItem>
                              <MenuItem value={0}>
                                Z <img src={Arrow} /> A
                              </MenuItem>
                            </Dropdown>
                          </div> */}
                          {/* {i != 0 ? ( */}
                          {true ? (
                            <div
                              style={{ width: 30, justifyContent: "end" }}
                              className="v-center"
                              onClick={() => {
                                let temp = [...groupsData];
                                temp.splice(i, 1);
                                setgroupsData(temp);
                                console.log("temp", temp);
                                props.onGroupChange({
                                  e: undefined,
                                  data: temp,
                                });

                                if (i == 0) {
                                  setTimeout(() => {
                                    setgroupsData([{ value: "", order: 0 }]);
                                  }, 100);
                                }
                              }}
                            >
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5"
                                  stroke="#ED604B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}

                    <div style={{ marginTop: 8, marginLeft: 9 }}>
                      {groupsData.length <= 1 ? (
                        <a
                          onClick={() => {
                            setgroupsData([
                              ...groupsData,
                              { value: "", order: 1 },
                            ]);
                          }}
                          className="text-p font-14 fontW-600"
                        >
                          <img src={PlusP} /> Add subgroup
                        </a>
                      ) : null}
                    </div>
                  </Box>
                </FloMenu>
              </li>
              {/* SORT FIELDS */}
              <li
                style={{
                  backgroundColor:
                    peopleSD.sortfields[0]?.value != "" ? "" : "",
                }}
              >
                <FloMenu
                  menu={
                    <>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.9213 5.53982C20.6213 6.28982 20.6213 7.52982 18.9213 8.27982L13.0213 10.8998C12.3513 11.1998 11.2513 11.1998 10.5813 10.8998L4.68125 8.27982C2.98125 7.52982 2.98125 6.28982 4.68125 5.53982L10.5813 2.91982C11.2513 2.61982 12.3513 2.61982 13.0213 2.91982L14.9412 3.76982"
                          stroke={
                            peopleSD.sortfields[0]?.value != ""
                              ? "#F37226"
                              : "#919196"
                          }
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3 11C3 11.84 3.63 12.81 4.4 13.15L11.19 16.17C11.71 16.4 12.3 16.4 12.81 16.17L19.6 13.15C20.37 12.81 21 11.84 21 11"
                          stroke={
                            peopleSD.sortfields[0]?.value != ""
                              ? "#F37226"
                              : "#919196"
                          }
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3 16C3 16.93 3.55 17.77 4.4 18.15L11.19 21.17C11.71 21.4 12.3 21.4 12.81 21.17L19.6 18.15C20.45 17.77 21 16.93 21 16"
                          stroke={
                            peopleSD.sortfields[0]?.value != ""
                              ? "#F37226"
                              : "#919196"
                          }
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span
                        style={{
                          color:
                            peopleSD.sortfields[0]?.value != ""
                              ? "#F37226"
                              : "",
                        }}
                      >
                        Sort
                      </span>
                    </>
                  }
                >
                  <Box>
                    <div className="searchMenuContainer">
                      <div className="floMenuHeading">
                        <span>Sort by</span>
                      </div>
                    </div>
                    {sortData.map((group, i) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div style={{ width: 130, marginRight: 10 }}>
                            <Dropdown
                              onChange={(e) => {
                                let data = [...sortData];
                                data[i].value = e.target.value;
                                console.log("e:", e.target.value);

                                if (e.target.value != "") {
                                  setsortData(data);
                                } else {
                                  setsortData(data);
                                }
                              }}
                              default={group.value}
                            >
                              <MenuItem value="">
                                <>None</>
                              </MenuItem>
                              {props.fields.map((data, index) => {
                                return (
                                  <MenuItem key={index} value={data.value}>
                                    {data.title}
                                  </MenuItem>
                                );
                              })}
                            </Dropdown>
                          </div>
                          <div style={{ width: 130 }}>
                            <Dropdown
                              default={group.order}
                              onChange={(e) => {
                                let data = [...sortData];
                                data[i].order = e.target.value;
                                console.log("e:", e.target.value);

                                setsortData(data);
                              }}
                            >
                              <MenuItem value={1}>
                                A <img src={Arrow} /> Z
                              </MenuItem>
                              <MenuItem value={0}>
                                Z <img src={Arrow} /> A
                              </MenuItem>
                            </Dropdown>
                          </div>
                          {/* {i != 0 ? ( */}
                          {true ? (
                            <div
                              style={{ width: 30, justifyContent: "end" }}
                              className="v-center"
                              onClick={() => {
                                let temp = [...sortData];
                                temp.splice(i, 1);
                                setsortData(temp);
                                console.log("temp", temp);
                                
                                if (i == 0) {
                                  setTimeout(() => {
                                    setsortData([{ value: "", order: 0 }]);
                                  }, 100);
                                }
                              }}
                            >
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5"
                                  stroke="#ED604B"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}

                    {/* <div style={{ marginTop: 8, marginLeft: 9 }}>
                      {sortData.length <= 1 ? (
                        <a
                          onClick={() => {
                            setsortData([
                              ...sortData,
                              { value: "", order: 1 },
                            ]);
                          }}
                          className="text-p font-14 fontW-600"
                        >
                          <img src={PlusP} /> Add another sort
                        </a>
                      ) : null}
                    </div> */}
                  </Box>
                </FloMenu>
              </li>
              {/* ACTION BUTTON */}
              <li>
                <FloMenu
                  menu={
                    <>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 6.25V8.25M10 6.25V8.25M2 9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22H9C4 22 2 20 2 15V12H18M6 16V18M10 16V18M14 7.25H18M14 17H18"
                          stroke="#919196"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span>Action</span>
                    </>
                  }
                >
                  <MenuItem onClick={handleClose}>
                    <img className="manageMenuImg" src={assignbase} />
                    <span className="manageMenuLabel">Assign to base</span>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <img className="manageMenuImg" src={edit} />
                    <span className="manageMenuLabel">Edit</span>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <img className="manageMenuImg" src={archive} />
                    <span className="manageMenuLabel">Archive</span>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <img className="manageMenuImg" src={importIcon} />
                    <span className="manageMenuLabel">Import</span>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <img className="manageMenuImg" src={importIcon} />
                    <span className="manageMenuLabel">
                      Export (.CSV for Excel)
                    </span>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <img className="manageMenuImg" src={print} />
                    <span className="manageMenuLabel">Print</span>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <img className="manageMenuImg" src={deleteIcon} />
                    <span className="manageMenuLabel">Delete</span>
                  </MenuItem>
                </FloMenu>
              </li>
            </ul>

            {/* MENU BUTTON */}
            {/* <Box sx={{ justifyContent: "flex-end", display: "flex" }}> */}
              {/* LIST VIEW */}
              {/* <div className="v-center hover">
                <img
                  className="iconSize"
                  style={{ paddingRight: 1 }}
                  src={listView}
                />{" "}
              </div>
              <div className="v-center hover" style={{ padding: "0px 15px" }}>
                <img
                  className="iconSize"
                  style={{ paddingRight: 1 }}
                  src={gridView}
                />{" "}
              </div>
              <FloMenu
                menu={
                  <div className="v-center hover">
                    <img
                      className="iconSize"
                      style={{ paddingRight: 1 }}
                      src={menuIcon}
                    />{" "}
                  </div>
                }
              >
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={assignbase} />
                  <span className="manageMenuLabel">Assign to base</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={edit} />
                  <span className="manageMenuLabel">Edit</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={archive} />
                  <span className="manageMenuLabel">Archive</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={importIcon} />
                  <span className="manageMenuLabel">Import</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={importIcon} />
                  <span className="manageMenuLabel">
                    Export (.CSV for Excel)
                  </span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={print} />
                  <span className="manageMenuLabel">Print</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={deleteIcon} />
                  <span className="manageMenuLabel">Delete</span>
                </MenuItem>
              </FloMenu>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Toolbar;
