import { render } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import Input from "../../Components/input";
import FloCheckbox from "../../Components/checkbox";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import checkboxIcon from "../../assets/img/icon/checkbox.svg";
import checkedCheckbox from "../../assets/img/icon/checkedCheckbox.svg";
import RolePermissionsHelper from "../../helper/RolePermission";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

const AllRoles = () => {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 DEFINED STATUES                                ||
  // ! ||--------------------------------------------------------------------------------||
  const navigate = useNavigate();
  const { GetAllRoles } = RolePermissionsHelper();
  const [rows, setRows] = useState([]);
  const [columns, setcolumns] = useState([
    { field: "role_name", headerName: "Role Name", width: 150 },
    { field: "permissions", headerName: "Permissions", width: 150 },
    { field: "created_at", headerName: "Created At", width: 200 },
    { field: "updated_at", headerName: "Updated At", width: 200 },
    {
      field: "activity",
      headerName: "Activity",
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          className="btn"
          style={{ height: 40 }}
          onClick={() => navigate(`/role-permissions/${params.id}`, { state: "" })}
        >
          View
        </Button>
      ),
    },
  ]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    GetAllRoles().then((e) => {
      if (e.status == "success") {
        let row = [];
        e.data.map((obj, i) => {
          let data = {
            id: obj._id,
            role_name: obj.role_name,
            permissions: obj.permissions.length,
            created_at: moment(obj.createdAt).format("MMMM Do YYYY"),
            updated_at: moment(obj.updatedAt).format("MMMM Do YYYY"),
            activity: `<button onclick='function(){alert(1)}' class="btn" style="color:white; border:none; min-width:80px;height:40px; margin-top:0px">View</button>`,
          };
          row.push(data);
        });
        setRows(row);
      }
    });
  }, []);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <section>
      <Box
        className="sqr-profile-name v-center"
        style={{ paddingLeft: 0, marginBottom: 25 }}
      >
        All Roles
      </Box>
      <Box style={{ marginTop: 25 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          checkboxSelection
        />
      </Box>
    </section>
  );
};

export default AllRoles;
