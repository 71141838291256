import * as React from "react";
import Box from "@mui/material/Box";
import employeeImg from "../assets/img/img/employee.svg";
import collaboratorImg from "../assets/img/img/collaborator.svg";
import plusIcon from "../assets/img/icon/plusIcon.png";
import Input from "../Components/input";
import AppendPeopleinvite from "../Screens/AppendPeopleinvite";
import { InvitePeople } from "../helper/index";
import { useSelector, useDispatch } from "react-redux";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import Grid from "@mui/material/Grid";
import ManagePeople from "../helper/ManagePeople";
import LoaderComp from "../Components/Loader";
import ReloadReduxHook from "../utilities/ReloadReduxHook";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 3,
  borderRadius: 2,
  outline: "unset",
  maxHeight: 560,
  overflow: "scroll",
};

export default function AddPeopleModelInvite(props) {
  const { getPeople } = ManagePeople();
  const { PeopleReload } = ReloadReduxHook();
  const OnboardingReducer = useSelector((state) => state.onboardingReducer);
  const userReducer = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loader, setloader] = React.useState(false);
  const [selectedVal, setSelectedVal] = React.useState();
  const [data, setdata] = React.useState([
    {
      name: "",
      email: "",
    },
  ]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getPeoples = () => {
    getPeople({
      groupdata: [],
      company_id: userReducer.userDetails.company_id,
    }).then((res) => {
      if (res.data.status == "error") {
        toastError(res.data.data.message);
      } else if ((res.data.status = "success")) {
        if (res.data.data.length > 0) {
          let Data = res.data.data;
          console.log("Get People", Data);
          dispatch({
            type: "UPDATEPEOPLE",
            payload: Data,
          });
        }
      }
    });
  };

  const InvitePeopleFun = () => {
    const validData = data.filter(
      (person) => person.name.trim() !== "" && person.email.trim() !== ""
    );

    if (validData.length !== data.length) {
      toastWarning("Please fill in all fields before inviting.");
      return;
    }

    setloader(true);
    console.log("Data:", data);
    InvitePeople(data).then((e) => {
      setloader(false);
      console.log("Response:", e);
      if (e.data.status == "success") {
        toastSuccess("Invited successfully");
      } else if (e.data.status == "warning") {
        e.data.data.map((e) => {
          toastWarning(e.msg);
        });
      } else if (e.data.status == "error") {
        console.log("Error:", e.data);
        // e.data.data.map((e) => {
        //   toastError(e.msg);
        // });
      }
      props.handleClose();
      getPeoples();
      PeopleReload();
    });
  };

  return (
    <Box sx={style}>
      <h1 className="fh1 text-center">Set up your team’s account</h1>

      <Box sx={{ flexGrow: 1 }}>
        {data.map((d, i) => {
          return (
            <AppendPeopleinvite
              type={props.selectedVal}
              key={i}
              getData={(e) => {
                console.log(e);
                let tempdata = [...data];
                tempdata[i] = e;
                console.log("tempdata:", tempdata);
                setdata(tempdata);
              }}
            />
          );
        })}

        <Box
          className="h-center hover"
          sx={{ marginTop: 3 }}
          onClick={() => {
            let tempdata = [...data];
            tempdata.push({ name: "", email: "" });
            console.log("tempdata:", tempdata);
            setdata(tempdata);
          }}
        >
          <img src={plusIcon} width="46" />
        </Box>

        <Box className="f-end" style={{ marginTop: 30 }}>
          <div
            onClick={() => props.nextStage(1)}
            className="btn-block btn-mini-inv mt-0"
            style={{ height: 38, maxWidth: 100 }}
          >
            Back
          </div>
          <div
            onClick={() => !loader && InvitePeopleFun()}
            className="btn-block btn_text mt-0"
            style={{ height: 38, maxWidth: 100 }}
          >
            <LoaderComp loader={loader}>Done </LoaderComp>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
