import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ActiveMenu from "../Components/activeMenu";
import _ from "lodash";
import { AlphaColor } from "../utilities/General";
import { department } from "../Components/attendencereportdata/AttendenceReportData";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InGreen from "../assets/img/icon/in_green.svg";
import InGray from "../assets/img/icon/in_gray.png";
import OutRed from "../assets/img/icon/out_red.svg";
import { getTimeTracking } from "../helper/index";
import useTokkenHook from "../utilities/TokkenHook";
import moment from "moment";
import GeneralHook from "../utilities/GeneralHook";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dots from "../assets/img/icon/dots.png";
import menu from "../assets/img/icon/menu.png";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import FloMenu from "../Components/floMenu";
import MenuItem from "@mui/material/MenuItem";
import assignbase from "../assets/img/icon/assignbase.svg";
import edit from "../assets/img/icon/edit.svg";
import archive from "../assets/img/icon/archive.svg";
import importIcon from "../assets/img/icon/Import.svg";
import deleteIcon from "../assets/img/icon/Delete.svg";
import print from "../assets/img/icon/print.svg";
import NewInputSearch from "../Components/attendencereportdata/NewInputSearch";
import FloSelect from "../Components/FloSelect";
import DropdownModel from "../Components/attendencereportdata/DropdownModel";
const statusBadge = (s) => {
  const status = {
    Logged: "approved_badge",
    Approved: "approved_badge",
    Pending: "pending_badge",
    Tracking: "tracking_badge",
    Holiday: "grayr_badge",
    Conflict: "pending_badge",
  };
  return status[s];
};
const astatusBadge = (s) => {
  const status = {
    On_Time: "green_badge",
    Holiday: "gray_badge",
    Late: "yellow_badge",
    Early: "blue_badge",
    Absent: "red_badge",
    WFH: "orange_badge",
    Leave: "purple_badge",
  };
  return status[s];
};
const data = [
  {
    tittle: "120",
    Description: "Total logged Hours",
  },
  {
    tittle: "10",
    Description: "Total Leaves",
  },
  {
    tittle: "04",
    Description: "Absents",
  },
  {
    tittle: "4400",
    Description: "Salary deduction",
  },
];
const AttendenceReport = () => {
  const [rows, setrows] = useState([]);
  const { tokken, setTokken } = useTokkenHook();
  const { getGeneral, setGeneral } = GeneralHook();
  const trackingStage = getGeneral("attendenceReducer", "trackingStage");
  const selectedPeopleId = getGeneral("changeNumber", "fieldsBlockVariable");
  const attendenceReducer = useSelector((state) => state.attendenceReducer);
  const [fromDate, setFromDate] = useState(moment().subtract(1, "months"));
  const [toDate, setToDate] = useState(moment());
  const [anchorEl, setAnchorEl] = React.useState(null);
  useEffect(() => {
    init();
  }, [trackingStage, selectedPeopleId, attendenceReducer.reload]);
  const init = () => {
    const company_id = getGeneral("userReducer", "userDetails").company_id;
    const user_id = getGeneral("changeNumber", "selectedPeopleId");

    getTimeTracking({ user_id, company_id }, tokken).then((res) => {
      console.log("Response:", res.data);
      if (res.data.status == "success") {
        setrows(res.data.data);
      }
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [collabs, setcollabs] = useState([
    { name: "ali", id: "0", color: "#FFDDE1" },
    { name: "ali", id: "0", color: "#FFEEBF" },
    { name: "ali", id: "0", color: "#D2E1F0" },
    { name: "ali", id: "0", color: "#D9D6FA" },
  ]);

  return (
    <>
      <section className="PeopelDetaisComp">
        {/* departments section  */}
        <div className="PeopelDetaisLeft">
          <ActiveMenu
            setChecked={(e) => {
              console.log(e.target.checked);
            }}
          />
          {department.map((item, index) => (
            <div
              className="titleBorder-department"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
              key={index}
            >
              <span
                className="profileimg"
                style={{
                  backgroundColor: AlphaColor(item.departmentTitle[0]),
                }}
              >
                {item.departmentTitle ? item.departmentTitle[0] : null}
              </span>
              <div className="department-titles">{item.departmentTitle}</div>
            </div>
          ))}
        </div>

        {/* body container employee lists  */}
        <div className="PeopelDetaisBody" ref={componentRef}>
          <div
            className="jsb v-center"
            style={{ padding: 25, paddingBottom: 0 }}
          >
            <div className="d-flex">
              <div className="sqr-profile-container"></div>
              <div className="sqr-profile-name v-center">Design Department</div>
            </div>
            {/* <div className="d-flex p-relative">
              <div className="floCollabDivider"></div>
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "30px",
              color: "#ED604B",
            }}
          >
            <LocalPrintshopIcon
              onClick={handlePrint}
              style={{ cursor: "pointer" }}
            />
          </div>
          {/* {department employe listing} */}
          <div
            style={{ padding: "0px 18px", borderBottom: "1px #E5E8E8 solid" }}
          >
            <div className="toolbar-attendenceReport">
              <div className="innerContainer-toolbar">
                <div className="calenderinner-con">
                  <div>
                    <CalendarMonthIcon className="calender-icon" />
                  </div>
                  <div className="date-content-con">
                    {/* <div>
                  <h1 className="attendenceDate-p">From date</h1>
                </div> */}
                    <div>
                      {/* <p className="attendenceDate-h1">01 Apr 2024</p> */}
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        className="new-pp"
                      >
                        <MobileDatePicker
                          className="mobile-date-piker"
                          label="From Date"
                          value={fromDate}
                          onChange={(newValue) => setFromDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="line-attendence"></div>
                <div className="calenderinner-con">
                  <div>
                    <CalendarMonthIcon className="calender-icon" />
                  </div>
                  <div className="date-content-con">
                    <div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                          className="mobile-date-piker"
                          label="To Date"
                          value={toDate}
                          onChange={(newValue) => setToDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                {/* new checking purpose */}
                {/* Select menu peoples filter start */}
                {/* <div className="attendence-people-filter">
                  <div className="">
                    <TuneRoundedIcon className="calender-icon" />
                  </div>
                  <div style={{ marginLeft: "13px" }}>
                    <div className="attendenceReport-Select">Select</div>
                    <div className="attendenceReport-People">Peoples</div>
                  </div>
                </div> */}
              </div>
              {/* margin */}
              <div
                style={{
                  width: "100%",
                  marginRight: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="flo-input">
                  {/* <NewInputSearch /> */}
                  <DropdownModel label={"Software Department"} />
                </div>
              </div>
              {/*ending point*/}
              <div>
                <div className="dropdownAttendence-icon">
                  {/* {First menu icon} */}
                  <img src={menu} style={{ margin: "20px" }} />
                  {/* second menu icon start*/}
                  <FloMenu
                    menu={
                      <>
                        <img src={dots} />
                      </>
                    }
                  >
                    <MenuItem onClick={handleClose}>
                      <img className="manageMenuImg" src={assignbase} />
                      <span className="manageMenuLabel">Assign to base</span>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img className="manageMenuImg" src={edit} />
                      <span className="manageMenuLabel">Edit</span>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img className="manageMenuImg" src={archive} />
                      <span className="manageMenuLabel">Archive</span>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img className="manageMenuImg" src={importIcon} />
                      <span className="manageMenuLabel">Import</span>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img className="manageMenuImg" src={importIcon} />
                      <span className="manageMenuLabel">
                        Export (.CSV for Excel)
                      </span>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img className="manageMenuImg" src={print} />
                      <span className="manageMenuLabel">Print</span>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <img className="manageMenuImg" src={deleteIcon} />
                      <span className="manageMenuLabel">Delete</span>
                    </MenuItem>
                  </FloMenu>
                  {/* second menu icon end*/}
                </div>
              </div>
            </div>
          </div>

          <div style={{ background: "#FBFAFC", borderRadius: "4px" }}>
            <Grid container sx={{ paddingX: 1 }}>
              {data.map((item) => (
                <>
                  <Grid item xs={3} style={{ marginTop: "10px" }}>
                    <Box
                      sx={{ margin: 1 }}
                      className="leaveSummeryContainer"
                      style={{ margin: "5px" }}
                    >
                      <div className="fh3">{item.tittle}</div>
                      <div className="fp">{item.Description}</div>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
            <TableContainer component={Paper} style={{ background: "none" }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="CentraNo2-m">Name</TableCell>
                    <TableCell className="CentraNo2-m">Date</TableCell>
                    <TableCell className="CentraNo2-m">Dept</TableCell>
                    <TableCell className="CentraNo2-m">In</TableCell>
                    <TableCell className="CentraNo2-m">Out</TableCell>
                    <TableCell className="CentraNo2-m">Hours</TableCell>
                    <TableCell className="CentraNo2-m">Status</TableCell>
                    <TableCell className="CentraNo2-m">Manager</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((attendence, i) => {
                    console.log(attendence.trackingData);
                    let trackingStatus =
                      attendence?.trackingData[0]?.leaveStatus &&
                      attendence?.trackingData[0]?.checkin
                        ? "Conflict"
                        : attendence?.trackingData[0]?.trackingStatus;
                    return (
                      <TableRow
                        key={1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell className="f-12 CentraNo2-m">
                          Ali Rizvi
                        </TableCell>
                        <TableCell className="f-12 CentraNo2-m">
                          3 june 2024
                        </TableCell>
                        {/* ATTENDENCE DATE */}
                        <TableCell
                          className="f-12 CentraNo2-m"
                          component="th"
                          scope="row"
                        >
                          {moment(attendence.checkinDate)
                            .subtract(5, "hours")
                            .format("dddd Do MMM")}
                        </TableCell>
                        {/* CHECK IN */}
                        <TableCell className="f-12 CentraNo2">
                          <img
                            className="inoutImg"
                            src={
                              attendence?.trackingData[0]?.checkin
                                ? InGreen
                                : InGray
                            }
                          />{" "}
                          {attendence?.trackingData[0]?.checkin
                            ? moment(attendence?.trackingData[0]?.checkin)
                                .subtract(5, "hours")
                                .format("h:mm a")
                            : "00:00 am"}
                        </TableCell>
                        {/* CHECK OUT */}
                        <TableCell className="f-12 CentraNo2">
                          <img
                            className="inoutImg"
                            src={
                              attendence?.trackingData[0]?.checkout
                                ? OutRed
                                : InGray
                            }
                          />{" "}
                          {attendence?.trackingData[0]?.checkout
                            ? moment(attendence?.lastCheckout)
                                .subtract(5, "hours")
                                .format("h:mm a")
                            : "00:00 pm"}
                        </TableCell>
                        {/* LOGED HOURS */}
                        <TableCell className="f-12 CentraNo2">
                          {attendence?.trackingData[0]?.totalHours
                            ? attendence?.trackingData[0]?.totalHours.toFixed(1)
                            : 0}{" "}
                          hrs
                        </TableCell>
                        {/* STATUS BADGE */}
                        <TableCell>
                          <span
                            className={`${statusBadge(trackingStatus)} ${
                              attendence.STATUS == "Holiday" ? "d-none" : null
                            }`}
                          >
                            {trackingStatus}
                          </span>
                        </TableCell>
                        {/* MANAGER NAME */}
                        <TableCell className="f-12 CentraNo2">
                          {" "}
                          <span className={`grayr_badge`}>
                            {
                              attendence?.user?.details[1]?.block[0]?.inputs[6]
                                .value
                            }
                          </span>
                        </TableCell>
                        {/* TIMETRACKING TAG */}
                        {/* <TableCell>
                    <span
                      className={`green_badge capsul_badge ${astatusBadge(
                        attendence?.trackingData[0]?.leaveStatus
                          ? attendence?.trackingData[0]?.leaveStatus
                          : attendence?.trackingData[0]?.checkin
                          ? "On_Time"
                          : "Absent"
                      )}`}
                    >
                      {attendence?.trackingData[0]?.leaveStatus
                        ? attendence?.trackingData[0]?.leaveStatus
                        : attendence?.trackingData[0]?.checkin
                        ? "On Time"
                        : "Absent"}
                    </span>
                  </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </section>
    </>
  );
};

export default AttendenceReport;
