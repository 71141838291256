import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import checkboxIcon from "../assets/img/icon/checkbox.svg";
import checkedCheckbox from "../assets/img/icon/checkedCheckbox.svg";

const FloCheckbox = (props) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <div className="checkboxProfileTd">
      <Checkbox
        value={props?.value}
        className={`${props.className} ${checked ? "d-block" : "d-none"}`}
        onChange={(e) => {
          setChecked(!checked);
          props?.onChange({
            id: e.target.value,
            checked: !checked,
          });
        }}
        checked={checked}
        inputProps={{ "aria-label": "FloCheckbox" }}
        icon={<img className="checkboxIcon" src={checkboxIcon} />}
        checkedIcon={<img className="checkboxIcon" src={checkedCheckbox} />}
      />
      <div
        style={{ ...props.style }}
        className={`tableRecords ${checked ? "d-none" : "d-block"}`}
      >
        {props.index + 1}
      </div>
    </div>
  );
};

export default FloCheckbox;
