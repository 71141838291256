import { API_URL } from "../config";
import axios from "axios";

// ! ||--------------------------------------------------------------------------------||
// ! ||                                     SIGN UP                                    ||
// ! ||--------------------------------------------------------------------------------||
export const Signup = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/users/signup", data)
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                     SIGN IN                                    ||
// ! ||--------------------------------------------------------------------------------||
export const SignIn = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/users/login", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                 FORGOT PASSWORD                                ||
// ! ||--------------------------------------------------------------------------------||
export const ForgotPass = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/users/forget-password", data)
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                 RESET PASSWORD                                 ||
// ! ||--------------------------------------------------------------------------------||
export const ResetPass = (data) => {
  return new Promise((resolve, reject) => {
    console.warn("request:", data);
    axios({
      method: "post",
      url: API_URL + "api/users/reset-password",
      data: data.data,
      headers: {
        Authorization: data.header,
      },
    })
      .then(function (response) {
        console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                 IS EMAIL EXIST                                 ||
// ! ||--------------------------------------------------------------------------------||
export const isEmailExistCall = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/users/is-email-exist", data)
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                  UPDATE PEOPLE                                 ||
// ! ||--------------------------------------------------------------------------------||

export const UpdatePeople = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/users/update-people", data)
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                  INVITE PEOPLE                                 ||
// ! ||--------------------------------------------------------------------------------||
export const InvitePeople = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/users/invite-people", data)
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};

// ! ||--------------------------------------------------------------------------------||
// ! ||                                  LEAVE REQUEST                                 ||
// ! ||--------------------------------------------------------------------------------||
export const leaveRequest = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/attendence/leave-request", data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                               GET LEAVE REQUESTS                               ||
// ! ||--------------------------------------------------------------------------------||
export const getLeaveRequest = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    console.log("tokken:", tokken);
    axios
      .post(API_URL + "api/attendence/get-leave-request", data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                             GET LEAVE REQUEST BY ID                            ||
// ! ||--------------------------------------------------------------------------------||
export const getLeaveRequestBYID = (data, tokken = "") => {
  console.log("data", data);
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/attendence/get-leave-request-byid", data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                              UPDATE LEAVE REQUEST                              ||
// ! ||--------------------------------------------------------------------------------||
export const updateLeaveRequest = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/attendence/update-leave-request", data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                              UPDATE LEAVE REQUEST                              ||
// ! ||--------------------------------------------------------------------------------||
export const updateLeaveRequestStatus = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "api/attendence/update-leave-request-by-id", data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                              DELETE LEAVE REQUEST                              ||
// ! ||--------------------------------------------------------------------------------||
export const delLeaveRequest = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        API_URL +
          `api/attendence/del-leave-request/${data.company_id}/${data.user_id}`,
        {
          headers: {
            Authorization: tokken,
          },
        }
      )
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                              GET FEATURED TIMEOFF                              ||
// ! ||--------------------------------------------------------------------------------||
export const getFeaturedTimeoff = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `api/attendence/featured-timeoff`, data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                   ALL TIMEOFF                                  ||
// ! ||--------------------------------------------------------------------------------||
export const getAllTimeoff = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `api/attendence/all-timeoff`, data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                SET TIME TRACKING                               ||
// ! ||--------------------------------------------------------------------------------||
export const setTimeTracking = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `api/attendence/set-tracking`, data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                GET TIME TRACKING                               ||
// ! ||--------------------------------------------------------------------------------||
export const getTimeTracking = (data, tokken = "") => {
  console.log(data);
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `api/attendence/get-tracking`, data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                CHECKIN TRACKING                                ||
// ! ||--------------------------------------------------------------------------------||
// export const checkInOutAttendence = (data, tokken = "") => {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(API_URL + `api/attendence/set-tracking`, data, {
//         headers: {
//           Authorization: tokken,
//         },
//       })
//       .then(function (response) {
//         // console.log(response);
//         resolve(response);
//       })
//       .catch(function (error) {
//         reject(error);
//         console.log(error);
//       });
//   });
// };
export const checkInOutAttendence = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `api/attendence/set-tracking`, data, {
        headers: {
          // Authorization: `Bearer ${tokken}`,
          Authorization: `Bearer ${tokken}`,
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                SEND OTP                                        ||
// ! ||--------------------------------------------------------------------------------||
export const sendOTP = (email, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `api/users/send-otp/${email}`, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                Verify OTP                                      ||
// ! ||--------------------------------------------------------------------------------||
export const verifyOTP = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `api/users/verify-otp`, data, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                         Get Line Managers                                      ||
// ! ||--------------------------------------------------------------------------------||
export const getLineManagers = (tokken = "") => {
  console.log("tokken___", tokken);
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `api/users/get-managers`, {
        headers: {
          Authorization: tokken,
        },
      })
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
// ! ||--------------------------------------------------------------------------------||
// ! ||                                Verify OTP                                      ||
// ! ||--------------------------------------------------------------------------------||
export const createRole = (data, tokken = "") => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + `api/role-permission/create-role`, data, {})
      .then(function (response) {
        // console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};
