import { API_URL } from "../config";
import axios from "axios";
import useTokkenHook from "../utilities/TokkenHook";

// ! ||--------------------------------------------------------------------------------||
// ! ||                                   Get All Roles                                ||
// ! ||--------------------------------------------------------------------------------||
const ManagePeopleHelper = () => {
  const { tokken, setTokken } = useTokkenHook();

  const getPeople = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "api/users/user-bygroup", data, {
          headers: {
            Authorization: tokken,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
          console.log(error);
        });
    });
  };
  return {
    getPeople,
  };
};
export default ManagePeopleHelper;
