import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
const ApproveTimeModal = ({ show, onClose,name,handleLeaveReqStatus }) => {
  if (!show) {
    return null;
  }
  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content">
          <div style={{ display: "flex", justifyContent: "center", }} className="container-Approvetimemodal">
            <Alert
              severity="error"
              className="error-icon-approvetimemodal"
            ></Alert>
          </div>
          <div style={{ textAlign: "center" }}>
            <h4 className="heading-confirmmember">Confirm Leave Approval</h4>
            <p className="para-confirmmember">
              Are you sure you wish to approve Leaves of {name} ?
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div className="btn-approvetimemodal" onClick={()=>{handleLeaveReqStatus("Approved")}}>
                 Approve
              </div>
              <div className="btn-approvetimemodal-cancel" onClick={onClose}>
                <a href="#" /> Disapprove
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveTimeModal;
