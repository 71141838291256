import React from "react";
import "../Screens/css/NotFound.css";
import floLogo from "./image/floLogo.png";
import Frame from "./image/Frame.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="notFound-container">
        <div className="notfound-logo-con">
          <img src={floLogo} className="notFound-logo" />
        </div>
        <div className="notFound-hero">
          <div className="hero-first">
            <div className="notFound-tittle">404</div>
            <div className="notFound-subtittle">Ooops! Page not Found</div>
            <div className="notFound-description">
              This page doesn't exist or was removed!
              <br /> We suggest you going back home
            </div>
            <div>
              <Link to="/" className="btn-container-notfound">
                <div className="notfound-btn">Back To Home</div>
              </Link>
            </div>
          </div>
          {/* second section */}
          <div className="hero-sec">
            <img src={Frame} className="notFound-heroimg"></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
