import { API_URL } from "../config";
import axios from "axios";
import useTokkenHook from "../utilities/TokkenHook";

// ! ||--------------------------------------------------------------------------------||
// ! ||                                   Get All Roles                                ||
// ! ||--------------------------------------------------------------------------------||
const RolePermissionsHelper = () => {
  const { tokken, setTokken } = useTokkenHook();

  const GetAllRoles = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + "api/role-permission/get-all-roles", {
          headers: {
            Authorization: tokken,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error.response?.data?.data);
        });
    });
  };
  const UpdateRolePermissions = (role_id, body) => {
    let url = `api/role-permission/update-role/${role_id}`;
    if (!role_id) {
      url = `api/role-permission/create-role`;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + url, body, {
          headers: {
            Authorization: tokken,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error.response?.data?.data);
        });
    });
  };
  const GetAllPermissions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + "api/role-permission/all-permissions", {
          headers: {
            Authorization: tokken,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error.response?.data?.data);
        });
    });
  };
  const GetRoleDetails = (role_id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + `api/role-permission/get-role-details/${role_id}`, {
          headers: {
            Authorization: tokken,
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error.response?.data?.data);
        });
    });
  };
  return {
    GetAllRoles,
    GetAllPermissions,
    GetRoleDetails,
    UpdateRolePermissions,
  };
};
export default RolePermissionsHelper;
