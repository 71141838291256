import React from "react";
import { useSelector, useDispatch } from "react-redux";

function ReloadReduxHook() {
  const onboardingReducer = useSelector((state) => state.userReducer);
  const peopleReducer = useSelector((state) => state.changeNumber);
  const userReducer = useSelector((state) => state.userReducer);
  const attendanceReducer = useSelector((state) => state.attendenceReducer);

  const dispatch = useDispatch();
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     METHODS                                    ||
  // ! ||--------------------------------------------------------------------------------||
  const PeopleReload = () => {
    dispatch({
      type: "RELOAD_PEOPLE_REDUCER",
      reload: !peopleReducer.reload,
    });
  };
  const AttendanceReload = () => {
    dispatch({
      type: "RELOAD_ATTENDANCE_REDUCER",
      reload: !attendanceReducer.reload,
    });
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     RETURN                                     ||
  // ! ||--------------------------------------------------------------------------------||
  return {
    AttendanceReload: () => AttendanceReload(),
    PeopleReload: () => PeopleReload(),
  };
}

export default ReloadReduxHook;
