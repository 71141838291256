import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularIndeterminate({ children, loader }) {
  return (
    <Box sx={{ display: "flex" }}>
      {loader ? <CircularProgress size={20} color="inherit" /> : children}
    </Box>
  );
}
