const initialState = {
  count: 0,
  groupdata: 0,
  hidefields: 0,
  sortfields: [{ value: "", order: 1 }],
  fieldsBlockVariable: [
    {
      customField: true,
      layout: "full",
      tab_name: "Personal",
      block: [
        {
          blockName: "General Info",
          blockType: "simpleBlock",
          inputs: [
            {
              label: "First Namee",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "Last Name",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "Birthday",
              placeholder: "February 1st, 1999",
              type: "datepicker",
              value: "",
              data: [{ birthday: "1" }],
              length: 6,
            },
            {
              label: "Company Name",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "Nationality",
              placeholder: "",
              type: "select",
              value: "",
              data: [
                { nationality: "Pakistani" },
                { nationality: "Indian" },
                { nationality: "American" },
              ],
              length: 6,
            },
            {
              label: "Gender",
              placeholder: "",
              type: "select",
              value: "",
              data: [{ gender: "Male" }, { gender: "Female" }],
              length: 6,
            },
            {
              label: "Country of Employment",
              placeholder: "",
              type: "select",
              value: "",
              data: [
                { country_of_employment: "Pakistan" },
                { country_of_employment: "India" },
                { country_of_employment: "America" },
              ],
              length: 6,
            },
            {
              label: "Region of Employment",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
          ],
        },
        {
          blockName: "Tags",
          blockType: "simpleBlock",
          inputs: [
            {
              label: "Tags",
              type: "tags",
              value: "",
              data: [],
              length: 12,
            },
          ],
        },
        {
          blockName: "Contact Info",
          blockType: "collapseBlock",
          inputs: [
            {
              label: "Nationality",
              placeholder: "",
              type: "select",
              value: "",
              data: [
                { nationality: "+92" },
                { nationality: "+91" },
                { nationality: "+44" },
              ],
              length: 3,
            },

            {
              label: "Phone Number",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 4,
            },
            {
              label: "Work Type",
              placeholder: "",
              type: "radio",
              value: "",
              data: [{ work_type: "Personal" }, { work_type: "Work" }],
              length: 5,
            },
          ],
        },
        {
          blockName: "Email",
          blockType: "collapseBlock",
          inputs: [
            {
              label: "Email",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 7,
            },
            {
              label: "Work Type",
              placeholder: "",
              type: "radio",
              value: "",
              data: [{ work_type: "Personal" }, { work_type: "Work" }],
              length: 5,
            },
          ],
        },
        {
          blockName: "Address",
          blockType: "collapseBlock",
          inputs: [
            {
              label: "Address",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "City",
              placeholder: "",
              type: "select",
              value: "",
              data: [{ city: "London" }],
              length: 6,
            },
            {
              label: "Country",
              placeholder: "",
              type: "select",
              value: "",
              data: [
                { country: "Pakistan" },
                { country: "India" },
                { country: "United Kingdom" },
              ],
              length: 6,
            },
            {
              label: "Zip Code",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
          ],
        },
      ],
    },
    {
      customField: true,
      layout: "full",
      tab_name: "Work",
      block: [
        {
          blockName: "General Info",
          blockType: "simpleBlock",
          inputs: [
            {
              label: "Start End Date",
              placeholder: "",
              type: "startenddate",
              value: "",
              data: [{ start_end_date: "01/02/2022" }, { start_end_date: "" }],
              length: 12,
            },
            {
              label: "Position/Title",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "Team",
              placeholder: "",
              type: "select",
              value: "",
              data: [
                { team: "Dev" },
                { team: "Design" },
                { team: "HR" },
                { team: "Managers" },
              ],
              length: 6,
            },
            {
              label: "Work Type",
              placeholder: "",
              type: "radio",
              value: "",
              data: [{ work_type: "Full Time" }, { work_type: "Part Time" }],
              length: 6,
            },
            {
              label: "Employee Code",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 3,
            },
            {
              label: "Status",
              placeholder: "",
              type: "select",
              value: "",
              data: [{ status: "Active" }, { status: "Deactive" }],
              length: 3,
            },
            {
              label: "Manager",
              placeholder: "",
              type: "select",
              value: "",
              data: [
                { manager: "Irsa Ch" },
                { manager: "Momina" },
                { manager: "Rimsha" },
              ],
              length: 6,
            },
            {
              label: "Type",
              placeholder: "",
              type: "select",
              value: "",
              data: [
                { type: "Employee" },
                { type: "Client" },
                { type: "Collaborator" },
              ],
              length: 6,
            },
          ],
        },
        {
          blockName: "Salary Info",
          blockType: "simpleBlock",
          inputs: [
            {
              label: "Salary Duration",
              placeholder: "",
              type: "radio",
              value: "",
              data: [
                { salary_duration: "Monthly" },
                { salary_duration: "Weekly" },
                { salary_duration: "Daily" },
                { salary_duration: "Hourly" },
              ],
              length: 6,
            },
            {
              label: "Salary",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
          ],
        },
        {
          blockName: "Location",
          blockType: "simpleBlock",
          inputs: [
            {
              label: "Branch",
              placeholder: "",
              type: "select",
              value: "",
              data: [
                { branch: "Buzz Headquarters, Lahore, Pakistan." },
                { branch: "Buzz Interactive, Malaysia" },
              ],
              length: 6,
            },
            {
              label: "Work Nature",
              placeholder: "",
              type: "radio",
              value: "",
              data: [
                { work_nature: "Remote" },
                { work_nature: "Hybrid" },
                { work_nature: "On Site" },
              ],
              length: 6,
            },
          ],
        },
        {
          blockName: "Bank Info",
          blockType: "collapseBlock",
          inputs: [
            {
              label: "Bank Name",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "Title",
              placeholder: "Account Holder Name",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "Account Number",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "IBAN / SWIFT / BIC",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
          ],
        },
        {
          blockName: "Identity Info",
          blockType: "collapseBlock",
          inputs: [
            {
              label: "ID Number",
              placeholder: "xxxxx-xxxxxxx-x",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
            {
              label: "Expiry",
              placeholder: "",
              type: "datepicker",
              value: "",
              data: [{ expiry: "1" }],
              length: 6,
            },
            {
              label: "Attachment",
              placeholder: "",
              type: "input",
              value: "",
              data: [],
              length: 6,
            },
          ],
        },
      ],
    },
    {
      customField: false,
      layout: "fixed",
      tab_name: "Attendence",
      block: [
        {
          blockName: "",
          blockType: "simpleBlock",
          inputs: [
            {
              label: "attendence_table",
              type: "attendenceTable",
              value: "",
              data: [],
              length: 12,
            },
          ],
        },
      ],
    },
    {
      customField: false,
      layout: "fixed",
      tab_name: "Timeoff",
      block: [
        {
          blockName: "",
          blockType: "simpleBlock",
          inputs: [
            {
              label: "timeoff_section",
              type: "timeoffSection",
              value: "",
              data: [],
              length: 12,
            },
          ],
        },
      ],
    },
  ],
  peoples: [],
  reload:false,
  selectedPeopleId: "",
};

const changeNumber = (state = initialState, action) => {
  switch (action.type) {
    case "selectedPeopleId":
      console.log(action);
      return {
        ...state,
        selectedPeopleId: action.payload,
      };
      break;
    case "UPDATEPEOPLE":
      console.log(action);
      return {
        ...state,
        peoples: action.payload,
      };
      break;
    case "UPDATEFIELD":
      console.log(action);
      return {
        ...state,
        fieldsBlockVariable: action.payload,
      };
      break;
    case "ADDCUSTOMFIELD":
      console.log(action);
      return {
        ...state,
        count: state.count + 1,
      };
      break;
    case "INCREMENT":
      console.log(action);
      return {
        ...state,
        count: state.count + 1,
      };
      break;
    case "GROUPDATA":
      return {
        ...state,
        groupdata: action.payload,
      };
      break;
    case "HIDEFIELDS":
      return {
        ...state,
        hidefields: action.payload,
      };
      break;
    case "SORTFIELDS":
      return {
        ...state,
        sortfields: action.payload,
      };
      break;
    case "RELOAD_PEOPLE_REDUCER":
      return {
        ...state,
        reload: action.reload,
      };
      break;
    case "CLEANPEOPLEREDUCER":
      console.log(action);
      return {
        ...state,
        count: 0,
        groupdata: 0,
        hidefields: 0,
        sortfields: [{ value: "", order: 1 }],
        fieldsBlockVariable: [
          {
            customField: true,
            layout: "full",
            tab_name: "Personal",
            block: [
              {
                blockName: "General Info",
                blockType: "simpleBlock",
                inputs: [
                  {
                    label: "First Namee",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "Last Name",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "Birthday",
                    placeholder: "February 1st, 1999",
                    type: "datepicker",
                    value: "",
                    data: [{ birthday: "1" }],
                    length: 6,
                  },
                  {
                    label: "Company Name",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "Nationality",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [
                      { nationality: "Pakistani" },
                      { nationality: "Indian" },
                      { nationality: "American" },
                    ],
                    length: 6,
                  },
                  {
                    label: "Gender",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [{ gender: "Male" }, { gender: "Female" }],
                    length: 6,
                  },
                  {
                    label: "Country of Employment",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [
                      { country_of_employment: "Pakistan" },
                      { country_of_employment: "India" },
                      { country_of_employment: "America" },
                    ],
                    length: 6,
                  },
                  {
                    label: "Region of Employment",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                ],
              },
              {
                blockName: "Tags",
                blockType: "simpleBlock",
                inputs: [
                  {
                    label: "Tags",
                    type: "tags",
                    value: "",
                    data: [],
                    length: 12,
                  },
                ],
              },
              {
                blockName: "Contact Info",
                blockType: "collapseBlock",
                inputs: [
                  {
                    label: "Nationality",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [
                      { nationality: "+92" },
                      { nationality: "+91" },
                      { nationality: "+44" },
                    ],
                    length: 3,
                  },

                  {
                    label: "Phone Number",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 4,
                  },
                  {
                    label: "Work Type",
                    placeholder: "",
                    type: "radio",
                    value: "",
                    data: [{ work_type: "Personal" }, { work_type: "Work" }],
                    length: 5,
                  },
                ],
              },
              {
                blockName: "Email",
                blockType: "collapseBlock",
                inputs: [
                  {
                    label: "Email",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 7,
                  },
                  {
                    label: "Work Type",
                    placeholder: "",
                    type: "radio",
                    value: "",
                    data: [{ work_type: "Personal" }, { work_type: "Work" }],
                    length: 5,
                  },
                ],
              },
              {
                blockName: "Address",
                blockType: "collapseBlock",
                inputs: [
                  {
                    label: "Address",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "City",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [{ city: "London" }],
                    length: 6,
                  },
                  {
                    label: "Country",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [
                      { city: "Pakistan" },
                      { city: "India" },
                      { city: "United Kingdom" },
                    ],
                    length: 6,
                  },
                  {
                    label: "Zip Code",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                ],
              },
            ],
          },
          {
            customField: true,
            layout: "full",
            tab_name: "Work",
            block: [
              {
                blockName: "General Info",
                blockType: "simpleBlock",
                inputs: [
                  {
                    label: "Start End Date",
                    placeholder: "",
                    type: "startenddate",
                    value: "",
                    data: [
                      { start_end_date: "01/02/2022" },
                      { start_end_date: "" },
                    ],
                    length: 12,
                  },
                  {
                    label: "Position/Title",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "Team",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [
                      { team: "Dev" },
                      { team: "Design" },
                      { team: "HR" },
                      { team: "Managers" },
                    ],
                    length: 6,
                  },
                  {
                    label: "Work Type",
                    placeholder: "",
                    type: "radio",
                    value: "",
                    data: [
                      { work_type: "Full Time" },
                      { work_type: "Part Time" },
                    ],
                    length: 6,
                  },
                  {
                    label: "Employee Code",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 3,
                  },
                  {
                    label: "Status",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [{ status: "Active" }, { status: "Deactive" }],
                    length: 3,
                  },
                  {
                    label: "Manager",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [
                      { manager: "Irsa Ch" },
                      { manager: "Momina" },
                      { manager: "Rimsha" },
                    ],
                    length: 6,
                  },
                  {
                    label: "Type",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [
                      { type: "Employee" },
                      { type: "Client" },
                      { type: "Collaborator" },
                    ],
                    length: 6,
                  },
                ],
              },
              {
                blockName: "Salary Info",
                blockType: "simpleBlock",
                inputs: [
                  {
                    label: "Salary Duration",
                    placeholder: "",
                    type: "radio",
                    value: "",
                    data: [
                      { salary_duration: "Monthly" },
                      { salary_duration: "Weekly" },
                      { salary_duration: "Daily" },
                      { salary_duration: "Hourly" },
                    ],
                    length: 6,
                  },
                  {
                    label: "Salary",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                ],
              },
              {
                blockName: "Location",
                blockType: "simpleBlock",
                inputs: [
                  {
                    label: "Branch",
                    placeholder: "",
                    type: "select",
                    value: "",
                    data: [
                      { branch: "Buzz Headquarters, Lahore, Pakistan." },
                      { branch: "Buzz Interactive, Malaysia" },
                    ],
                    length: 6,
                  },
                  {
                    label: "Work Nature",
                    placeholder: "",
                    type: "radio",
                    value: "",
                    data: [
                      { work_nature: "Remote" },
                      { work_nature: "Hybrid" },
                      { work_nature: "On Site" },
                    ],
                    length: 6,
                  },
                ],
              },
              {
                blockName: "Bank Info",
                blockType: "collapseBlock",
                inputs: [
                  {
                    label: "Bank Name",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "Title",
                    placeholder: "Account Holder Name",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "Account Number",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "IBAN / SWIFT / BIC",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                ],
              },
              {
                blockName: "Identity Info",
                blockType: "collapseBlock",
                inputs: [
                  {
                    label: "ID Number",
                    placeholder: "xxxxx-xxxxxxx-x",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                  {
                    label: "Expiry",
                    placeholder: "",
                    type: "datepicker",
                    value: "",
                    data: [{ expiry: "1" }],
                    length: 6,
                  },
                  {
                    label: "Attachment",
                    placeholder: "",
                    type: "input",
                    value: "",
                    data: [],
                    length: 6,
                  },
                ],
              },
            ],
          },
          {
            customField: false,
            layout: "fixed",
            tab_name: "Attendence",
            block: [
              {
                blockName: "",
                blockType: "simpleBlock",
                inputs: [
                  {
                    label: "attendence_table",
                    type: "attendenceTable",
                    value: "",
                    data: [],
                    length: 12,
                  },
                ],
              },
            ],
          },
          {
            customField: false,
            layout: "fixed",
            tab_name: "Timeoff",
            block: [
              {
                blockName: "",
                blockType: "simpleBlock",
                inputs: [
                  {
                    label: "timeoff_section",
                    type: "timeoffSection",
                    value: "",
                    data: [],
                    length: 12,
                  },
                ],
              },
            ],
          },
        ],
        peoples: [],
        selectedPeopleId: "",
      };
      break;
    default:
      return state;
  }
};

export default changeNumber;
