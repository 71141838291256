import React, { Fragment } from "react";
import { Box, Grid } from "@mui/material";

function CalenderIcon(props) {
  return (
    <Fragment>
        <Box className="calenderIconBox">
            <Box className="calenderIconBoxtop">{props.month}</Box>
            <Box className="calenderIconBoxbottom">{props.date}</Box>
        </Box>
    </Fragment>
  );
}

export default CalenderIcon;
