import React, { useState, useEffect } from "react";
import { Collapse } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";

const PeopleDetails = () => {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 DEFINED STATUES                                ||
  // ! ||--------------------------------------------------------------------------------||
  const [open, setOpen] = useState(true);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {}, []);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <section className="PeopelDetaisComp">
      {AllPeopleLeft()}
      {PeopleBody()}
      {Widgets()}
    </section>
  );

  function Widgets() {
    return <div className="PeopelDetaisRight"></div>;
  }

  function PeopleBody() {
    return (
      <div className="PeopelDetaisBody">
        <div className="jsb v-center" style={{ padding: 25, paddingBottom: 0 }}>
          {ProfileContainer()}
        </div>
      </div>
    );
  }

  function ProfileContainer() {
    return (
      <div style={{ width: "100%" }}>
        <Outlet />
      </div>
    );
  }

  function AllPeopleLeft() {
    return (
      <div className="PeopelDetaisLeft" style={{ padding: 10 }}>
        <ul className="flo-settings-ul">
          <li className="flo-settings-li">
            <span>Profile</span>
          </li>
          <li className="flo-settings-li " onClick={handleToggle}>
            <span>Roles and Permissions</span>
          </li>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ul className="ls-none">
              <li className="flo-settings-li text-white-hover">
                <Link
                  to="/all-roles"
                  className="v-center text-dec-none text-dark"
                >
                  <span>All Roles</span>
                </Link>
              </li>

              <li className="flo-settings-li flo-setting-menu-active text-white-hover">
                <Link
                  to="/create-role"
                  className="v-center text-dec-none text-dark"
                >
                  <span>Create Roles</span>
                </Link>
              </li>
            </ul>
          </Collapse>

          <li className="flo-settings-li">
            <span>General Setting</span>
          </li>
          <li className="flo-settings-li">
            <span>Reports</span>
          </li>
        </ul>
      </div>
    );
  }
};

export default PeopleDetails;
