import React, { useEffect, useState } from "react";
import { Box, Paper, Grid, Divider, IconButton } from "@mui/material";
import FloMenu from "./floMenu";
import MenuItem from "@mui/material/MenuItem";
import Input from "./input";
import TextSVG from "../assets/img/icon/customFieldsList/text.svg";
import Justifyleft from "../assets/img/icon/customFieldsList/textalign-justifyleft.svg";
import AttachmentSVG from "../assets/img/icon/customFieldsList/attach-square.svg";
import CalendarSVG from "../assets/img/icon/customFieldsList/calendar.svg";
import CallSVG from "../assets/img/icon/customFieldsList/call.svg";
import DollarSVG from "../assets/img/icon/customFieldsList/dollar-square.svg";
import FirstLine1 from "../assets/img/icon/customFieldsList/firstline-1.svg";
import FirstLine2 from "../assets/img/icon/customFieldsList/firstline.svg";
import LinkSquare from "../assets/img/icon/customFieldsList/link-square.svg";
import ProfileSVG from "../assets/img/icon/customFieldsList/profile-circle.svg";
import SMSSVG from "../assets/img/icon/customFieldsList/sms.svg";
import CheckboxSVG from "../assets/img/icon/customFieldsList/checkbox.svg";
import ArrowSVG from "../assets/img/icon/customFieldsList/arrow.svg";
import FormulaField from "../Components/formulaField";
import algoliasearch from "algoliasearch";
import { CalculateFormula } from "../utilities/FormulaCalc";
import { useSelector, useDispatch } from "react-redux";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import { logRoles } from "@testing-library/react";

const client = algoliasearch("ALWBGQP6XY", "bf5e76d592babd95b5f38d992f750c93");
const index = client.initIndex("title");

const AddCustomFields = (props) => {
  const PeopleReducer = useSelector((state) => state.changeNumber);
  const dispatch = useDispatch();
  const [formulaText, setformulaText] = React.useState("Hi Guys!");
  const formulaInputRef = React.useRef(null);
  const [userInput, setUserInput] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedField, setselectedField] = React.useState({
    icon: "",
    title: "",
  });
  const [fieldFunctions, setfieldFunctions] = React.useState([]);
  const [formulaResult, setformulaResult] = React.useState("");
  const [algosearch, setAlgosearch] = React.useState("");
  const [fieldName, setfieldName] = React.useState("");
  const [formulaField, setformulaField] = React.useState("");
  const open = Boolean(anchorEl);

  useEffect(() => {
    console.log("Data:", { fieldName, formulaField });
  }, [fieldName, formulaField]);

  const handleClose = (icon, title) => {
    setAnchorEl(null);
    setselectedField({
      icon,
      title,
    });
  };
  const stringToAsci = (string) => {
    const str = string;
    let ascii = "";

    for (let i = 0; i < str.length; i++) {
      ascii += str.charCodeAt(i) + " ";
    }
    console.log(ascii);
    return ascii;
  };
  const AsciToString = (asciiCode) => {
    const ascii = asciiCode;
    let str = "";

    ascii.split(" ").forEach((code) => {
      str += String.fromCharCode(code);
    });

    return str;
  };
  const calculateFormula = (val) => {
    try {
      algoliaSeach(val);
      const salary = 30000;
      let formula = val;
      const formulaExecuted = eval(formula.replace("salary", salary));

      console.log("formulaExecuted:", formulaExecuted);
      if (!formulaExecuted) {
        console.log("Invalid formula");
        setformulaResult("Invalid formula");
      } else {
        console.log("Executed Formula:", formulaExecuted);
        setformulaResult(formulaExecuted);
      }
    } catch (error) {
      console.log("error: ", error.message);
      setformulaResult(error.message);
    }
  };
  function handleInput(event) {
    const inputText = event.target.textContent;
    setUserInput(inputText);
  }
  function algoliaSeach(val) {
    var searchList = [];
    setAlgosearch(val);
    index.search(val, {}).then(({ hits }) => {
      // console.log("hits:", hits);
      setfieldFunctions(hits);
      // if (hits.length > 0) {
      //   hits.forEach((e) => {
      //     searchList.push({
      //       id: e.objectID,
      //       title: e.title,
      //     });
      //   });
      //   console.log("searchList:", searchList);
      // }
    });
  }
  function AddCustomFieldsFun() {
    if (fieldName != "" && formulaField != "") {
      let blockId = 0;
      let deepfieldsBlockVariable = [...PeopleReducer.fieldsBlockVariable];
      deepfieldsBlockVariable.filter((tabs) => {
        tabs.tab_name = tabs.tab_name.toLowerCase();

        if (tabs.tab_name == props.tabName) {
          tabs.block[blockId].inputs.push({
            label: fieldName,
            placeholder: "",
            type: "input",
            value: formulaField,
            data: [],
            length: 6,
          });
        }
      });
      dispatch({
        type: "ADDCUSTOMFIELD",
        payload: deepfieldsBlockVariable,
      });
      toastSuccess("Custom filed addes successfully");
      setformulaField("");
      setfieldName("");
      console.log(PeopleReducer.fieldsBlockVariable);
    } else if (fieldName == "") {
      toastError("Field Name is require");
    } else {
      toastError("Formula Field is require");
    }
  }

  return (
    <div className="addCustomFields">
      <Grid container spacing={2}>
        <Grid xs={6}>
          <FloMenu
            className="floCustomFieldsUl"
            menu={
              <>
                <div className="FloCustomField" style={{ marginRight: 10 }}>
                  Add a Custom Field
                </div>
              </>
            }
          >
            <div>
              <div>
                <Input
                  getValue={(val) => {
                    setfieldName(val);
                  }}
                  minWidth={300}
                  placeholder=""
                  label="Field Name (optional)"
                />
              </div>
              {selectedField.icon != "" ? (
                <div>
                  <div
                    className="floCustomFieldsDropdown"
                    onClick={() =>
                      setselectedField({
                        icon: "",
                        title: "",
                      })
                    }
                  >
                    <div className="h-center">
                      <img className="manageMenuImg" src={selectedField.icon} />
                      <span className="manageMenuLabel">
                        {selectedField.title}
                      </span>
                    </div>
                    <div className="d-flex">
                      <img className="" src={ArrowSVG} />
                    </div>
                  </div>
                  <span className="FloCustomFieldsDisc">
                    Compute a value in each record based on other fields in the
                    same record.
                  </span>
                  <div className="floCustomField--FormulaTabs">
                    <div className="floCustomField--FormulaTabsHead">
                      <span className="floCustomField--FormulaTabsHead--tabs FormulaTabsHead--tabs--active">
                        Formula
                      </span>
                      <span className="floCustomField--FormulaTabsHead--tabs">
                        Formatting
                      </span>
                    </div>
                    <div className="floCustomField--FormulaTabsBody">
                      <b className="FloCustomFieldsDisc text-p">
                        {formulaResult}
                      </b>
                      {/* <textarea
                        onChange={() => calculateFormula()}
                        id="floCustomFormulaField"
                        rows={5}
                      ></textarea> */}
                      <FormulaField
                        onChange={(val) => {
                          setformulaField(val);
                          algoliaSeach(val);
                          setformulaResult(CalculateFormula(val, 0));
                        }}
                      />

                      <div className={`floCustomInsertFieldFunctions`}>
                        <b>INSERT A FIELD OR FUNCTION</b>
                        <ul>
                          <li>
                            <span>Notes</span>
                            <span>Field</span>
                          </li>
                          {fieldFunctions.map((val) => {
                            return (
                              <>
                                <li>
                                  <span>{val.title}</span>
                                  <span>{val.type}</span>
                                </li>
                                {algosearch.charCodeAt() ==
                                val.title.charCodeAt() ? (
                                  <div className="formulaNotes">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: val.desc,
                                      }}
                                    ></p>
                                  </div>
                                ) : null}
                              </>
                            );
                          })}
                          <li
                            className={`${
                              fieldFunctions.length > 0 ? "d-none" : "d-block"
                            }`}
                          >
                            <span>No record found</span>
                          </li>
                        </ul>
                      </div>
                      <div className="f-end">
                        <span className="btn-block btn-mini mr-6 btn-mini-inv">
                          Cancel
                        </span>
                        <span
                          onClick={() => AddCustomFieldsFun()}
                          className="btn-block btn-mini"
                        >
                          Save
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="floCustomFieldsList">
                  <MenuItem onClick={() => handleClose(LinkSquare, "Formula")}>
                    <img className="manageMenuImg" src={LinkSquare} />
                    <span className="manageMenuLabel">Formula</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose(AttachmentSVG, "Attachment")}
                  >
                    <img className="manageMenuImg" src={AttachmentSVG} />
                    <span className="manageMenuLabel">Attachment</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose(CheckboxSVG, "Checkbox")}
                  >
                    <img className="manageMenuImg" src={CheckboxSVG} />
                    <span className="manageMenuLabel">Checkbox</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose(FirstLine2, "Multiple select")}
                  >
                    <img className="manageMenuImg" src={FirstLine2} />
                    <span className="manageMenuLabel">Multiple select</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose(FirstLine1, "Single select")}
                  >
                    <img className="manageMenuImg" src={FirstLine1} />
                    <span className="manageMenuLabel">Single select</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(ProfileSVG, "User")}>
                    <img className="manageMenuImg" src={ProfileSVG} />
                    <span className="manageMenuLabel">User</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(CalendarSVG, "Date")}>
                    <img className="manageMenuImg" src={CalendarSVG} />
                    <span className="manageMenuLabel">Date</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleClose(CallSVG, "Phone number")}
                  >
                    <img className="manageMenuImg" src={CallSVG} />
                    <span className="manageMenuLabel">Phone number</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(SMSSVG, "Email")}>
                    <img className="manageMenuImg" src={SMSSVG} />
                    <span className="manageMenuLabel">Email</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(LinkSquare, "URL")}>
                    <img className="manageMenuImg" src={LinkSquare} />
                    <span className="manageMenuLabel">URL</span>
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(DollarSVG, "Currency")}>
                    <img className="manageMenuImg" src={DollarSVG} />
                    <span className="manageMenuLabel">Currency</span>
                  </MenuItem>
                </div>
              )}
            </div>
          </FloMenu>
        </Grid>
        <Grid xs={6}>
          <div className="FloCustomField" style={{ marginLeft: 10 }}>
            Add a Multi-Custom Block
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
function IF(logic, trueValue, falseValue) {
  if (logic) {
    return trueValue;
  } else {
    return falseValue;
  }
}
function SUM(...args) {
  let total = 0;
  for (let value of args) {
    total += value;
  }
  return total;
}
export default AddCustomFields;
export { IF, SUM };
