import React, { useState, useEffect } from "react";
import ActiveMenu from "../Components/activeMenu";
import _ from "lodash";
import { AlphaColor } from "../utilities/General";
import { ApproveEmployeData } from "../Components/ApproveTimeData.js/ApproveTimedata";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ApproveTimeModal from "../Components/ApproveTimeData.js/ApproveTimeModal";
import { getLeaveRequestBYID } from "../helper/index";
import useTokkenHook from "../utilities/TokkenHook";
import { useSelector, useDispatch } from "react-redux";
import GeneralHook from "../utilities/GeneralHook";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { updateLeaveRequestStatus } from "../helper/index";
const ApproveTimeOff = () => {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 ALL STATE                                      ||
  // ! ||--------------------------------------------------------------------------------||
  const PeopleReducer = useSelector((state) => state.changeNumber);
  const { getGeneral, setGeneral } = GeneralHook();
  const { tokken } = useTokkenHook();
  const [showModal, setShowModal] = React.useState(false);
  const [leaveReqData, setleaveReqData] = React.useState([]);
  let tab = getGeneral("changeNumber", "fieldsBlockVariable");

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   USE EFFECTS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    handleGetLeaveRequest();
  }, [tab]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleGetLeaveRequest = () => {
    console.log(PeopleReducer.selectedPeopleId);
    getLeaveRequestBYID({ id: PeopleReducer.selectedPeopleId }, tokken).then(
      (resp) => {
        let res = resp.data;
        console.log(res);
        if (res.status === "success") {
          setleaveReqData(res.data);
        }
      }
    );
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                            UPDATE LEAVE REQ STATUS                             ||
  // ! ||--------------------------------------------------------------------------------||
  const handleLeaveReqStatus = (leave_request_id, status) => {
    console.log(leave_request_id);
    updateLeaveRequestStatus({ leave_request_id, status }, tokken).then((e) => {
      if (e.data.status === "success") {
        setShowModal(false);
        handleGetLeaveRequest();
      }
    });
  };
  const statusBadge = (s) => {
    const status = {
      Approved: "approved_badge",
      Disapproved: "disapproved_badge",
      Pending: "pending_badge",
      Tracking: "tracking_badge",
      Holiday: "grayr_badge",
    };
    return status[s];
  };

  return (
    <section>
      <Grid container spacing={2}>
        {getGeneral("attendenceReducer", "featuredTimeoff")?.map(
          (timeoff, i) => {
            if (i < 3)
              return (
                <Grid key={i} xs={3} sx={{ padding: 1 }}>
                  <Box className="leaveSummeryContainer">
                    <div className="fh3">
                      {timeoff.remaining_days}/
                      {timeoff.timeoff_details.total_days} Used
                    </div>
                    <div className="fp">
                      {timeoff.timeoff_details.timeoff_type}
                    </div>
                  </Box>
                </Grid>
              );
          }
        )}
      </Grid>
      <h1 style={{ paddingLeft: "25px", paddingTop: "15px" }}>
        Pending Request
      </h1>

      <TableContainer style={{ marginTop: "10px" }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell className="CentraNo2-m">Name</TableCell>
              {/* <TableCell className="CentraNo2-m">Department</TableCell> */}
              <TableCell className="CentraNo2-m">Type</TableCell>
              <TableCell className="CentraNo2-m">From</TableCell>
              <TableCell className="CentraNo2-m">To</TableCell>
              <TableCell className="CentraNo2-m">Approval</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaveReqData.map((item, index) => (
              <>
                <TableRow
                  key={index}
                  className="f-12 CentraNo2-m"
                  component="th"
                  scope="row"
                >
                  <TableCell className="aprrovetimeoff-tabledata-name">
                    {item.name}
                  </TableCell>
                  {/* <TableCell className="aprrovetimeoff-tabledata">
                    {item.dept}
                  </TableCell> */}
                  <TableCell className="aprrovetimeoff-tabledata">
                    {item.type}
                  </TableCell>
                  <TableCell className="aprrovetimeoff-tabledata">
                    {Moment(item.from).format("DD MMM yy")}
                  </TableCell>
                  <TableCell className="aprrovetimeoff-tabledata">
                    {Moment(item.to).format("DD MMM yy")}
                  </TableCell>
                  <TableCell className="aprrovetimeoff-tabledata">
                    {item.status === "Pending" ? (
                      <>
                        <CloseIcon className="closeico" />
                        <CheckIcon
                          className="checkico"
                          onClick={handleOpenModal}
                        />
                      </>
                    ) : (
                      <span className={`${statusBadge(item.status)} `}>
                        {item.status}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
                <ApproveTimeModal
                  name={item.name}
                  handleLeaveReqStatus={(status) => {
                    handleLeaveReqStatus(item._id, status);
                  }}
                  show={showModal}
                  onClose={handleCloseModal}
                />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* pending request container end*/}
    </section>
  );
};

export default ApproveTimeOff;
