function AlphaColor(a) {
  let colors = {
    A: "#FFDDE1",
    B: "#FFD8F6",
    C: "#FFBBE0",
    D: "#FFA9D8",
    E: "#FDA1A7",
    F: "#F98F95",
    G: "#FFEEBF",
    H: "#F9E6A6",
    I: "#FFD7BA",
    J: "#FFCAA4",
    K: "#FFAF83",
    L: "#D2E1F0",
    M: "#C3E8EB",
    N: "#B4E0D6",
    O: "#92CFC0",
    P: "#84EAD8",
    Q: "#D9D6FA",
    R: "#D6E7FA",
    S: "#D0DEEE",
    T: "#C1BBFF",
    U: "#BBCAFF",
    V: "#BDDDCC",
    W: "#C7DDBD",
    X: "#ADE1A4",
    Y: "#D5E1A4",
    Z: "#E1DBA4",
  };
  return colors[a];
}

function GrantAccess(array, key, module) {
  return !!array.filter((e) => e.permission_name == key && e.module == module)
    .length;
}


export { AlphaColor, GrantAccess };
