import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo/Logo.svg";
import Input from "../Components/input";
import "../App.css";
import { Checkbox } from "@mui/material";
import arrow from "../assets/img/icon/arrow-p.svg";
import google from "../assets/img/icon/google.svg";
import outlook from "../assets/img/icon/outlook.svg";
import eyeslash from "../assets/img/icon/eyeslash.svg";
import check from "../assets/img/icon/check.svg";
import alert from "../assets/img/icon/alert.svg";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import { ForgotPass } from "../helper/index";
function ForgotPassword(props) {
  const navigate = useNavigate();
  const [body, setbody] = useState({
    email: "",
  });
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 FORGOT PASSWORD                                ||
  // ! ||--------------------------------------------------------------------------------||
  const forgotPass = () => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (body.email == "") {
      toastError("Email is require");
    } else if (!filter.test(body.email)) {
      toastError("Email is invalid");
    } else {
      ForgotPass(body).then((res) => {
        if (res.data.status == "error") {
          toastError(res.data.data.message);
        } else if ((res.data.status = "success")) {
          toastSuccess(res.data.data.message);
          navigate("/check-email", { state: body });
        }
      });
    }
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 RETURN FUNCTION                                ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <div className="signup bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading">Forgot Password?</h4>
      <div className="h-center">
        <div>
          <div className="board-white">
            <p className="board-white-p">
              No worries, we’ll send you reset instructions
            </p>

            <div style={{ marginTop: 15 }}>
              <Input
                getValue={(e) => setbody({ ...body, email: e })}
                value={body.email}
                minWidth={350}
                placeholder="tom@gmail.com"
                label="Enter your email"
              />
            </div>

            <div
              onClick={() => forgotPass()}
              className="btn-block btn_text"
              variant="contained"
            >
              Forgot Password
            </div>
            <p className="h-center CentraNo2 already_use_flo">
              Didn’t receive the email?{" "}
              <a onClick={() => forgotPass()} style={{ paddingLeft: 5 }}>
                Click to resend
              </a>
            </p>
            <p className="h-center CentraNo2 already_use_flo">
              <Link
                to="/login"
                className="v-center"
                style={{ paddingLeft: 5, textDecoration: "none" }}
              >
                <img src={arrow} />{" "}
                <span style={{ color: "black", paddingLeft: 10 }}>
                  Back to login
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
