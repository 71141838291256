import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import search from "../assets/img/icon/search.svg";


import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

export default function SelectLabels(props) {
  const [age, setAge] = React.useState(props.default);

  const handleChange = (event) => {
    setAge(event.target.value);
    props.onChange(event)
  };

  return (
    <div className='floDropdown'>
      <FormControl sx={{ m: 1, minWidth: 100,width:"100%" }}>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          
        >
          {props.children}
        </Select>
      </FormControl>
    </div>
  );
}