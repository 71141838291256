import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Search from "../../../assets/img/icon/search.svg";
import CloseArrow from "../../../assets/img/icon/closeArrow.svg";
import Button from "@mui/material/Button";
import AddPeople from "../../../Screens/AddPeopleModel";
import { useNavigate } from "react-router-dom";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

export default function FloHeader() {

  let navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const shiftTab = (key) => {
    if (key == 2) {
      const element = document.querySelector(".navbar-flotab-active");
      element.style.transition = "left 0.15s";
      element.style.left = "89px";

      const FloPeopleTab = document.querySelector(".FloPeopleTab");
      FloPeopleTab.style.color = "#AFAFBD";
      const FloBaseTab = document.querySelector(".FloBaseTab");
      FloBaseTab.style.color = "#6C6C71";
    } else {
      const element = document.querySelector(".navbar-flotab-active");
      element.style.transition = "left 0.15s";
      element.style.left = "4px";

      const FloPeopleTab = document.querySelector(".FloPeopleTab");
      FloPeopleTab.style.color = "#6C6C71";
      const FloBaseTab = document.querySelector(".FloBaseTab");
      FloBaseTab.style.color = "#AFAFBD";
    }
  };

  return (
    <AppBar style={{ backgroundColor: "#ffffff" }} className="navbar-CSS">
      <Container
        maxWidth="none"
        // style={{ background: "transparent", margin: "20px 0px", width: "100%"  }}
        className="navbar_padding_margin mainContainer"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            className="navbarleftside"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box className="v-center">
              <h2 style={{ color: "#000000" }} className="navbarName">Manage
                {/* <img onClick={()=> navigate(-1)} className="floHeaderHeading hover" src={CloseArrow} /> Manage */}
              </h2>

              {/* <div className="floPeopleBaseSwitch">
                <Box className="navbar-container">
                  <Box className=" navbar-flotab-active"></Box>
                   <Box className="flotab flotabL flotab-deactive navbar_Screen">
                    <Box onClick={() => shiftTab(1)}> */}
                      {/* className="v-center" */}
                      {/* <img src={peopleIcon} /> */}
                      {/* <Box
                        className="FloPeopleTab"
                        style={{
                          color: "#6C6C71",
                          fontSize: "12.5px",
                          fontWeight: "bold",
                        }}
                      >
                        12 People
                      </Box>
                    </Box>
                  </Box>
                  <Box className="flotab flotabR flotab-deactive navbar_Screen">
                    <Box onClick={() => shiftTab(2)}> */}
                      {/* <img src={baseIcon} /> */}
                      {/* <Box
                        className="FloBaseTab"
                        style={{
                          color: "#AFAFBD",
                          fontSize: "12.5px",
                          fontWeight: "bold",
                        }}
                      >
                        4 Bases
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div> */}

              <Button variant="text" className="navBarfilterbutton" style={{marginLeft:"20px"}}>
                <img
                  src={Search}
                  style={{
                    height: "18px",
                    width: "18px",
                    marginRight: "8px",
                  }}
                />
                <h6
                  style={{ color: "#6C6C71", fontSize: "14px" }}
                  className="navbarName navbarfiltertext"
                >
                  Search
                </h6>
              </Button>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingRight: 7,
            }}
          >
            <Box sx={{ width: 80 }}>
              <AddPeople />
            </Box>
          </div>
        </div>
      </Container>
    </AppBar>
  );
}
