import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import ActionIcon from "../assets/img/icon/action.svg";
import SortIcon from "../assets/img/icon/newSort.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FloCheckbox from "./checkbox";
import { Checkbox } from "@mui/material";
import checkboxIcon from "../assets/img/icon/checkbox.svg";
import checkedCheckbox from "../assets/img/icon/checkedCheckbox.svg";
import FloMenu from "../Components/floMenu";
import Input from "../Components/input";
import DropdownModel from "./attendencereportdata/DropdownModel";
import { limitdepartment } from "./attendencereportdata/LimitDepartment";
import { AlphaColor } from "../utilities/General";
export default function Active(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [open, setOpen] = React.useState(true);
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <AppBar className="Active-Bar-CSS">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* sx={{ width: 100, height: 35, position: "relative" }} */}
          <Box>
            <div
              variant="text"
              className="btn--active v-center"
              onClick={handleClick}
            >
              <div style={{ paddingLeft: 13 }}>
                <Checkbox
                  className={`"flocheckbox baseNameCheckbox"`}
                  onChange={(e) => props.setChecked(e)}
                  inputProps={{ "aria-label": "FloCheckbox" }}
                  icon={<img className="checkboxIcon" src={checkboxIcon} />}
                  checkedIcon={
                    <img className="checkboxIcon" src={checkedCheckbox} />
                  }
                />
              </div>
              <ListItemText
                primary="Active"
                className="dropdown--text--active"
              />
              <ExpandMore style={{ width: "20px", height: "20px" }} />
              {/* {open ? <ExpandLess  style={{width:'20px' , height:'20px'}}/> : <ExpandMore style={{width:'20px' , height:'20px'}}/>} */}
            </div>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div></div>
          </Collapse>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            {/* Filter Model start*/}
            <div className="addCustomFields">
              <div style={{ display: "flex" }}>
                {" "}
                <IconButton className="iconbtn--active">
                  <img
                    src={SortIcon}
                    style={{ height: "18px", width: "18px" }}
                  />
                </IconButton>
                <FloMenu
                  className="floCustomFieldsUl"
                  menu={
                    <>
                      <IconButton className="iconbtn--active">
                        <img
                          src={ActionIcon}
                          style={{ height: "18px", width: "18px" }}
                        />
                      </IconButton>
                    </>
                  }
                >
                  <div>
                    <div>
                      <Input
                        className="flo-input flo-textarea"
                        minWidth={300}
                        placeholder=""
                        label="Search (optional)"
                      />
                    </div>

                    <div
                      className="floCustomFieldsList"
                      style={{ padding: "10px" }}
                    >
                      <div>Departments</div>
                      <DropdownModel label={"Software Department"} />
                      <div style={{ marginTop: "10px" }}>Last Search</div>
                      {limitdepartment.map((item, index) => (
                        <div
                          className="titleBorder-department"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                          key={index}
                        >
                          <span
                            className="profileimg"
                            style={{
                              backgroundColor: AlphaColor(
                                item.departmentTitle[0]
                              ),
                            }}
                          >
                            {item.departmentTitle
                              ? item.departmentTitle[0]
                              : null}
                          </span>
                          <div className="department-titles">
                            {item.departmentTitle}
                          </div>
                        </div>
                      ))}
                      <div style={{ marginTop: "10px" }}>Employees</div>
                      <DropdownModel label={"Search Employees"} />
                      <div style={{ marginTop: "10px" }}>Date</div>
                      <DropdownModel label={"Date"} />
                    </div>
                  </div>
                </FloMenu>
              </div>
            </div>
            {/* Filter Model End */}
            {/* <IconButton className="iconbtn--active">
              <img src={SortIcon} style={{ height: "18px", width: "18px" }} />
            </IconButton>
            <IconButton className="iconbtn--active">
              <img src={ActionIcon} style={{ height: "18px", width: "18px" }} />
            </IconButton> */}
          </Box>
        </div>
      </div>
    </AppBar>
  );
}
