import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo/Logo.svg";
import Input from "../Components/input";
import Select from "../Components/select";
import "../App.css";
import "animate.css";
import {
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
} from "@mui/material";
import arrow from "../assets/img/icon/Arrow.svg";
import google from "../assets/img/icon/google.svg";
import outlook from "../assets/img/icon/outlook.svg";
import MenuItem from "@mui/material/MenuItem";
import { SignIn, Signup, InvitePeople, createRole } from "../helper/index";
import { useLocation, useNavigate } from "react-router-dom";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import { useSelector, useDispatch } from "react-redux";
import LoaderComp from "../Components/Loader";

// ! ||--------------------------------------------------------------------------------||
// ! ||                                 MAIN COMPONENT                                 ||
// ! ||--------------------------------------------------------------------------------||
function CompanyDetails() {
  const OnboardingReducer = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();
  const [howManyPeople, sethowManyPeople] = useState("01-10");
  const [howDidYouHear, sethowDidYouHear] = useState("social_media");
  const myRadioRef0 = useRef(null);
  const myRadioRef1 = useRef(null);
  const myRadioRef2 = useRef(null);
  const myRadioRef3 = useRef(null);

  const myRadioRef10 = useRef(null);
  const myRadioRef11 = useRef(null);
  const myRadioRef12 = useRef(null);
  const myRadioRef13 = useRef(null);
  const myRadioRef14 = useRef(null);
  const myRadioRef15 = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setbody] = useState(OnboardingReducer);
  const [loader, setloader] = useState(false);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   USE EFFECTS                                   ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    console.log("Body:", body);
  }, [body]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 HOW MANY PEOPLE                                ||
  // ! ||--------------------------------------------------------------------------------||
  const HowManyPeopleHendler = (event) => {
    setbody({ ...body, no_emp: event.target.value });
    sethowManyPeople(event.target.value);
  };
  const controlPropsPeople = (item) => ({
    checked: howManyPeople === item,
    onChange: HowManyPeopleHendler,
    value: item,
    name: "how-many-people",
    inputProps: { "aria-label": item },
  });
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                HOW DID YOU HEAR                                ||
  // ! ||--------------------------------------------------------------------------------||
  const HowDidYouHearHendler = (event) => {
    setbody({ ...body, how_did_hear_you_about_us: event.target.value });
    sethowDidYouHear(event.target.value);
  };
  const controlProps = (item) => ({
    checked: howDidYouHear === item,
    onChange: HowDidYouHearHendler,
    value: item,
    name: "how-did-you-hear",
    inputProps: { "aria-label": item },
  });
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   GET STARTED                                  ||
  // ! ||--------------------------------------------------------------------------------||
  const getStarted = () => {
    setloader(true);
    console.log("in", body);
    if (
      body.how_did_hear_you_about_us == "other" ||
      body.how_did_hear_you_about_us == "ref"
    ) {
      toastWarning(
        `${howDidYouHear == "other" ? "Other" : "Ref"} field is required`
      );
      setloader(false);
      return false;
    }
    Signup(body).then(async (res) => {
      debugger
      if (res.data.status == "error") {
        console.log(typeof res.data.data);
        if (res.data.data.message) {
          toastError(res.data.data.message);
          setloader(false);
        } else {
          res.data.data.forEach((msg) => {
            toastError(msg);
            setloader(false);
          });
        }
      } else if ((res.data.status = "success")) {
        let token = res.data.data.token;
        let userData = res.data.data.newUser;
        console.log("user data",userData);
        
        dispatch({
          type: "ResetOnboarding",
          payload: "",
        });
        const adminRolePromise = await createRole({
          role_name: "Admin",
          company_id: userData._id,
          permissions: [
            "66339f3955fae51939805cfb",
            "66339f3955fae51939805cfc",
            "66339f3955fae51939805cfd",
            "66339f3955fae51939805d00",
            "66339f3955fae51939805d03",
            "66339f3955fae51939805d06",
            "66339f3955fae51939805d09",
            "66339f3955fae51939805d0c",
            "66339f3955fae51939805d0f",
            "66339f3955fae51939805d12",
            "66339f3955fae51939805d0b",
            "66339f3955fae51939805d0e",
            "66339f3955fae51939805d11",
            "66339f3955fae51939805d08",
            "66339f3955fae51939805d05",
            "66339f3955fae51939805d02",
            "66339f3955fae51939805cfe",
            "66339f3955fae51939805d04",
            "66339f3955fae51939805d01",
            "66339f3955fae51939805d07",
            "66339f3955fae51939805d0a",
            "66339f3955fae51939805d0d",
            "66339f3955fae51939805d10",
            "66339f3955fae51939805d13",
            "66339f3955fae51939805cff",
          ],
        });

        const invitePromise = await InvitePeople([
          {
            company: userData.company_name,
            company_id: userData._id,
            email: userData.email,
            name: body.yourName,
            type: "Admin",
            pass: body.password,
          },
        ]);
       
        const employeeRolePromise = await createRole({
          role_name: "Employee",
          company_id: userData._id,
          permissions: [
            "66339f3955fae51939805d0c",
            "66339f3955fae51939805d0d",
            "66339f3955fae51939805d0e",
            "66339f3955fae51939805d10",
            "66339f3955fae51939805d11",
            "66339f3955fae51939805d12",
            "66339f3955fae51939805d13",
          ],
        });
        const lineManagerRolePromise = await createRole({
          role_name: "Line Manager",
          company_id: userData._id,
          permissions: [
            "66339f3955fae51939805d0a",
            "66339f3955fae51939805d0b",
            "66339f3955fae51939805d08",
            "66339f3955fae51939805d0e",
            "66339f3955fae51939805d12",
            "66339f3955fae51939805d11",
            "66339f3955fae51939805d13",
            "66339f3955fae51939805d04",
          ],
        });
        // setloader(false);
        //   Login({ email: userData.email, password: body.password });
        Promise.all([invitePromise, adminRolePromise, employeeRolePromise, lineManagerRolePromise])
        .then(([inviteResponse, adminRoleResponse, employeeRoleResponse, lineManagerRoleResponse]) => {
          if (inviteResponse.data.status === "success") {
            toastSuccess("User registered successfully!");
            setloader(false);
            Login({ email: userData.email, password: body.password });
          } else if (inviteResponse.data.status === "error") {
            setloader(false);
            inviteResponse.data.data.forEach((error) => {
              toastError(error.msg);
            });
          }
        })
        .catch((error) => {
          setloader(false);
          toastError(error.msg);
          console.error("Error details:", error);
        });
      } else if (res.data.status == "error") {
        setloader(false);
        res.data.data.map((e) => {
          toastError(e.msg);
        });
      }
    });
  };
  const Login = (data) => {
    SignIn(data).then((res) => {
      if (res.data.status == "error") {
        toastError(res.data.data.message);
      } else if ((res.data.status = "success")) {
        let token = res.data.data.token;
        let userData = res.data.data.getUser;
        console.log("res", { token, userData });
        setloader(false);
        dispatch({
          type: "UPDATETOKEN",
          payload: token,
        });
        dispatch({
          type: "UPDATEUSERDATA",
          payload: userData,
        });
        navigate("/manage");

        toastSuccess("User Authenticated!");
      }
    });
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                RETURN COMPONENT                                ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <div className="signup bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading">Help us understand your needs.</h4>
      <div className="h-center">
        <div>
          <div className="board-white animate__fadeIn">
            {/* HOW MANY PEOPLES */}
            <p className="board-white-p">
              What is the team size of your company
            </p>
            <RadioGroup row name="people" sx={{ width: "100%" }}>
              <Grid container spacing={1.5}>
                <Grid item xs={3}>
                  <div
                    onClick={() => {
                      myRadioRef0.current.click();
                    }}
                    className={`people-radio-box ${
                      howManyPeople == "01-10" ? "active-radio-box" : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">01-10</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef0}
                        {...controlPropsPeople("01-10")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    onClick={() => {
                      myRadioRef1.current.click();
                    }}
                    className={`people-radio-box ${
                      howManyPeople == "10-25" ? "active-radio-box" : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">10-25</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef1}
                        {...controlPropsPeople("10-25")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    onClick={() => {
                      myRadioRef2.current.click();
                    }}
                    className={`people-radio-box ${
                      howManyPeople == "25-49" ? "active-radio-box" : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">25-49</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef2}
                        {...controlPropsPeople("25-49")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    onClick={() => {
                      myRadioRef3.current.click();
                    }}
                    className={`people-radio-box ${
                      howManyPeople == "50+" ? "active-radio-box" : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">50+</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef3}
                        {...controlPropsPeople("50+")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </RadioGroup>
            {/* HOW DID YOU HEAR ABOUT US */}
            <p className="board-white-p mt-25">How did you hear about us.</p>
            <RadioGroup row name="people" sx={{ width: 385 }}>
              <Grid container spacing={1.5}>
                <Grid item xs={4}>
                  <div
                    onClick={() => {
                      myRadioRef10.current.click();
                    }}
                    className={`people-radio-box ${
                      howDidYouHear == "social_media"
                        ? "active-radio-box"
                        : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">Social Media</p>
                      <p className="did-you-hear-sublabel">e.g IG, FB</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef10}
                        {...controlProps("social_media")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    onClick={() => {
                      myRadioRef11.current.click();
                    }}
                    className={`people-radio-box ${
                      howDidYouHear == "search_engine"
                        ? "active-radio-box"
                        : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">Search Engine</p>
                      <p className="did-you-hear-sublabel">e.g Google</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef11}
                        {...controlProps("search_engine")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    onClick={() => {
                      myRadioRef12.current.click();
                      setbody({ ...body, how_did_hear_you_about_us: "ref" });
                    }}
                    className={`people-radio-box ${
                      howDidYouHear == "ref" ? "active-radio-box" : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">Referral</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef12}
                        {...controlProps("ref")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    onClick={() => {
                      myRadioRef13.current.click();
                    }}
                    className={`people-radio-box ${
                      howDidYouHear == "review_website"
                        ? "active-radio-box"
                        : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">Review Sites</p>
                      <p className="did-you-hear-sublabel">e.g G2 Crowd</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef13}
                        {...controlProps("review_website")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    onClick={() => {
                      myRadioRef14.current.click();
                    }}
                    className={`people-radio-box ${
                      howDidYouHear == "blogs_ads" ? "active-radio-box" : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">Blogs / Ads</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef14}
                        {...controlProps("blogs_ads")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    onClick={() => {
                      myRadioRef15.current.click();
                      setbody({ ...body, how_did_hear_you_about_us: "other" });
                    }}
                    className={`people-radio-box ${
                      howDidYouHear == "other" ? "active-radio-box" : null
                    }`}
                    style={{ display: "flex" }}
                  >
                    <Box>
                      <p className="did-you-hear-label">Other</p>
                    </Box>
                    <Box>
                      <Radio
                        inputRef={myRadioRef15}
                        {...controlProps("other")}
                        className="p-0"
                        size="small"
                        sx={{
                          color: "#b3bdbc99",
                          "&.Mui-checked": {
                            color: "#ed604b",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </RadioGroup>
            {/* OTHER OPTION */}
            <Box
              style={{ marginLeft: 5, marginRight: 7 }}
              sx={{
                marginTop: 2,
                display:
                  howDidYouHear == "ref" || howDidYouHear == "other"
                    ? "block"
                    : "none",
              }}
            >
              <Input
                getValue={(e) =>
                  setbody({ ...body, how_did_hear_you_about_us: e })
                }
                value={
                  body.how_did_hear_you_about_us == "other" ||
                  body.how_did_hear_you_about_us == "ref"
                    ? ""
                    : body.how_did_hear_you_about_us
                }
                minWidth={350}
                multiline
                row="2"
                placeholder="Tell us from where you learned about us"
              />
            </Box>
            {/* GET STARTED BUTON */}
            <div style={{ marginLeft: 5, marginRight: 5 }}>
              <Button
                sx={{ marginTop: 2 }}
                onClick={() => !loader && getStarted()}
                className="btn-block btn_text"
                variant="contained"
              >
                <LoaderComp loader={loader}>Get Started </LoaderComp>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
