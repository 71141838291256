import { render } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import FloCheckbox from "../Components/checkbox";
import { useSelector, useDispatch } from "react-redux";
import GeneralHook from "../utilities/GeneralHook";
import { AlphaColor } from "../utilities/General";


const BaseName = (props) => {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                            DEFINED STATES AND CONSTS                           ||
  // ! ||--------------------------------------------------------------------------------||
  const { getGeneral, setGeneral } = GeneralHook();
  const OnboardingReducer = useSelector((state) => state.changeNumber);
  const dispatch = useDispatch();
  const colors = ["#FFDDE1", "#FFEEBF", "#D2E1F0", "#D9D6FA", "#E9FCFF"];
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  const rColor = () => {
    let randomIndex = Math.floor(Math.random() * colors.length);
    randomIndex = colors[randomIndex];
    return randomIndex;
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <section className="baseName">
      <div className="v-center pl-22">
        <div style={{ marginRight: 12 }}>
          <FloCheckbox
            style={{ marginRight: "unset", marginLeft: 6 }}
            index={props.index}
            className="flocheckbox baseNameCheckbox"
            checked={props.checkedAll}
          />
        </div>

        <div
          className="hover v-center"
          style={{ width: "100%" }}
          onClick={() => {
            console.log("props.detail?:", props.data);
            dispatch({
              type: "UPDATEFIELD",
              payload: props.data?.details,
            });
            setGeneral("selectedPeopleId", props.data._id);
          }}
        >
          <span
            className="profileimg"
            style={{
              backgroundColor: AlphaColor(props.title[0]),
            }}
          >
            {props.title ? props.title[0] : null}
          </span>
          <div style={{ width: "100%" }}>
            <div className="baseNametitle">{props.title}</div>
            <div className="baseNameDesc">{props.detail}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BaseName;
