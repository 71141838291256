import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 12,
      height: 12,
    },
    "&:before": {
      //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      //     theme.palette.getContrastText(theme.palette.primary.main)
      //   )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      //     theme.palette.getContrastText(theme.palette.primary.main)
      //   )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 12,
    height: 12,
    margin: 2,
  },
}));

export default function CustomizedSwitches(props) {
  const [checkedSwitch, setcheckedSwitch] = React.useState(!props.hide);
  const [fields, setfields] = React.useState(props.field);
  React.useEffect(() => {
    console.log("UE",props.hide)
    setcheckedSwitch(!props.hide)
  }, [props.hide]);
  return (
    <div className="floSwitch" key={props.key}>
      <FormGroup style={{ display: "inline-block" }}>
        <FormControlLabel
          control={
            <Android12Switch
              checked={checkedSwitch}
              value={props.value}
              onChange={(e) => {
                const index = props.field.findIndex((element) => element.value === e.target.value);
                console.log("index",index);
                console.log(e.target.checked);
                console.log(e.target.value);
                setcheckedSwitch(e.target.checked);
                let temp = {
                  value: props.value,
                  title: props.label,
                  hide: !e.target.checked,
                };
                let temp_fields = fields;
                temp_fields[index] = temp;
                console.warn("switch",index);
                console.warn("switch",fields);
                props.setfields(fields);
              }}
              
            />
          }
          label={ props.label}
        />
      </FormGroup>
    </div>
  );
}
