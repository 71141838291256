import React, { useEffect, useState, Fragment } from "react";
import { SignIn } from "../helper/index";
import FloHeader from "../Components/floheader";
import Toolbar from "../Components/toolbar";
import Flo1Layout from "../Components/layouts/Layout2/sidebar";
import checkboxIcon from "../assets/img/icon/checkbox.svg";
import checkedCheckbox from "../assets/img/icon/checkedCheckbox.svg";
import ManagePeople from "../helper/ManagePeople";
import { Button, Checkbox, Paper } from "@mui/material";
import { SignalCellularNullOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import GeneralHook from "../utilities/GeneralHook";
import { AlphaColor } from "../utilities/General";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import { useSelector, useDispatch } from "react-redux";
import FloCheckbox from "../Components/checkbox";
import { store } from "../store";
import { get } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// ! ||--------------------------------------------------------------------------------||
// ! ||                                 MAIN COMPONENT                                 ||
// ! ||--------------------------------------------------------------------------------||
function Manage() {
  // restrict browser back button start
  window.history.pushState(null, null, window.location.pathname); // Replace current history state
  // restrict browser back button end
  const { getPeople } = ManagePeople();
  const colors = ["#FFDDE1", "#FFEEBF", "#D2E1F0", "#D9D6FA", "#E9FCFF"];
  const { getGeneral, setGeneral } = GeneralHook();
  const [fields, setfields] = useState([
    { value: "name", title: "Name", hide: false },
    { value: "job_title", title: "Job Title", hide: false },
    { value: "dept", title: "Dept", hide: false },
    { value: "tags", title: "Tags", hide: false },
    { value: "managers", title: "Managers", hide: false },
    { value: "work_type", title: "Work Type", hide: false },
  ]);
  const [tableHeading, settableHeading] = useState(fields);
  const [loading, setloading] = useState(false);
  const [tableHeadingWidth, settableHeadingWidth] = useState([]);
  const [data, setdata] = useState([]);
  const [groupdata, setgroupdata] = useState([]);
  const [checkedAll, setcheckedAll] = useState(false);
  const [checkedAllId, setcheckedAllId] = useState([]);
  const peopleSD = useSelector((state) => state.changeNumber);
  const userReducer = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   USE EFFECTS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    console.log("userReducer:", userReducer);
    if (userReducer.token == "") {
      navigate("/");
      toastError("Sorry, you are not authorized!");
    }
  }, []);
  const reSizeColumns = (thI) => {
    let max = 0;
    setTimeout(() => {
      let tdwidth = document.querySelectorAll(`.td-${thI}`);
      let thwidth = document.querySelectorAll(`.th-${thI}`);
      if (true) {
        tdwidth.forEach((e, i) => {
          if (e.offsetWidth > max) {
            max = e.offsetWidth + 30;
          }
        });
        tdwidth.forEach((e, i) => {
          e.style.width = `${max}px`;
          e.style.minWidth = `${max}px`;
          if (thwidth[i]) {
            if (thwidth[i].style) {
              thwidth[i].style.width = `${max}px`;
              thwidth[i].style.minWidth = `${max}px`;
            }
          }
        });
        thI++;
      }
    }, 0);
  };
  useEffect(() => {
    getPeoples();
  }, [peopleSD.sortfields]);
  const getPeoples = () => {
    setloading(true);
    getPeople({
      groupdata: groupdata,
      company_id: userReducer.userDetails.company_id,
    }).then((res) => {
      setloading(false);
      console.log("res:", res);
      if (res.data.status == "error") {
        toastError(res.data.data.message);
      } else if ((res.data.status = "success")) {
        if (res.data.data.length > 0) {
          let sortedData = res.data.data;
          if (peopleSD.sortfields[0]?.value != "") {
            sortedData = sortedData.sort((a, b) => {
              console.log("a,b", a, b);
              console.log(".........", b[peopleSD.sortfields[0].value]);
              if (peopleSD.sortfields[0]?.order == 0) {
                return b[peopleSD.sortfields[0].value].localeCompare(
                  a[[peopleSD.sortfields[0].value]]
                );
              } else {
                return a[[peopleSD.sortfields[0].value]].localeCompare(
                  b[[peopleSD.sortfields[0].value]]
                );
              }
            });
          }
          setdata(sortedData);
        } else {
        }
      }
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     RETURN                                     ||
  // ! ||--------------------------------------------------------------------------------||
  // GroupChangeHandler function to handle group changes
  const GroupChangeHandler = (data) => {
    setgroupdata(data.data);
    getPeoples();
  };
  return (
    <>
      <Flo1Layout
        loading={loading}
        setfields={(e) => {
          setTimeout(() => {
            setfields([]);
          }, 300);
          setTimeout(() => {
            setfields(e);
          }, 500);
        }}
        fields={fields}
        onGroupChange={(e) =>
          setTimeout(() => {
            GroupChangeHandler(e);
          }, 500)
        }
      >
        <TableContainer
          component={Paper}
          className="floTableContainer"
          style={{ overflow: "scroll" }}
        >
          <Table
            style={{
              minWidth: "100%",
              overflow: "scroll",
            }}
          >
            <TableHead sx={{ backgroundColor: "#fbfafc", bottom:1,}}>
              <TableRow
                className="floTbody"
                style={{
                  paddingLeft: groupdata
                    ? groupdata.length > 0
                      ? 33
                      : 16
                    : 16,
                }}
              >
                {tableHeading.map((data, i) =>
                  !data.hide ? (
                    <TableCell
                      key={i}
                      className={`th th-${i}`}
                      style={{ color: "rgba(0, 0, 0, 0.67)"}}
                    >
                      {i == 0 ? (
                        <Checkbox
                          onChange={(e) => {
                            console.log("e.target.checked", e.target.checked);
                            setcheckedAll(e.target.checked);
                          }}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                          icon={
                            <img className="checkboxIcon" src={checkboxIcon} />
                          }
                          checkedIcon={
                            <img
                              className="checkboxIcon"
                              src={checkedCheckbox}
                            />
                          }
                        />
                      ) : null}{" "}
                      {data.title}
                    </TableCell>
                  ) : null
                )}
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                padding: "0px 16px 6px",
                backgroundColor: "#fbfafc",
                position: "relative",
                // bottom: 1,
              }}
            >
              {data?.map((subgroup, indx) => {
                let tags = subgroup?.details[0].block[1].inputs[0].data;
                let obj = Object.entries(subgroup).map(([key, value], k) => {
                  return { key, value };
                });
                let filteredName = fields.filter((f) => {
                  return f.value == "name";
                });
                let filteredDept = fields.filter((f) => {
                  return f.value == "dept";
                });
                let filteredJobTitle = fields.filter((f) => {
                  return f.value == "job_title";
                });
                let filteredTags = fields.filter((f) => {
                  return f.value == "tags";
                });
                let filteredManagers = fields.filter((f) => {
                  return f.value == "managers";
                });
                let filteredWorkType = fields.filter((f) => {
                  return f.value == "work_type";
                });
                console.log("filteredName:", filteredName);

                return (
                  <TableRow key={indx} className="floTr">
                    {filteredName[0]?.hide == false ? (
                      <TableCell className="td td-0">
                        <div className="d-flex">
                          <FloCheckbox
                            index={indx}
                            className="flocheckbox"
                            checked={checkedAll}
                          />
                          <Link
                            to={`/view-people/${subgroup._id}`}
                            className="aUnset d-flex"
                            onClick={() => {
                              setGeneral("selectedPeopleId", subgroup._id);
                              dispatch({
                                type: "UPDATEFIELD",
                                payload: subgroup.details,
                              });
                            }}
                          >
                            <span
                              className="profileimg"
                              style={{
                                backgroundColor: AlphaColor(subgroup?.name[0]),
                              }}
                            >
                              {subgroup.name ? subgroup.name[0] : null}
                            </span>
                            <div>{subgroup.name}</div>
                          </Link>
                        </div>
                      </TableCell>
                    ) : null}
                    {filteredJobTitle[0]?.hide == false ? (
                      <TableCell className="td td-1">
                        <Link
                          to={`/view-people?_id${subgroup._id}`}
                          onClick={() => {
                            setGeneral("selectedPeopleId", subgroup._id);
                            dispatch({
                              type: "UPDATEFIELD",
                              payload: subgroup.details,
                            });
                          }}
                          className="aUnset"
                        >
                          {subgroup.details[1].block[0].inputs[1].value}
                        </Link>
                      </TableCell>
                    ) : null}
                    {filteredDept[0]?.hide == false ? (
                      <TableCell className="td td-2">
                        <Link
                          to={`/view-people?_id${subgroup._id}`}
                          onClick={() => {
                            setGeneral("selectedPeopleId", subgroup._id);
                            dispatch({
                              type: "UPDATEFIELD",
                              payload: subgroup.details,
                            });
                          }}
                          className="aUnset"
                        >
                          {subgroup.details[1].block[0].inputs[2].value}
                        </Link>
                      </TableCell>
                    ) : null}
                    {filteredTags[0]?.hide == false ? (
                      <TableCell className="td td-3">
                        <Link
                          to={`/view-people?_id${subgroup._id}`}
                          onClick={() => {
                            setGeneral("selectedPeopleId", subgroup._id);
                            dispatch({
                              type: "UPDATEFIELD",
                              payload: subgroup.details,
                            });
                          }}
                          className="aUnset"
                        >
                          {tags
                            ? tags.map((tag) => (
                                <span className="accessBadge" key={tag.tag}>
                                  {tag.tag}
                                </span>
                              ))
                            : null}
                        </Link>
                      </TableCell>
                    ) : null}
                    {filteredManagers[0]?.hide === false ? (
                      <TableCell className="td td-4">
                        <Link
                          to={`/view-people?_id${subgroup._id}`}
                          onClick={() => {
                            setGeneral("selectedPeopleId", subgroup._id);
                            dispatch({
                              type: "UPDATEFIELD",
                              payload: subgroup.details,
                            });
                          }}
                          className="aUnset"
                        >
                          {subgroup.details[1].block[0].inputs[6].value}
                        </Link>
                      </TableCell>
                    ) : null}
                    {filteredWorkType[0]?.hide == false ? (
                      <TableCell className="td td-5">
                        <Link
                          to={`/view-people?_id${subgroup._id}`}
                          onClick={() => {
                            setGeneral("selectedPeopleId", subgroup._id);
                            dispatch({
                              type: "UPDATEFIELD",
                              payload: subgroup.details,
                            });
                          }}
                          className="aUnset"
                        >
                          {subgroup.details[1].block[0].inputs[3].value}
                        </Link>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Flo1Layout>
    </>
  );
}

export default Manage;
