import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Addpeople from "../assets/img/icon/addperson.svg";
import employeeImg from "../assets/img/img/employee.svg";
import collaboratorImg from "../assets/img/img/collaborator.svg";
import clientImg from "../assets/img/img/client.svg";
import AddPeopleModelCat from "../Screens/AddPeopleModelCat";
import AddPeopleModelInvite from "../Screens/AddPeopleModelInvite";
import { unset } from "lodash";
import Grid from "@mui/material/Grid";

export default function AddPeopleModel() {
  const [open, setOpen] = React.useState(false);
  const [selectedVal, setSelectedVal] = React.useState();
  const [stageNo, setStageNo] = React.useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div
        onClick={handleOpen}
        className="btn-outline-block text-p mt-0"
        style={{ height: 35,scale:0.9 }}
      >
        <img
          src={Addpeople}
          style={{ height: "20px", width: "20px", marginRight: "6px" }}
        />
        Add
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {stageNo == 1 ? (
          <AddPeopleModelCat
            setSelectedVal={(e) => setSelectedVal(e)}
            selectedVal={selectedVal}
            handleClose={handleClose}
            nextStage={(e) => setStageNo(e)}
          />
        ) : (
          <AddPeopleModelInvite
            selectedVal={selectedVal}
            handleClose={handleClose}
            nextStage={(e) => setStageNo(e)}
          />
        )}
      </Modal>
    </div>
  );
}
