import changeNumber from "./peopleReducer";
import onboardingReducer from "./OnboardingReducer";
import userReducer from "./userReducer";
import attendenceReducer from "./attendenceReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  onboardingReducer,
  changeNumber,
  userReducer,
  attendenceReducer,
});

export default rootReducer;
