import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo/Logo.svg";
import Input from "../Components/input";
import "../App.css";
import { Checkbox, Button } from "@mui/material";
import arrow from "../assets/img/icon/Arrow.svg";
import google from "../assets/img/icon/google.svg";
import outlook from "../assets/img/icon/outlook.svg";
import eyeslash from "../assets/img/icon/eyeslash.svg";
import eye from "../assets/img/icon/eye.svg";
import check from "../assets/img/icon/check.svg";
import alert from "../assets/img/icon/alert.svg";
import LoaderComp from "../Components/Loader";

import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import { SignIn, sendOTP, isEmailExistCall } from "../helper/index";
import { useSelector, useDispatch } from "react-redux";

function Signup() {
  let navigate = useNavigate();
  let buttonref = useRef();
  const OnboardingReducer = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(true);
  const [body, setbody] = useState({
    name: OnboardingReducer?.yourName,
    email: OnboardingReducer?.email,
    companyName: OnboardingReducer?.company_name,
  });
  const [loader, setloader] = useState(false);

  useEffect(() => {
    dispatch({
      type: "Signup",
      payload: { name: "", email: "", companyName: "" },
    });
    dispatch({ type: "Signup", payload: body });
  }, [body]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        buttonref.current.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     METHODS                                    ||
  // ! ||--------------------------------------------------------------------------------||
  const validateFields = () => {
    if (
      body.name.trim() === "" ||
      body.email.trim() === "" ||
      body.companyName.trim() === ""
    ) {
      toastWarning("Please fill in all fields before proceeding.");
      return false;
    }
    return true;
  };
  const isEmailExist = async () => {
    if (!validateFields()) return;
    setloader(true);
    isEmailExistCall({ email: body.email, companyName: body.companyName }).then(
      async (res) => {
        if (res.data.status == "success") {
          console.log(typeof res.data.data.isEmailExist);
          if (
            res.data.data.isEmailExist > 0 ||
            res.data.data.isCompanyExist > 0
          ) {
            res.data.data.message.forEach((msg) => {
              toastWarning(msg);
            });
          } else {
            let otp = await sendOTP(body.email);
            if (otp.data.status) {
              setloader(false);
              navigate(`/verify-email/${body.email}`);
            }
          }
        } else {
          setloader(false);
          toastWarning(res.data.data.message);
        }
      }
    );
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   GET STARTED                                  ||
  // ! ||--------------------------------------------------------------------------------||

  return (
    <div className="signup bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading">Great! Lets get you started</h4>
      <div className="h-center">
        <div>
          <div className="board-white">
            {/* <h5 className="text-center mt-0 CentraNo2-l">
              Just need a few things to get you going
            </h5> */}
            <div className="jsb">
              <Button variant="text" className="signup_google">
                <div className="">
                  <h5 className="CentraNo2 v-center m-0">
                    Sign up with{" "}
                    <img src={google} style={{ marginLeft: 5, width: 25 }} />
                  </h5>
                </div>
              </Button>
              <Button variant="text" className="signup_outlook">
                <h5 className="CentraNo2 v-center m-0">
                  Sign up with{" "}
                  <img src={outlook} style={{ marginLeft: 5, width: 25 }} />
                </h5>
              </Button>
            </div>
            <div className="jsb v-center" style={{ margin: "15px 0px" }}>
              <div className="border-bottom" style={{ width: "45%" }}></div>
              <span style={{ fontSize: 13 }}>or</span>
              <div className="border-bottom" style={{ width: "45%" }}></div>
            </div>

            <div style={{ marginTop: 15 }}>
              <Input
                getValue={(e) => {
                  setbody({ ...body, name: e });
                }}
                value={OnboardingReducer.yourName}
                minWidth={350}
                placeholder=""
                label="Your Name"
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <div className="p-relative">
                <Input
                  value={OnboardingReducer.email}
                  getValue={(e) => setbody({ ...body, email: e })}
                  minWidth={350}
                  placeholder=""
                  label="Email"
                />
              </div>
            </div>
            <div style={{ marginTop: 15 }}>
              <div className="p-relative">
                <Input
                  value={OnboardingReducer.company_name}
                  getValue={(e) => setbody({ ...body, companyName: e })}
                  minWidth={350}
                  placeholder=""
                  label="Company Name"
                />
              </div>
            </div>
            {/* <p className="h-center CentraNo2 already_use_flo alert">
              <img src={alert} className="alert-icon" /> The password you are
              trying to enter is incorrect
            </p> */}

            <Button
              ref={buttonref}
              sx={{ marginTop: 2 }}
              variant="contained"
              onClick={() => !loader && isEmailExist()}
              className="btn-block btn_text"
            >
              <LoaderComp loader={loader}>Next</LoaderComp>
            </Button>

            <p className="h-center CentraNo2 already_use_flo">
              Already use flo?{" "}
              <Link to="/login" style={{ paddingLeft: 5 }}>
                Sign in here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
