const initialState = {
  yourName: "",
  email: "",
  company_name: "",
  password: "",
  no_emp: "01-10",
  how_did_hear_you_about_us: "social_media",
};

const OnboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Signup":
      console.log("Signup...:", action.payload);
      return {
        ...state,
        yourName:
          action.payload.name,
        email: action.payload.email,
        company_name:
          action.payload.companyName
      };
      break;
    case "SetPass":
      console.log("SetPass...:", action.payload);
      return {
        ...state,
        password:
          action.payload.password != ""
            ? action.payload.password
            : state.password,
      };
      break;
    case "ResetOnboarding":
      console.log("ResetOnboarding...:", action.payload);
      return {
        ...state,
        yourName: "",
        email: "",
        company_name: "",
        password: "",
      };
      break;
    case "CLEANONBOARDREDUCER":
      console.log("ResetOnboarding...:", action.payload);
      return {
        ...state,
        yourName: "",
        email: "",
        company_name: "",
        password: "",
        no_emp: "01-10",
        how_did_hear_you_about_us: "social_media",
      };
      break;

    default:
      return state;
  }
};

export default OnboardingReducer;
