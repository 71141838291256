import React, { forwardRef, useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // IMPERATIVE HENDELER
  useImperativeHandle(ref, () => ({
    handleClickOpen,
    handleClose,
  }));

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="CentraNo2-m">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            className="CentraNo2"
            id="alert-dialog-slide-description"
          >
            {props.desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-mini-block btn-mini-inv mt-0"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-mini-block text-white mt-0 "
            onClick={() => props.confirm()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default ConfirmationDialog;
