const CalculateFormula = (val, type = 1) => {
  try {
    const salary = 30000;
    let formula = val;
    const formulaExecuted = eval(formula.replace("salary", salary));

    console.log("formulaExecuted:", formulaExecuted);
    if (!formulaExecuted) {
      if (type == 0) {
        return "Invalid formula";
      }

      console.log("Invalid formula");
    } else {
      if (formulaExecuted) {
        return formulaExecuted;
      }
      console.log("Executed Formula:", formulaExecuted);
    }
  } catch (error) {
    if (type == 0) {
      return error.message;
    }
    console.log("error: ", error.message);
  }
};
function IF(logic, trueValue, falseValue) {
  if (logic) {
    return trueValue;
  } else {
    return falseValue;
  }
}
function SUM(...args) {
  let total = 0;
  for (let value of args) {
    total += value;
  }
  return total;
}

export { CalculateFormula, IF, SUM };
