export const department = [
  {
    departmentTitle: "Human Resource",
  },
  {
    departmentTitle: "WebFlow Developers",
  },

  {
    departmentTitle: "Web Developer",
  },
  {
    departmentTitle: "Software Department",
  },
  // {
  //   departmentTitle: "SEO",
  // },
  // {
  //   departmentTitle: "Designers",
  // },
  // {
  //   departmentTitle: "Video Editors",
  // },
  // {
  //   departmentTitle: "Accounts",
  // },
];
