import * as React from "react";
import Box from "@mui/material/Box";
import employeeImg from "../assets/img/img/employee.svg";
import collaboratorImg from "../assets/img/img/collaborator.svg";
import clientImg from "../assets/img/img/client.svg";
import Grid from "@mui/material/Grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 3,
  borderRadius: 2,
  outline: "unset",
};

export default function AddPeopleModelCat(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    props.setSelectedVal("Employee");
  }, []);

  return (
    <Box sx={style}>
      <h1 className="fh1 text-center">Who are you inviting?</h1>
      <p className="fp text-center" style={{ marginBottom: 40 }}>
        First you’ll invite them to the account. Then you can add them to bases.
      </p>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={4}>
            <Box
              onClick={() => props.setSelectedVal("Employee")}
              className={`appPeopelModelBox ${
                props.selectedVal == "Employee" ? "activeAppPeopelModelBox" : ""
              }`}
            >
              <img width="100%" src={employeeImg} />
              <h1 className="fh2 text-center">Employee</h1>
              <p className="fp text-center">
                Only Buzz Interactive employees can create projects, invite
                others, and be admins.
              </p>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              // onClick={() => props.setSelectedVal("Collaborator")}
              className={`appPeopelModelBox flo-disable${
                props.selectedVal == "Collaborator"
                  ? "activeAppPeopelModelBox"
                  : ""
              }`}
            >
              <img width="100%" src={collaboratorImg} />
              <h1 className="fh2 text-center">Collaborator</h1>
              <p className="fp text-center">
                Collaborators can collaborate but can't create projects or
                invite others.
              </p>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              // onClick={() => props.setSelectedVal("Client")}
              className={`appPeopelModelBox flo-disable ${
                props.selectedVal == "Client" ? "activeAppPeopelModelBox" : ""
              }`}
            >
              <img width="100%" src={clientImg} />
              <h1 className="fh2 text-center">Client</h1>
              <p className="fp text-center">
                Clients can access projects but can't
                <br /> create or add people.
              </p>
            </Box>
          </Grid>
        </Grid>
        <Box className="f-end" style={{ marginTop: 30 }}>
          <div
            onClick={() => props.handleClose()}
            className="btn-block btn-mini-inv mt-0"
            style={{ height: 38, maxWidth: 100 }}
          >
            Cancel
          </div>
          <div
            onClick={() => props.nextStage(2)}
            className="btn-block btn_text mt-0"
            style={{ height: 38, maxWidth: 100 }}
          >
            Next
          </div>
        </Box>
      </Box>
    </Box>
  );
}
