import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import logoPhone from "../../../assets/img/logo/logo_circle.svg";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "../../../assets/img/icon/home.svg";
import workLoadIcon from "../../../assets/img/icon/workload.svg";
import manageIcon from "../../../assets/img/icon/manageNew.svg";
import Typography from "@mui/material/Typography";
import logo from "../../../assets/img/logo/flo.svg";
import notificationsIcon from "../../../assets/img/icon/notification.svg";
import settingIcon from "../../../assets/img/icon/setting.svg";
import profileIcon from "../../../assets/img/icon/temp.svg";
import FloHeader from "../../../Components/layouts/Layout2/floHeader";
import FloToolbar from "../../../Components/toolbar";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const sideItems = [
    {
      title: "Home",
      icon: HomeIcon,
    },
    {
      title: "Workload",
      icon: workLoadIcon,
    },
  ];
  const sidebarItems = [
    {
      title: "Notifications",
      icon: notificationsIcon,
    },
    {
      title: "Setting",
      icon: settingIcon,
    },
    {
      title: "Profile",
      icon: profileIcon,
    },
  ];
  const handleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1, overflow: "hidden" }}>
        <FloToolbar
          onGroupChange={(e) => props.onGroupChange(e)}
          setfields={(e) => props.setfields(e)}
          fields={props.fields}
        />
        {props.children}
      </Box>
    </Box>
  );
}
