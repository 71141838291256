import React, { useState, useEffect, useRef } from "react";
import Quill from "quill"; // import Quill library
import "quill/dist/quill.snow.css"; // import Quill CSS

function FormulaField(props) {
  const [quill, setQuill] = useState(null); // initialize quill object state variable
  const editorRef = useRef(); // initialize editor reference variable
  useEffect(() => {
    if (editorRef.current) {
      const editor = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: false,
        },
      });
      setQuill(editor);
    }
  }, []);

  useEffect(() => {
    if (quill) {
      let h = 0;
      // if quill object is available
      const highlightWord = (text) => {
        let regexValues = ["salary", "amount", "IF","SUM"];
        let regexValue = "";
        regexValues.forEach((val, i) => {
          if (regexValues.length > 0) {
            if (regexValues.length - 1 == i) {
              regexValue += `${val}`;
            } else {
              regexValue += `${val}|`;
            }
          } else {
            regexValue += `${val}`;
          }
        });
        console.log("regexValue:", regexValue);
        const Regex = new RegExp(`\\b(${regexValue})\\b`, "gi");

        // const Regex = /\b(salary|amount)\b/gi;
        const str = text;
        let m;

        while ((m = Regex.exec(str)) !== null) {
          console.log("Regex:",Regex.lastIndex);
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === Regex.lastIndex) {
            Regex.lastIndex++;
          }
          // The result can be accessed through the `m`-variable.
          m.forEach((match, groupIndex) => {
            if (Regex.lastIndex > h) {
              // h=Regex.lastIndex;
            }

            if (match == "IF") {
              quill.formatText(m.index, Regex.lastIndex, {
                color: "#00921d",
                bold: true,
              });
            }if (match == "SUM") {
              quill.formatText(m.index, Regex.lastIndex, {
                color: "#00921d",
                bold: true,
              });
            } else {
              quill.formatText(m.index, Regex.lastIndex, {
                color: "#ed604b",
                bold: true,
              });
            }

            quill.formatText(
              Regex.lastIndex > h ? Regex.lastIndex : h,
              text.length,
              {
                color: "black",
                bold: false,
              }
            );
            console.log(
              `Found match, group ${groupIndex}: ${match}, Start:${m.index}, End:${Regex.lastIndex}, h:${h}`
            );
            
          });
        }
      };
      quill.on("text-change", (delta, oldDelta, source) => {
        if (source === "user") {
          // if the text was changed by the user
          props.onChange(quill.getText());
          highlightWord(quill.getText()); // call the highlightWord function on the current text in the editor
        }
      });
    }
  }, [quill]);
  return (
    <div className="App">
      <div className="editor-container" ref={editorRef}></div>
    </div>
  );
}

export default FormulaField;
