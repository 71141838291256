import React, { useState, useEffect } from "react";
import { Box, Paper, Grid, Divider, IconButton } from "@mui/material";
import _ from "lodash";
import editAddressIcon from "../assets/img/icon/Edit_address.svg";
import deleteAddressIcon from "../assets/img/icon/deleteAddress.svg";
import FloSelect from "../Components/FloSelect";
import DatePicker from "../Components/DatePicker";
import FloRadio from "../Components/FloRadio";
import FloTags from "../Components/Tags";
export default function SubFormFactory(props) {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 DEFINED STATUES                                ||
  // ! ||--------------------------------------------------------------------------------||
  const [editBlockForm, seteditBlockForm] = useState(false);
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  const blockformFactory = (input, block, j) => {
    return (
      <Grid xs className="h100">
        <div className="Address--manage--CSS address--text">
          <div>{input.value}</div>
          <div
            style={{
              display:
                (block.inputs.length <= 3 ? block.inputs.length - 1 : 2) == j
                  ? "flex"
                  : "none",
              paddingLeft: 10,
            }}
          >
            <IconButton
              onClick={() => seteditBlockForm(true)}
              className="address--button--padding"
            >
              <img src={editAddressIcon} />
            </IconButton>
            <IconButton className="address--button--padding">
              <img src={deleteAddressIcon} />
            </IconButton>
          </div>
        </div>
      </Grid>
    );
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  let no = 0;
  return (
    <Grid container spacing={2} className={`${props.block.blockType}`}>
      {props.block.inputs.map((input, j) => {
        no++;
        let sum = props.block.inputs.map((o) => {
          return o.length;
        });
        sum = sum.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        return props.block.blockType == "collapseBlock" ? (
          editBlockForm ? (
            <>
              <Grid
                xs={
                  props.block.blockType == "collapseBlock" && sum > 12
                    ? editBlockForm
                      ? input.length
                      : 4
                    : input.length
                }
                key={j}
              >
                <div
                  className={`${
                    props.block.blockType != "collapseBlock"
                      ? "floInputContainer"
                      : editBlockForm == true
                      ? "floInputContainer"
                      : null
                  } ${
                    !editBlockForm &&
                    props.block.blockType == "collapseBlock" &&
                    no <= 2
                      ? "blockBorderRgith"
                      : null == j
                  }`}
                  style={{ height: "100%" }}
                >
                  {props.block.blockType == "collapseBlock" && !editBlockForm
                    ? blockformFactory(input, props.block, j)
                    : props.formFactory(input, {
                        blockName: props.block.blockName,
                        blockType: props.block.blockType,
                        tabName: props.tabName,
                      })}
                </div>
              </Grid>
              {/* collabsed block */}
            </>
          ) : no <= 3 ? (
            <>
              <Grid
                xs={
                  props.block.blockType == "collapseBlock" && sum > 12
                    ? 4
                    : input.length
                }
                key={j}
              >
                <div
                  className={`${
                    props.block.blockType != "collapseBlock"
                      ? "floInputContainer"
                      : editBlockForm == true
                      ? "floInputContainer"
                      : null
                  } ${
                    !editBlockForm &&
                    props.block.blockType == "collapseBlock" &&
                    props.block.inputs.length <= 2
                      ? no <= 1
                        ? "blockBorderRgith"
                        : null
                      : no <= 2
                      ? "blockBorderRgith"
                      : null
                  }`}
                  style={{ height: "100%" }}
                >
                  {props.block.blockType == "collapseBlock" && !editBlockForm
                    ? blockformFactory(input, props.block, j)
                    : props.formFactory(input, {
                        blockName: props.block.blockName,
                        blockType: props.block.blockType,
                      })}
                </div>
              </Grid>
              {/* collabsed block */}
            </>
          ) : null
        ) : (
          <>
            <Grid
              xs={
                props.block.blockType == "collapseBlock" && sum > 12
                  ? 4
                  : input.length
              }
              key={j}
            >
              <div
                className={`${
                  props.block.blockType != "collapseBlock"
                    ? "floInputContainer"
                    : editBlockForm == true
                    ? "floInputContainer"
                    : null
                } ${
                  !editBlockForm &&
                  props.block.blockType == "collapseBlock" &&
                  no <= 2
                    ? "blockBorderRgith"
                    : null == j
                }`}
                style={{ height: "100%" }}
              >
                {props.block.blockType == "collapseBlock" && !editBlockForm
                  ? blockformFactory(input, props.block, j)
                  : props.formFactory(input, {
                      blockName: props.block.blockName,
                      blockType: props.block.blockType,
                    })}
              </div>
            </Grid>
            {/* collabsed block */}
          </>
        );
      })}

      <div
        className={`floInputContainer jc-r ${
          editBlockForm && props.block.blockType == "collapseBlock"
            ? "d-flex"
            : "d-none"
        }`}
        style={{ width: "100%" }}
      >
        <Box sx={{ width: 80 }}>
          <div
            onClick={() => seteditBlockForm(false)}
            className="btn-block btn_text mt-0"
            style={{ height: 38 }}
          >
            Save
          </div>
        </Box>
        <Box sx={{ width: 80 }}>
          <div
            onClick={() => seteditBlockForm(false)}
            className="btn-block btn_text btn_text_invert mt-0"
            style={{ height: 38, marginLeft: 10 }}
          >
            Cancel
          </div>
        </Box>
      </div>
    </Grid>
  );
}
