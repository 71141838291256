export const ApproveEmployeData = [
  {
    EmployeeName: "Waqas Khan",
    role: "Seo Manager",
  },
  {
    EmployeeName: "Usman Khan",
    role: "Media Buyer",
  },

  {
    EmployeeName: "Momina Lala",
    role: "Project Manager",
  },
  {
    EmployeeName: "Ahsan Khan",
    role: "Designer",
  },
  {
    EmployeeName: "Shakeel khan",
    role: "Developer",
  },
  {
    EmployeeName: "Waqas khan",
    role: "SEO Manager ",
  },
  {
    EmployeeName: "Usman Khan",
    role: "UI/UX Designer",
  },
  
];
