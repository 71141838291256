import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Input from "./input";
import moment from "moment";
import { GrantAccess } from "../utilities/General";
import { useSelector, useDispatch } from "react-redux";

export default function FloDatePicker(props) {
  const dispatch = useDispatch();
  const PeopleReducer = useSelector((state) => state.changeNumber);
  const datePickerRef = useRef(null);
  const [open, setopen] = useState(false);
  const [grantAccess, setGrantAccess] = useState(false);
  const [disabledComp, setDisabledComp] = useState(false);

  const [data, setdata] = useState(
    props.data.length > 0 ? [...props.data] : []
  );
  const [value, setvalue] = useState(props.value ? props.value : "");



  const search = (e) => {
    if (props.data.length > 0) {
      let obj = props?.data?.filter((o) => {
        console.log("props.keyValue:", (o[props.keyValue.toLowerCase()] = e));
        return (o[props.keyValue.toLowerCase()] = e);
      });
      console.log("obj:", obj);
      console.log("e:", e);
      if (e == "") {
        console.log("props.data:", props.data);
        setdata(obj);
      } else {
        setdata(obj);
      }
    }
  };
  return (
    <section>
      <div
        className={`floSelectMenuOverlay ${
          open && props.data.length > 0 ? "d-block" : "d-none"
        }`}
        onClick={() => setopen(false)}
      ></div>
      <div className="v-hidden p-absolute">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["StaticDatePicker"]}>
            <DemoItem label="Mobile variant">
              <MobileDatePicker
                ref={datePickerRef}
                format="MMMM DD YY"
                onChange={(e) => {
                  console.log(e.$d);
                  search(e.$d);
                  if (props.type) {
                    props?.getValue(moment(e.$d).format("MMMM Do YY"));
                  }
                  setvalue(moment(e.$d).format("MMMM Do YY"));
                }}
                defaultValue={dayjs()}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </div>

      <div className="p-relative">
        <div
          onClick={() => {
            datePickerRef.current.click();
          }}
        >
          <Input
            key={value}
            value={value}
            getValue={(e) => {
              setopen(true);
              search(e);
              props?.getValue(e);
            }}
            disabled={disabledComp}
            placeholder={props.placeholder}
            label={props.label}
          />
          {/* ARROW */}
          {props.data.length > 0 ? (
            <svg
              className="floSelectArrowup"
              width="11"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.625 1L7 6.625L1.375 1"
                stroke="#696969"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : null}

          {/* TIMES */}
          {value ? (
            <svg
              onClick={() => {
                setvalue("");
                search("");
              }}
              className="floSelectTimes"
              width="8"
              height="8"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5"
                stroke="#696969"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : null}
        </div>
      </div>
    </section>
  );
}
