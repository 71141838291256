import React, { forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Addpeople from "../assets/img/icon/addperson.svg";
import employeeImg from "../assets/img/img/employee.svg";
import collaboratorImg from "../assets/img/img/collaborator.svg";
import clientImg from "../assets/img/img/client.svg";
import RequestTimeoffForm from "../Screens/RequestTimeoffForm";
import { unset } from "lodash";
import Grid from "@mui/material/Grid";
import LeaveRequestHook from "../utilities/LeaveRequestHook";

const AddPeopleModel = forwardRef((props, ref) => {
  const { leaveRequest, setLeaveRequest, reset: resetLR } = LeaveRequestHook();
  const [open, setOpen] = React.useState(false);
  const [editState, seteditState] = React.useState(0);
  const [stageNo, setStageNo] = React.useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   USE EFFECT                                   ||
  // ! ||--------------------------------------------------------------------------------||
  // IMPERATIVE HENDELER
  useImperativeHandle(ref, () => ({
    handleOpen,
  }));
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                     RETURN                                     ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <div>
      <div
        onClick={() => {
          handleOpen();
          resetLR();
        }}
      >
        <Box className="btn-mini-block btn_text">Request Timeoff</Box>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RequestTimeoffForm
          handleClose={() => {
            handleClose();
            props.reload();
          }}
          nextStage={(e) => setStageNo(e)}
        />
      </Modal>
    </div>
  );
});

export default AddPeopleModel;
