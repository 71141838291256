import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/img/logo/Logo.svg";
import Input from "../Components/input";
import "../App.css";
import { Checkbox } from "@mui/material";
import arrow from "../assets/img/icon/arrow-p.svg";
import google from "../assets/img/icon/google.svg";
import outlook from "../assets/img/icon/outlook.svg";
import eyeslash from "../assets/img/icon/eyeslash.svg";
import check from "../assets/img/icon/check.svg";
import alert from "../assets/img/icon/alert.svg";

function CheckEmail() {
  const location = useLocation();
  const [mail, setmail] = useState("");

  useEffect(() => {
    setmail(location.state.email.split("@")[1]);
    console.log(location.state.email.split("@")[1]);
  }, []);
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 OPEN EMAIL APP                                 ||
  // ! ||--------------------------------------------------------------------------------||
  const OpenEmailApp = () => {
    switch (mail) {
      case "gmail.com":
        return "https://mail.google.com/";
        break;
      case "yahoo.com":
        return "https://mail.yahoo.com/";
        break;
      case "hotmail.com":
        return "https://outlook.live.com/";
        break;
      default:
        return "https://outlook.live.com/";
        break;
    }
  };
  return (
    <div className="signup bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading">Forgot Password?</h4>
      <div className="h-center">
        <div>
          <div className="board-white">
            <p className="board-white-p">
              We sent a password reset link to
              <br />
              <b style={{ color: "black", fontSize: 14 }}>
                {location.state.email}
              </b>
            </p>

            <a
              // to="/reset-password"
              href={OpenEmailApp()}
              target="_blank"
              className="btn-block btn_text"
              variant="contained"
            >
              Open email app
            </a>

            <p className="h-center CentraNo2 already_use_flo">
              <Link
                to="/login"
                className="v-center"
                style={{ paddingLeft: 5, textDecoration: "none" }}
              >
                <img src={arrow} />{" "}
                <span style={{ color: "black", paddingLeft: 10 }}>
                  Back to login
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckEmail;
