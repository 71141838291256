import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import SetPassword from "./Screens/SetPassword";
import Signup from "./Screens/Signup";
import Login from "./Screens/Login";
import CompanyDetails from "./Screens/CompanyDetails";
import ForgotPassword from "./Screens/ForgotPassword";
import EmailVerification from "./Screens/EmailVerification";
import CheckEmail from "./Screens/CheckEmail";
import ReSetPassword from "./Screens/ReSetPassword";
import Manage from "./Screens/Manage";
import PeopleDetails from "./Screens/PeopleDetails";
import Setting from "./Screens/Settings/index";
import RolePermissions from "./Screens/Settings/RolePermissions";
import AllRoles from "./Screens/Settings/AllRoles";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import FloLayout from "./Components/layouts/flo";
import FormulaField from "./Components/formulaField";
import DND from "./Components/dnd1";
import NotFound from "./Screens/NotFound";
import AttendenceReport from "./Screens/AttendenceReport";
import ApproveTimeOff from "./Screens/ApproveTimeOff";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AttendenceTracking from "./Components/AttendenceTracking";
import TrackingAttendenceNew from "./TrackingAttendenceNew";
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen /> */}
        <div>
          <React.StrictMode>
            <BrowserRouter>
              <Routes>
                <Route element={<FloLayout />}>
                  <Route
                    path="attendence-report"
                    element={<AttendenceReport />}
                  />
                  <Route path="timeoff-request" element={<ApproveTimeOff />} />
                  <Route path="view-people/:id?" element={<PeopleDetails />} />
                  <Route path="/FormulaField" element={<FormulaField />} />
                  <Route path="manage" element={<Manage />} />
                  <Route element={<Setting />}>
                    <Route
                      path="/role-permissions/:role_id?"
                      element={<RolePermissions />}
                    />
                    <Route path="/all-roles" element={<AllRoles />} />
                    <Route path="/create-role" element={<RolePermissions />} />
                  </Route>
                </Route>
                {/* <Route path="/" element={<ComingSoon/>} /> */}
                <Route index element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="login" element={<Login />} />
                <Route path="set-password" element={<SetPassword />} />
                <Route path="company-details" element={<CompanyDetails />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="check-email" element={<CheckEmail />} />
                <Route path="reset-password" element={<ReSetPassword />} />

                <Route path="dnd" element={<DND />} />
                <Route path="*" element={<NotFound />} />
                <Route
                  path="verify-email/:email"
                  element={<EmailVerification />}
                />
              </Routes>
            </BrowserRouter>
          </React.StrictMode>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitxals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
