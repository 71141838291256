import { useEffect, useState } from "react";
import logo from "../assets/img/logo/Logo.svg";
import Input from "../Components/input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import { Button } from "@mui/material";
import arrow from "../assets/img/icon/Arrow.svg";
import google from "../assets/img/icon/google.svg";
import eyeslash from "../assets/img/icon/eyeslash.svg";
import check from "../assets/img/icon/check.svg";
import invalid from "../assets/img/icon/invalid.svg";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import { ResetPass } from "../helper/index";

function ReSetPassword(props) {
  const [showPass, setShowPass] = useState(true);
  const [character, setcharacter] = useState(false);
  const [numaric, setnumaric] = useState(false);
  const [length8, setlength8] = useState(false);
  const [body, setbody] = useState({
    data: {
      password: "1",
      cpassword: "",
    },
    header: "",
  });

  const [passToken, setpassToken] = useState("");
  let location = useLocation();
  let navigate = useNavigate();

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   USE EFFECTS                                  ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let token = params.get("token");
    let tkn = token.split("_buzz");
    setbody({ ...body, header: tkn[1] });
  }, []);

  useEffect(() => {
    // console.log("character: ", character);
    // console.log("numaric: ", numaric);
    // console.log("length8: ", length8);
    console.log("body: ", body);
  }, [character, numaric, length8, body]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                CHECK VALIDATION                                ||
  // ! ||--------------------------------------------------------------------------------||
  const checkValidation = (e, type) => {
    // CHECK CHARACTER VALIDATION
    var characterReg = /[a-zA-Z]/g;
    var numaricReg = /\d/g;

    if (type == "pass") {
      setbody({ ...body, data: { ...body.data, password: e } });
    } else if (type == "cpass") {
      setbody({ ...body, data: { ...body.data, cpassword: e } });
    }

    if (characterReg.test(e)) {
      console.log(1);
      setcharacter(true);
    } else if (!characterReg.test(e)) {
      console.log(2);
      setcharacter(false);
    }

    if (numaricReg.test(e)) {
      console.log(3);
      setnumaric(true);
    } else if (!numaricReg.test(e)) {
      console.log(4);
      setnumaric(false);
    }

    if (e.length >= 8) {
      console.log(5);
      setlength8(true);
    } else {
      console.log(6);
      setlength8(false);
    }
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 RESET PASSWORD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  const ResetPassword = () => {
    if (body.data.password == body.data.cpassword) {
      ResetPass(body).then((res) => {
        if (res.data.status == "error") {
          toastError(res.data.data.message);
        } else if ((res.data.status = "success")) {
          toastSuccess("Password reset successfully!");
          navigate("/login");
          console.log("res", res.data);
        }
      });
    } else {
      toastError("Password didn't match");
    }
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 RETURN FUNCTION                                ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <div className="signup bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading">Set new password</h4>
      <div className="h-center">
        <div>
          <div className="board-white">
            <p className="board-white-p">
              The new password must be different from
              <br /> the previously used passwords
            </p>
            <div className="p-relative">
              <Input
                getValue={(e) => checkValidation(e, "pass")}
                password={showPass}
                minWidth={350}
                placeholder="*******"
                label="Password"
              />
              {showPass ? (
                <img
                  onClick={() => setShowPass(!showPass)}
                  src={eyeslash}
                  className="p-absolute eyetoggle"
                />
              ) : (
                <img
                  onClick={() => setShowPass(!showPass)}
                  src={check}
                  className="p-absolute eyetoggle"
                />
              )}
            </div>
            <div className="p-relative" style={{ marginTop: 10 }}>
              <Input
                getValue={(e) => checkValidation(e, "cpass")}
                password={showPass}
                minWidth={350}
                placeholder="*******"
                label="Confirm Password"
              />
              {showPass ? (
                <img
                  onClick={() => setShowPass(!showPass)}
                  src={eyeslash}
                  className="p-absolute eyetoggle"
                />
              ) : (
                <img
                  onClick={() => setShowPass(!showPass)}
                  src={check}
                  className="p-absolute eyetoggle"
                />
              )}
            </div>
            <p className="CentraNo2 strong_pass">
              Password Strength: <span className="CentraNo2-m">Strong</span>
            </p>
            <p
              className={`CentraNo2 ${
                length8 ? "text-s" : "text-p"
              } strong_pass_check v-center`}
            >
              <img
                src={length8 ? check : invalid}
                style={{ marginRight: 10, width: 14 }}
              />{" "}
              Use 8 or more characters.
            </p>
            <p
              className={`CentraNo2 ${
                character ? "text-s" : "text-p"
              } strong_pass_check v-center`}
            >
              <img
                src={character ? check : invalid}
                style={{ marginRight: 10, width: 14 }}
              />{" "}
              Use a minimum of one letter.
            </p>
            <p
              className={`CentraNo2 ${
                numaric ? "text-s" : "text-p"
              } strong_pass_check v-center`}
              style={{ marginBottom: 10 }}
            >
              <img
                src={numaric ? check : invalid}
                style={{ marginRight: 10, width: 14 }}
              />{" "}
              Use a minimum of one number.
            </p>

            <div
              onClick={() => ResetPassword()}
              className="btn-block btn_text"
              variant="contained"
            >
              Reset Password
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReSetPassword;
