import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo/Logo.svg";
import Input from "../Components/input";
import "../App.css";
import { Checkbox } from "@mui/material";
import arrow from "../assets/img/icon/arrow-p.svg";
import google from "../assets/img/icon/google.svg";
import outlook from "../assets/img/icon/outlook.svg";
import eyeslash from "../assets/img/icon/eyeslash.svg";
import check from "../assets/img/icon/check.svg";
import alert from "../assets/img/icon/alert.svg";
import OtpInput from "react-otp-input";
import { useParams } from "react-router-dom";
import LoaderComp from "../Components/Loader";

import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";
import { verifyOTP, sendOTP } from "../helper/index";
function EmailVerification(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [body, setbody] = useState({
    email: "",
  });
  const [otp, setOtp] = useState("");
  const [loader, setloader] = useState(false);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 FORGOT PASSWORD                                ||
  // ! ||--------------------------------------------------------------------------------||
  const hendleVerifyOTP = () => {
    setloader(true);
    verifyOTP({ email: params.email, otp: otp }).then((res) => {
      if (res.data.status == "error") {
        setloader(false);
        // toastError(res.data.data.message);
      } else if ((res.data.status = "success")) {
        if (res?.data?.data) {
          // toastSuccess(res.data.data.message);
          toastSuccess("OTP Verified");
          navigate("/set-password");
        } else {
          toastWarning("Incorrect OTP");
        }
        setloader(false);
      }
    });
  };
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 RETURN FUNCTION                                ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <div className="signup bg-sec">
      <div className="logo">
        <img src={logo} style={{ width: 90 }} />
      </div>
      <h4 className="h-center heading">Verify Email</h4>
      <div className="h-center">
        <div>
          <div className="board-white">
            <p className="board-white-p">
              Please enter you OTP code to proceed
            </p>

            <div style={{ marginTop: 15 }}>
              {/* <Input
                getValue={(e) => setbody({ ...body, email: e })}
                minWidth={350}
                placeholder="tom@gmail.com"
                label="Enter your email"
              /> */}

              <OtpInput
                containerStyle={{ justifyContent: "space-around" }}
                inputStyle={{
                  height: 50,
                  width: 50,
                  borderWidth: 1,
                  borderRadius: 5,
                  borderColor: "#6d6c6c54",
                  fontSize: 32,
                }}
                value={otp}
                onChange={setOtp}
                numInputs={4}
                shouldAutoFocus
                renderInput={(props) => <input class="otp-input" {...props} />}
              />
            </div>

            <div
              onClick={() => !loader && hendleVerifyOTP()}
              className="btn-block btn_text"
              variant="contained"
            >
              <LoaderComp loader={loader}>Verify</LoaderComp>
            </div>
            <p className="h-center CentraNo2 already_use_flo">
              Didn’t receive the email?{" "}
              <a
                onClick={async () => {
                  setloader(true);
                  sendOTP(params.email).then((e) => {
                    if (e?.data?.data?.status) {
                      toastSuccess("OTP Sended");
                    }
                    setloader(false);
                  });
                }}
                style={{ paddingLeft: 5 }}
              >
                Click to resend
              </a>
            </p>
            <p className="h-center CentraNo2 already_use_flo">
              <Link
                to="/login"
                className="v-center"
                style={{ paddingLeft: 5, textDecoration: "none" }}
              >
                <img src={arrow} />{" "}
                <span style={{ color: "black", paddingLeft: 10 }}>
                  Back to login
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
