import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { PanoramaPhotosphereSelect } from "@mui/icons-material";
import { useEffect } from "react";

export default function Input(props) {
  const [value, setvalue] = useState(props.value ? props.value : "");

  useEffect(() => {
    setvalue(props.value);
  }, [props]);

  const OnChageHeldler = (e) => {
    props.getValue(e.target.value);
    setvalue(e.target.value);
  };

  return (
    <div className={`flo-input ${props.multiline ? "flo-textarea" : null}`}>
      <TextField
        autoComplete="off"
        multiline={props.multiline ? true : false}
        label={props.label}
        placeholder={props.placeholder}
        value={value}
        disabled={props.disabled}
        variant="filled"
        rows={props.row ? props.row : 1}
        type={props.password ? "password" : "text"}
        style={{
          minWidth: props?.minWidth,
          maxWidth: props?.maxWidth,
          width: "100%",
          background: "white",
          overflow: "hidden",
        }}
        onBlur={(e) => {
          if (props.onBlur) {
            props.onBlur(e.target.value);
            setvalue(e.target.value);
          }
        }}
        onChange={(e) => {
          OnChageHeldler(e);
        }}
      />
    </div>
  );
}
