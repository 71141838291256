import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import search from "../assets/img/icon/search.svg";
import { SignalWifiStatusbarNullRounded } from "@mui/icons-material";

export default function InputAdornments(props) {
  const [data, setdata] = React.useState(false);

  

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
        <Input
          {...props}
          onChange={(e) => {
            e.preventDefault();
            props.getdata(e.target.value);
            setdata(e.target.value)
          }}
          id="filled-adornment-password"
          type={"text"}
          placeholder={props.label}
          autoComplete="off"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={()=>props.getdata(data)}
                edge="end"
              >
               {props.icon==true?<img
                  src={search}
                  style={{ width: 18, position: "relative", right: 4,marginBottom: 10 }}
                />:null}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}
