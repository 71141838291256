import React, { Fragment, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InGreen from "../assets/img/icon/in_green.svg";
import InGray from "../assets/img/icon/in_gray.png";
import OutRed from "../assets/img/icon/out_red.svg";
import OutGray from "../assets/img/icon/out_gray.svg";
import RequestTimeOff from "./RequestTimeOff";
import { getTimeTracking } from "../helper/index";
import useTokkenHook from "../utilities/TokkenHook";
import moment from "moment";
import GeneralHook from "../utilities/GeneralHook";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { Margin } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dots from "../assets/img/icon/dots.png";
import menu from "../assets/img/icon/menu.png";
import print from "../assets/img/icon/print.svg";
// import DatePicker from "../Components/DatePicker"
import FloSelect from "./FloSelect";
import LeaveRequestHook from "../utilities/LeaveRequestHook";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import FloMenu from "./floMenu";
import MenuItem from "@mui/material/MenuItem";
import assignbase from "../assets/img/icon/assignbase.svg";
import edit from "../assets/img/icon/edit.svg";
import archive from "../assets/img/icon/archive.svg";
import importIcon from "../assets/img/icon/Import.svg";

import deleteIcon from "../assets/img/icon/Delete.svg";
const statusBadge = (s) => {
  const status = {
    Logged: "approved_badge",
    Approved: "approved_badge",
    Pending: "pending_badge",
    Tracking: "tracking_badge",
    Holiday: "grayr_badge",
    Conflict: "pending_badge",
    Absent: "red_badge",
  };
  return status[s];
};
const astatusBadge = (s) => {
  const status = {
    On_Time: "green_badge",
    Holiday: "gray_badge",
    Late: "yellow_badge",
    Early: "blue_badge",
    Absent: "red_badge",
    WFH: "orange_badge",
    Leave: "purple_badge",
  };
  return status[s];
};

export default function AttendencePeopleTable() {
  const [rows, setrows] = useState([]);
  const { tokken, setTokken } = useTokkenHook();
  const { getGeneral, setGeneral } = GeneralHook();
  const trackingStage = getGeneral("attendenceReducer", "trackingStage");
  const selectedPeopleId = getGeneral("changeNumber", "fieldsBlockVariable");
  const attendenceReducer = useSelector((state) => state.attendenceReducer);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userDetails, setuserDetails] = React.useState({});
  const [summeryData, setSummeryData] = React.useState([
    {
      tittle: "0",
      Description: "Total logged Hours",
    },
    {
      tittle: "0",
      Description: "Total Leaves",
    },
    {
      tittle: "0",
      Description: "Absents",
    },
    {
      tittle: "0",
      Description: "Salary deduction",
    },
  ]);

  useEffect(() => {
    init();
  }, [
    trackingStage,
    selectedPeopleId,
    attendenceReducer.reload,
    fromDate,
    toDate,
  ]);

  const getDaysFomString = (string) => {
    let SalaryInfo = {
      Monthly: 30,
      Weekly: 7,
      Daily: 1,
      Hourly: 0.25,
    };
    return SalaryInfo[string];
  };

  const init = () => {
    const company_id = getGeneral("userReducer", "userDetails").company_id;
    const user_id = getGeneral("changeNumber", "selectedPeopleId");

    getTimeTracking(
      {
        user_id,
        company_id,
        start_date: moment(fromDate).format("YYYY-MM-DD"),
        end_date: moment(toDate).format("YYYY-MM-DD"),
      },
      tokken
    ).then((res) => {
      console.log("attendance_report", res?.data?.data?.attendance_report);
      if (res.data.status == "success") {
        let abs = res?.data?.data?.attendance_report?.filter(
          (attendence) => attendence.trackingStatus == "Absent"
        );
        let totalAbsents = abs.length;
        let salary =
          res?.data?.data?.attendance_report[0]?.user_details[0]?.details[1]
            ?.block[1]?.inputs[1]?.value;
        let salary_tenure = getDaysFomString(
          res?.data?.data?.attendance_report[0]?.user_details[0]?.details[1]
            ?.block[1]?.inputs[0]?.value
        );
        let salary_perday = salary / salary_tenure;
        salary_perday = parseFloat(salary_perday);
        let deductedSalary = (salary_perday * totalAbsents);

        summeryData[0].tittle =
          res?.data?.data?.attendance_report[0]?.loggedHours?.toFixed(1)
            ? res?.data?.data?.attendance_report[0]?.loggedHours?.toFixed(1)
            : 0;
        summeryData[1].tittle = res?.data?.data?.total_leaves[0]?.total_leaves
          ? res?.data?.data?.total_leaves[0]?.total_leaves
          : 0;
          console.log("deductedSalary",deductedSalary);
        summeryData[2].tittle = totalAbsents;
        summeryData[3].tittle = deductedSalary?deductedSalary.toFixed(2):0;
        setSummeryData(summeryData);
        setrows(res?.data?.data?.attendance_report);
        setuserDetails(res?.data?.data?.attendance_report[0]?.user_details);
      }
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "20px",
          borderBottom: "1px #E5E8E8 solid",
        }}
      >
        <div className="toolbar-attendenceReport">
          <div className="innerContainer-toolbar">
            <div className="calenderinner-con">
              <div>
                <CalendarMonthIcon className="calender-icon" />
              </div>
              <div className="date-content-con">
                {/* <div>
                  <h1 className="attendenceDate-p">From date</h1>
                </div> */}
                <div>
                  {/* <p className="attendenceDate-h1">01 Apr 2024</p> */}
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    className="new-pp"
                  >
                    <MobileDatePicker
                      className="mobile-date-piker"
                      label="From Date"
                      value={fromDate}
                      onChange={(newValue) => setFromDate(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="line-attendence"></div>
            <div className="calenderinner-con">
              <div>
                <CalendarMonthIcon className="calender-icon" />
              </div>
              <div className="date-content-con">
                {/* <div>
                  <h1 className="attendenceDate-p">To date</h1>
                </div> */}
                <div>
                  {/* <p className="attendenceDate-h1">21 Apr 2024</p> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      className="mobile-date-piker"
                      label="To Date"
                      value={toDate}
                      onChange={(newValue) => setToDate(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="dropdownAttendence-icon">
              {/* {First menu icon} */}

              <img src={menu} style={{ margin: "20px" }} />
              {/* second menu icon start*/}

              <FloMenu
                menu={
                  <>
                    <img src={dots} />
                  </>
                }
              >
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={assignbase} />
                  <span className="manageMenuLabel">Assign to base</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={edit} />
                  <span className="manageMenuLabel">Edit</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={archive} />
                  <span className="manageMenuLabel">Archive</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={importIcon} />
                  <span className="manageMenuLabel">Import</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={importIcon} />
                  <span className="manageMenuLabel">
                    Export (.CSV for Excel)
                  </span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={print} />
                  <span className="manageMenuLabel">Print</span>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <img className="manageMenuImg" src={deleteIcon} />
                  <span className="manageMenuLabel">Delete</span>
                </MenuItem>
              </FloMenu>

              {/* second menu icon end*/}
            </div>
          </div>
        </div>
      </div>
      <Grid container sx={{ paddingX: 1 }}>
        {summeryData.map((item, index) => (
          <>
            <Grid item xs={3} key={index}>
              <Box sx={{ margin: 1 }} className="leaveSummeryContainer ">
                <div className="fh3">
                  {parseFloat(item.tittle).toLocaleString()}
                </div>
                <div className="fp">{item.Description}</div>
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell className="CentraNo2-m">Date</TableCell>
              <TableCell className="CentraNo2-m">In</TableCell>
              <TableCell className="CentraNo2-m">Out</TableCell>
              <TableCell className="CentraNo2-m">Hours</TableCell>
              <TableCell className="CentraNo2-m">Status</TableCell>
              <TableCell className="CentraNo2-m">Manager</TableCell>
              {/* <TableCell className="CentraNo2-m"></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((attendence, i) => {
              console.log(attendence.trackingData);
              let trackingStatus = attendence.trackingStatus;
              return (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* ATTENDENCE DATE */}
                  <TableCell
                    className="f-12 CentraNo2-m"
                    component="th"
                    scope="row"
                  >
                    {moment(attendence.checkin).format("dddd Do MMM")}
                  </TableCell>
                  {/* CHECK IN */}
                  <TableCell className="f-12 CentraNo2">
                    <img
                      className="inoutImg"
                      src={
                        attendence?.checkin && trackingStatus != "Absent"
                          ? InGreen
                          : InGray
                      }
                    />{" "}
                    {attendence?.checkin && trackingStatus != "Absent"
                      ? moment(attendence?.checkin)
                          .subtract(5, "hours")
                          .format("h:mm a")
                      : "00:00 am"}
                  </TableCell>
                  {/* CHECK OUT */}
                  <TableCell className="f-12 CentraNo2">
                    <img
                      className="inoutImg"
                      src={
                        attendence?.checkout && trackingStatus != "Absent"
                          ? OutRed
                          : InGray
                      }
                    />{" "}
                    {attendence?.checkout && trackingStatus != "Absent"
                      ? moment(attendence?.lastCheckout)
                          .subtract(5, "hours")
                          .format("h:mm a")
                      : "00:00 pm"}
                  </TableCell>
                  {/* LOGED HOURS */}
                  <TableCell className="f-12 CentraNo2">
                    {attendence?.totalHours
                      ? attendence?.totalHours.toFixed(1)
                      : 0}{" "}
                    hrs
                  </TableCell>
                  {/* STATUS BADGE */}
                  <TableCell>
                    <span
                      style={{ textTransform: "capitalize" }}
                      className={`${statusBadge(trackingStatus)} ${
                        attendence.STATUS == "Holiday" ? "d-none" : null
                      }`}
                    >
                      {trackingStatus}
                    </span>
                  </TableCell>
                  {/* MANAGER NAME */}
                  <TableCell className="f-12 CentraNo2">
                    {" "}
                    <span className={`grayr_badge`}>
                      {
                        attendence?.user_details[0]?.details[1]?.block[0]
                          ?.inputs[6].value
                      }
                    </span>
                  </TableCell>
                  {/* TIMETRACKING TAG */}
                  {/* <TableCell>
                    <span
                      className={`green_badge capsul_badge ${astatusBadge(
                        attendence?.trackingData[0]?.leaveStatus
                          ? attendence?.trackingData[0]?.leaveStatus
                          : attendence?.trackingData[0]?.checkin
                          ? "On_Time"
                          : "Absent"
                      )}`}
                    >
                      {attendence?.trackingData[0]?.leaveStatus
                        ? attendence?.trackingData[0]?.leaveStatus
                        : attendence?.trackingData[0]?.checkin
                        ? "On Time"
                        : "Absent"}
                    </span>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
