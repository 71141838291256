import { render } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import { Box, Paper, Grid, Divider, IconButton } from "@mui/material";
import BaseName from "../Components/BaseName";
import ActiveMenu from "../Components/activeMenu";
import FloCollabCircle from "../Components/floCollabCircle";
import FloSelect from "../Components/FloSelect";
import DatePicker from "../Components/DatePicker";
import FloRadio from "../Components/FloRadio";
import FloTags from "../Components/Tags";
import FormFactoryComp from "../Components/FormFactory";
import _ from "lodash";
import editAddressIcon from "../assets/img/icon/Edit_address.svg";
import deleteAddressIcon from "../assets/img/icon/deleteAddress.svg";
import GeneralHook from "../utilities/GeneralHook";
import { GrantAccess } from "../utilities/General";
import ManagePeople from "../helper/ManagePeople";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  toastSuccess,
  toastInfo,
  toastWarning,
  toastError,
} from "../utilities/toast";

const PeopleDetails = () => {
  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                 DEFINED STATUES                                ||
  // ! ||--------------------------------------------------------------------------------||
  const { getPeople } = ManagePeople();
  const PeopleReducer = useSelector((state) => state.changeNumber);
  const userReducer = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const params = useParams();

  const [collabs, setcollabs] = useState([
    { name: "ali", id: "0", color: "#FFDDE1" },
    { name: "ali", id: "0", color: "#FFEEBF" },
    { name: "ali", id: "0", color: "#D2E1F0" },
    { name: "ali", id: "0", color: "#D9D6FA" },
  ]);
  const [checkedAll, setcheckedAll] = useState(false);
  const [fieldsBlock, setfieldsBlock] = useState([]);
  const [activeTab, setactiveTab] = useState("personal");
  const { getGeneral, setGeneral } = GeneralHook();
  const [people, setpeople] = useState(PeopleReducer.peoples);
  const [blockTabs, setblockTabs] = useState({
    personal: "show",
    work: "show",
    attendence: "show",
    timeoff: "show",
  });

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                               DEFINED USE EFFECTS                              ||
  // ! ||--------------------------------------------------------------------------------||
  useEffect(() => {
    setcollabs([...collabs, { name: "", id: "0", color: "#ffffff" }]);
    setfieldsBlock(PeopleReducer.fieldsBlockVariable);
    getPeoples();
  }, []);

  useEffect(() => {
    let permissions = PeopleReducer.peoples.filter((people) => {
      return people._id == PeopleReducer.selectedPeopleId;
    });
    let peoplePermission = permissions[0]?.externalField.filter(
      (permission) => {
        return permission.module == "people";
      }
    );
    if (peoplePermission && peoplePermission[0]?.description == "can_view_my_team_personal_info") {
      if (userReducer?.userDetails?._id == PeopleReducer?.selectedPeopleId) {
        setactiveTab("personal")
        setblockTabs({
          personal: "show",
          work: "show",
          attendence: "show",
          timeoff: "show",
        });
      } else {
        setactiveTab("attendence")
        setblockTabs({
          personal: "hide",
          work: "hide",
          attendence: "show",
          timeoff: "show",
        });
      }
    }
   
    // console.log(
    //   "permissions_________________________",
    //   peoplePermission[0].description
    // );
    // userReducer.userDetails._id
  }, [PeopleReducer.selectedPeopleId]);

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                   ALL METHODS                                  ||
  // ! ||--------------------------------------------------------------------------------||

  const replaceUser = (obj, users) => {
    return _.cloneDeepWith(obj, (value) => {
      if (_.isString(value) && value.includes("@people@")) {
        const userId = value.replace(/@user@/, "");
        return users;
      }
    });
  };

  const getPeoples = () => {
    getPeople({
      groupdata: [],
      company_id: userReducer.userDetails.company_id,
    }).then((res) => {
      console.log("asdfasdfafasdfasdf", res);
      if (res.data.status == "error") {
        toastError(res.data.data.message);
      } else if ((res.data.status = "success")) {
        if (res.data.data.length > 0) {
          let Data = res.data.data;
          console.log("Get People", Data);
          setpeople(res.data.data);
          dispatch({
            type: "UPDATEPEOPLE",
            payload: Data,
          });
        }
      }
    });
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                                  RENDER METHOD                                 ||
  // ! ||--------------------------------------------------------------------------------||
  return (
    <section className="PeopelDetaisComp">
      {AllPeopleLeft()}
      {PeopleBody()}
      {/* {Widgets()} */}
    </section>
  );

  function Widgets() {
    return <div className="PeopelDetaisRight"></div>;
  }

  function PeopleBody() {
    return (
      <div className="PeopelDetaisBody">
        <div className="jsb v-center" style={{ padding: 25, paddingBottom: 0 }}>
          {ProfileContainer()}
          {PeopleWhoCanAccess()}
        </div>
        {PeopleTabs()}
        {PeopleTabBody()}
      </div>
    );
  }

  function PeopleTabBody() {
    return (
      <div style={{ marginTop: 10 }}>
        {PeopleReducer.fieldsBlockVariable.map((tab, h) => {
          let tabName = tab.tab_name.toLowerCase().replace(/\s+/g, "_");

          return (
            <div
              style={{
                padding: `${tab.layout != "fixed" ? "0px 55px" : "0px"}`,
              }}
            >
              <FormFactoryComp
                tabName={tabName}
                activeTab={activeTab}
                tab={tab}
                key={h}
              />
            </div>
          );
        })}
      </div>
    );
  }

  function PeopleTabs() {
    return (
      <div className="floTabsContainer" style={{ padding: "0px 25px" }}>
        <ul>
          {PeopleReducer.fieldsBlockVariable.map((tab, h) => {
            let tabName = tab.tab_name.toLowerCase().replace(/\s+/g, "_");
            return blockTabs[tabName] =="show" ? (
              <li
                onClick={() => setactiveTab(tabName)}
                className={`${
                  activeTab == tabName ? "floTabsActive" : null
                } floTabs`}
              >
                {tab.tab_name}
                <div
                  className={`floTabsActiveBar ${
                    activeTab == tabName ? "bg-p" : null
                  }`}
                ></div>
              </li>
            ) : null;
          })}
        </ul>
      </div>
    );
  }

  function PeopleWhoCanAccess() {
    return (
      <div className="d-flex p-relative">
        {/* <FloCollabCircle title="Can Edit" collabs={collabs} /> */}
        <div className="floCollabDivider"></div>
        {/* <FloCollabCircle title="Can View" collabs={collabs} /> */}
      </div>
    );
  }

  function ProfileContainer() {
    return (
      <div className="d-flex">
        <div className="sqr-profile-container"></div>
        <div className="sqr-profile-name v-center">
          {PeopleReducer.fieldsBlockVariable[0].block[0].inputs[0].value}{" "}
          {PeopleReducer.fieldsBlockVariable[0].block[0].inputs[1].value}
        </div>
      </div>
    );
  }

  function AllPeopleLeft() {
    return (
      <div className="PeopelDetaisLeft">
        <ActiveMenu
          setChecked={(e) => {
            console.log(e.target.checked);
            setcheckedAll(e.target.checked);
          }}
        />
        {PeopleReducer.peoples.map((p, i) => (
          <BaseName
            key={i}
            checkedAll={checkedAll}
            index={i}
            title={p.name}
            data={p}
            detail={p.details[1].block[0].inputs[1].value}
          />
        ))}
      </div>
    );
  }
};

export default PeopleDetails;
